import Highcharts from 'highcharts';
import angular from 'angular';
import { routerApp, baseUrl, themePalette, updatedCacheBust, enableHybrid, enableOnPremise, isQABuild } from '../app.module';
import { redirectToModule, executeFnOnElementLoad } from '../services/utilities';
import ValidationService from '../services/validation';
import cacheBustVersions from '../cacheBust';
import { CACHE_NAME, ONLINE_REQ_TIMEOUT, OFFLINE_REQ_TIMEOUT, ONLINETIMEOUT } from '../services/utilities';
import hybridService  from '../services/hybrid/index';
// import fetch & promise polyfill together to work on older browsers
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';
import { SensorData } from '../analytics/services/sensor.service';
import { modalService  } from '../services/modal.service';
import { VersionInfo } from '../models/version-info';

routerApp.controller('mainController', ['$log', '$timeout', '$rootScope', '$scope', '$http', '$state', '$stateParams', 'UserClientService', 'ReportService', 'ReportsOffshoreService', 'VesselApiService', 'VesselSpecificationService', 'ReportsApiService', 'EngineReportService', 'TimezoneService', 'PositionApiService', 'OffhireReportService', 'notify', 'PageState', '$interval', 'OfflineDatabase', 'errorHandlerService', 'AutoSaveService',
function($log, $timeout, $rootScope, $scope, $http, $state, $stateParams, UserClientService, ReportService, ReportsOffshoreService, VesselApiService, VesselSpecificationService, ReportsApiService, EngineReportService, TimezoneService, PositionApiService, OffhireReportService, notify, PageState, $interval, OfflineDatabase, _log, AutoSaveService) {
    $scope.pageState = new PageState();

    $rootScope.app = 'reporting';
    $rootScope.buildTheme = themePalette.getCurrentTheme();
    $scope.baseUrl = baseUrl;
    $scope.isQABuild = isQABuild;
    
    if (enableHybrid || enableOnPremise) {
        hybridService.registerListener('message', function(event) {
            switch(event.data.type) {
                case 'abort-signal':
                    OfflineDatabase.setOnlineConnection(false);
                    return;
                case 'engine-reports-list':
                    console.log('received sw broadcast for engine-reports-list')
                    $scope.refreshEngineReports();
                    return;
                case 'sync-new-report':
                    $rootScope.syncingOfflineReports = event.data.syncingOfflineReports;
                    return;
                case 'sync-new-engine-report':
                    $rootScope.syncingOfflineEngineReports = event.data.syncingOfflineEngineReports;
                    return;
                default:
                    return;
            }
        });
        OfflineDatabase.setCacheBustFilesCount(updatedCacheBust ? Object.keys(updatedCacheBust).length : Object.keys(cacheBustVersions).length);
        OfflineDatabase.setCorsRequestCount(Object.keys(OfflineDatabase.getCorsRequestSet()).length);
    }
    // todo: weird thing happens when i use ng route to move from one page to
    // another, this controller ends up initializing twice, so put in this hack
    // here to make sure it only initializes once, figure this out!
    if ($stateParams.report == undefined && $stateParams.engineReport == undefined) {
        notify.config({startTop: 60, maximumOpen: 3, position: 'right'});

        // so that we can call `$state.go()` from ng-click, allows us to disable
        // some views when we want to not allow them to access them, e.g reports
        $scope.go = $state.go.bind($state);

        $rootScope.selectedLevels = [];

        // create a new report as soon as this is loaded, and store that report in
        // the report service's new report object, if a started report is to be loaded
        // from the server we'll replace it later, but this way we'll have something
        // to start with
        $scope.report = ReportService.createNewVesselReport($state.current.name, VesselSpecificationService.getSpecifications());
        ReportService.setVesselReport($scope.report);

        // also create a new engine report and store that report on the report
        // service new engine report object
        $scope.engine_report = ReportService.createNewEngineReport();
        ReportService.setEngineReport($scope.engine_report);
        $scope.offhire_report = ReportService.createNewOffhireReport();
        ReportService.setOffhireReport($scope.offhire_report);
    }


    // keep track of browser online/offline status
    $rootScope.setServiceWorkTimeout = false;
    $rootScope.onlineStatus = {};
    if (enableHybrid && $rootScope.browserStatusJob == undefined) {
        $rootScope.browserStatusJob = $interval(function() {
            var isOnline = OfflineDatabase.isOnline();
            $rootScope.onlineStatus.online = isOnline;
            
            if (isOnline) {
                var now = new Date();
                $rootScope.onlineStatus.lastOnline = now.toLocaleString();
                if ($rootScope.setServiceWorkTimeout) {
                    $scope.goOnlineMode();
                }
            }

            if (!isOnline && !$rootScope.setServiceWorkTimeout) {
                $scope.goOfflineMode();
            }
        }, 5000);
    }
    if (!(enableHybrid || enableOnPremise)) {
        $rootScope.onlineStatus.online = true;
    }

    var connectionStatusTimeout = false;

    $scope.getVersion = function() {
        if (enableOnPremise) {
            hybridService
            .serverVersionQuery()
            .then((edgeVersionResponse: VersionInfo) => {
                $scope.appVersion = edgeVersionResponse && edgeVersionResponse.version || null;
            });
        }
    }
    
    $scope.goOfflineMode = function() {
        OfflineDatabase.setOnlineConnection(false);
        if (enableOnPremise) return;
        $scope.setSWRequest({ timeout: OFFLINE_REQ_TIMEOUT });    
        // reset any existing offline timer to go online
        if (connectionStatusTimeout) {
            $timeout.cancel(connectionStatusTimeout)
        }
        // start new timer for when to turn app back online
        // to check network connection strength
        connectionStatusTimeout = $timeout(function() {
            if (!OfflineDatabase.isOnline()) {
                $scope.goOnlineMode();
            }
        }, ONLINETIMEOUT)

    };

    $scope.goOnlineMode = function() {
        if (enableOnPremise) return;
        OfflineDatabase.setOnlineConnection(true);
        $scope.setSWRequest({ timeout: ONLINE_REQ_TIMEOUT });
        // reset any existing offline timer to go online
        if (connectionStatusTimeout) {
            $timeout.cancel(connectionStatusTimeout);
        }
    };

    $scope.setSWRequest = function(message) {
        $rootScope.setServiceWorkTimeout = message.timeout < 100;
        var appIsOnline = message.timeout > 100;
        hybridService.messageActiveServiceWorker({
            type: 'SET_TIMEOUT',
            payload: { 
                status: true,
                timeout: message.timeout,
                appIsOnline: appIsOnline
            }
        });
    };
    
    // disable loading modal if using offline modal
    if((enableHybrid || enableOnPremise) && $rootScope.offlineMode) {
        $rootScope.loadingModalUsed = true;
        if ($rootScope.offlineModalUsed) {
            $rootScope.offlineModal = false;
        }
    }
    // periodically issue a sync event for new-report to try to sync reports
    // that were submitted offline whenever the service worker gets the chance
    if (enableHybrid && $rootScope.syncJob == undefined) {
        $rootScope.syncJob = $interval(function() {
            hybridService.syncReportsServiceWorker(['new-report', 'new-engine-report']);
        }, 30000);
    }

    // Periodically check if any required report API request has been cache
    // if it has save count to indexeddb to be used for cache progress bar
    if (!$rootScope.offlineProgress && enableHybrid) {
        $rootScope.offlineProgress = 0;
    }
    if (enableHybrid && $rootScope.cacheStatusJob === undefined && $rootScope.offlineMode) {
        $rootScope.cacheStatusJob = $interval(function() {
            hybridService.serverOfflineProgressGet().then(function(results) {
                var count = results ? results.cacheCount : 0;
                var cacheBusterHasCached = results ? results.cacheBusterHasCached : false;
                hybridService.openCache(window, CACHE_NAME)
                .then(function(cache) { 
                    return cache.matchAll()
                    .then(function(cacheRequests) {
                        // skip cachebusts check if already done
                        if (!cacheBusterHasCached) {
                        // check cachebusts files has been cached 
                            var cachedItems = {};
                            cacheRequests
                            .filter(function(response) { return response.type === "basic" })
                            .forEach(function(response) {
                                if (response.url) {
                                    var u = new URL(response.url)
                                    var pn = u.pathname;
                                    var searchString = u.search;
                                    cachedItems[pn] = pn + searchString;
                                }
                            })
                            // count how many files in cacheBustVersion have been loaded into cache
                            for (let f in cacheBustVersions) {
                                var cacheItemKey = cacheBustVersions[f];
                                if (cachedItems['/' + f] == '/' + cacheItemKey) { // checks file matches hashnumber
                                    count++
                                } else if (cachedItems['/' + f]) { // if hashing number is different, check if any file verions exist
                                    count++; 
                                }
                            }
                        }

                        if (count >= OfflineDatabase.getCacheBustFilesCount()) {
                            cacheBusterHasCached = true;
                        }
                        
                        var corsRequests = cacheRequests.filter(function(cRequest) { // count data request for reporting only
                            if (cRequest.type === "cors" && cRequest.url.indexOf('analytics') == -1 && OfflineDatabase.verifyApi(cRequest.url)) {
                                return cRequest.url;
                            }
                        })
                        var apiRequestCount = corsRequests.length;
                        var totalCacheCount = OfflineDatabase.getTotalCacheCount();
                        var percentProgress = Math.floor(((count + apiRequestCount) / totalCacheCount) * 100);
                        $rootScope.offlineProgress = percentProgress > 100 ? 100 : percentProgress;

                        if (count >= OfflineDatabase.getCacheBustFilesCount() && apiRequestCount >= OfflineDatabase.getCorsRequestCount() && OfflineDatabase.isApiCached()) {
                            $rootScope.offlineModal = false;
                            $rootScope.offlineModalUsed = true;
                            $interval.cancel($rootScope.cacheStatusJob);
                            $scope.goOnlineMode();
                        }
                        return hybridService.serverProgressUpdate({
                            count: 'count',
                            cacheCount: count,  
                            apiRequestCount: apiRequestCount,
                            cacheBusterHasCached: cacheBusterHasCached
                        });
                    })
                    .catch(_log.cacheErrorHandler)
                })
                .catch(_log.cacheErrorHandler)
            })
        }, 5000)
    }

    $scope.stopInterval = function(intervalName) {
        clearInterval(intervalName)
    }

    $scope.isSelectedVessel = function(vessel) {
        return vessel.id != undefined && vessel.id == $rootScope.selectedVessel.id
    }

    // this gets called when the user clicks on a new vessel from the vessel
    // list, we set initialized data to false so that all the data gets
    // refreshed for the new $rootScope.selectedVessel.id value
  $scope.switchVessel = function (vessel) {
        if (!$scope.isSelectedVessel(vessel)) {
            $rootScope.dashboardReady = false;
            $rootScope.loadingModalUsed = false;
            $rootScope.initializedData = false;
            $rootScope.lastReportWasSeaReport = false;
            $rootScope.lastReportWasPortReport = false;
            $rootScope.lastReportWasPortOrAnchor = false;

            ReportService.clearService();
            ReportsOffshoreService.clearService()
            $rootScope.selectedVessel = vessel;
            ReportsOffshoreService.refreshReports()
            
            if (!$scope.features) return;
            const reportingModuleUrl = redirectToModule($scope.features, 'crew_dashboard');

            if (enableHybrid || enableOnPremise) {
                // if there's a report number set on the service, get it from there
                // otherwise use the one that is on the request itself, if it's > saved report number
                hybridService.serverMetaGet(vessel.id).then(function(offlineVesselData) {
                    ReportService.setVesselReportNumber(offlineVesselData && offlineVesselData.reportNumber && offlineVesselData.reportNumber > vessel.reportNumber ? offlineVesselData.reportNumber : vessel.reportNumber);
                    $rootScope.hasIncompleteVesselReport = offlineVesselData && offlineVesselData.hasIncompleteVesselReport != undefined ? offlineVesselData.hasIncompleteVesselReport : vessel.hasIncompleteVesselReport;
                    ReportService.setMEReportNumber(offlineVesselData && offlineVesselData.engineReportNumber && offlineVesselData.engineReportNumber.meReports && offlineVesselData.engineReportNumber.meReports > vessel.meReportNumber ? offlineVesselData.reportNumber.meReports : vessel.meReportNumber);
                    ReportService.setAEReportNumber(offlineVesselData && offlineVesselData.engineReportNumber && offlineVesselData.engineReportNumber.aeReports && offlineVesselData.engineReportNumber.aeReports > vessel.aeReportNumber ? offlineVesselData.reportNumber.aeReports : vessel.aeReportNumber);
                    
                    console.log($state.current.name)
                    $rootScope.reloading = true;
                    $state.go(reportingModuleUrl, { report: undefined, viewReport: false }, { reload: true });
                }).catch(function() {
                    ReportService.setVesselReportNumber(vessel.reportNumber);
                    ReportService.setMEReportNumber(vessel.meReportNumber);
                    ReportService.setAEReportNumber(vessel.aeReportNumber)
                }).finally(function() {
                    ReportService.setOffhireReportNumber(vessel.offhireReportNumber);
                })
            } else {
              ReportService.setVesselReportNumber(vessel.reportNumber);
              ReportService.setOffhireReportNumber(vessel.offhireReportNumber);
              ReportService.setMEReportNumber(vessel.meReportNumber);
              ReportService.setAEReportNumber(vessel.aeReportNumber);
              $rootScope.hasIncompleteVesselReport = vessel.hasIncompleteVesselReport;
  
              console.log($state.current.name)
              $rootScope.reloading = true;
              $state.go(reportingModuleUrl, { report: undefined, viewReport: false }, { reload: true });
            } 
            
        }
        SensorData.initialize()
    }

    // refresh the user's vessel list, ideally we only call this one, we should
    // be able to make a refresh call for this list on a periodic interval,
    // but only do one call per page load for now
    if ($rootScope.vessels == undefined) {
        VesselApiService.getVessels($rootScope.username).then(function(response) {
            var allVessels = response.data.data;
            $rootScope.all_vessels = allVessels;
            var active_vessels = new Array;
            for (var i = 0; i<allVessels.length;i++) {
                if (allVessels[i].disabled!=true) {
                    active_vessels.push(allVessels[i])
                }
            }
            $rootScope.vessels = active_vessels;
        });
    }

    // this function will load all reports stored in IndexDB
    // those are all the objects in the `reports` collection
    // returns a promise
    $scope.refreshOfflineReports = function() {
        if (!(enableHybrid || enableOnPremise)) return;
        return hybridService.serverReportsQueryByVesselId($rootScope.selectedVessel.id)
            .then(function(offlineReports) {
            var updatedOfflineReports = [];
            let clonedOffhireReports = [];
            if ($rootScope.offlineReports && $rootScope.offlineReports.length > 0) {
                clonedOffhireReports = $rootScope.offlineReports.map(x => Object.assign({}, x));
            }
            for (var i = 0; i < offlineReports.length; i++) {
                // check if report has already been submitted
                var offlineReport = offlineReports[i];
                var serverReports = ReportService.getVesselReportsList();
                if (offlineReport.report.status == 'started' 
                    && offlineReport.reportStatus == 'draft' 
                    && serverReports != undefined 
                    && serverReports.length > 0 
                    && serverReports[0].status == 'completed' 
                    && offlineReport.report.report_number <= serverReports[0].reportNumber
                ) {
                    OfflineDatabase.deleteReportInProgress(offlineReport.report, $rootScope.selectedVessel.id);
                } else {
                    updatedOfflineReports.push(offlineReport);
                }
            }
            // if current offline reports is different from this list
            // make a call to get all reports from database
            var offlineReportsListChange = clonedOffhireReports && clonedOffhireReports != updatedOfflineReports && updatedOfflineReports.length != clonedOffhireReports.length;
            var singleOfflineDraftreport = updatedOfflineReports.length == 1 && updatedOfflineReports[0].reportStatus === 'draft';
            if (offlineReportsListChange || singleOfflineDraftreport) {
                $scope.refreshVesselReports();
            }

            $rootScope.offlineReports =  OfflineDatabase.manageDisplayOfflineReports(updatedOfflineReports, serverReports);
            OfflineDatabase.setOfflineReports(updatedOfflineReports);
        }, function(error) {
            $rootScope.offlineReports = [];
            OfflineDatabase.setOfflineReports([]);
        })
    }

    // offline engine reports
    $scope.refreshOfflineEngineReports = function() { 
        if (!(enableHybrid || enableOnPremise)) return;       
        return hybridService.serverEngineReportsQueryByVesselId($rootScope.selectedVessel.id)
        .then(function(offlineEngReports) {
            // separate me vs ae offline lists
            var offlineMEReports = OfflineDatabase.getOfflineMEReports(offlineEngReports);
            var offlineAEReports = OfflineDatabase.getOfflineAEReports(offlineEngReports);
            var serverMEReports = ReportService.getMEReportList();
            var updatedMEOfflineReports = [];

            // deletes any duplicated completed engine reports
            // me reports
            for (var i = 0; i < offlineMEReports.length; i++) {
                var offlineMEReport = offlineMEReports[i];
                var meReportIndex = serverMEReports.length > 0 ? serverMEReports.length - 1 : 0;
                if (offlineMEReport.report.status == 'started'
                    && offlineMEReport.reportStatus == 'draft'
                    && serverMEReports != undefined 
                    && serverMEReports.length > 0 
                    && serverMEReports[meReportIndex].status == 'completed' 
                    && offlineMEReport.report.report_number <= serverMEReports[meReportIndex].reportNumber
                ) { 
                    OfflineDatabase.deleteEngineReportInProgress(offlineMEReport.report, $rootScope.selectedVessel.id)
                } else {
                    updatedMEOfflineReports.push(offlineMEReport);
                }
            }
            // ae reports
            var serverAEReports = ReportService.getAEReportList();
            var updatedAEOfflineReports = [];
            for (var i = 0; i < offlineAEReports.length; i++) {
                var offlineAEReport = offlineAEReports[i];
                var aeReportIndex = serverAEReports.length > 0 ? serverAEReports.length - 1 : 0;
                if (offlineAEReport.report.status == 'started'
                    && offlineAEReport.reportStatus == 'draft'
                    && serverAEReports != undefined 
                    && serverAEReports.length > 0 
                    && serverAEReports[aeReportIndex].status == 'completed' 
                    && offlineAEReport.report.report_number <= serverAEReports[aeReportIndex].reportNumber
                ) { 
                    OfflineDatabase.deleteEngineReportInProgress(offlineAEReport.report, $rootScope.selectedVessel.id)
                } else {
                    updatedAEOfflineReports.push(offlineAEReport);
                }
            }

            // refresh synced engine reports
            // when there's a change in updated offline list, report has been completed and removed from indexedb
            // when an draft has been updated and its reflected in the reports list
            var changeInEngineReportList = 
            $rootScope.offline_me_reports && $rootScope.offline_me_reports.length != updatedMEOfflineReports.length || 
            $rootScope.offline_ae_reports && $rootScope.offline_ae_reports.length != updatedAEOfflineReports.length;
            var draftStatusChange = 
            updatedMEOfflineReports.length == 1 && updatedMEOfflineReports[0].reportStatus == 'draft' || 
            updatedAEOfflineReports.length == 1 && updatedAEOfflineReports[0].reportStatus == 'draft';
            
            if (changeInEngineReportList || draftStatusChange) {
                $scope.refreshEngineReports();
            }

            // updated offline engine reports
            $rootScope.offline_me_reports = OfflineDatabase.manageDisplayOfflineReports(updatedMEOfflineReports,serverMEReports) ;
            $rootScope.offline_ae_reports = OfflineDatabase.manageDisplayOfflineReports(updatedAEOfflineReports,serverAEReports);
            OfflineDatabase.setOfflineEngineReports(updatedMEOfflineReports.concat(updatedAEOfflineReports));
            $rootScope.$broadcast('engine-reports-list')
        }, function(error) {
            $rootScope.offline_me_reports = [];
            $rootScope.offline_ae_reports = [];
            OfflineDatabase.setOfflineEngineReports([]);
        });
    };

    $scope.refreshOfflineOffhireReports = function() {
        if (!enableOnPremise) return;
        return hybridService.serverOffhireReportsQueryByVesselId($rootScope.selectedVessel.id)
        .then(offhireReports => {
            var updatedOfflineReports = [];
            for (var i = 0; i < offhireReports.length; i++) {
                var offlineReport = offhireReports[i];
                var serverReports = ReportService.getOffhireReportList();
                if (offlineReport.report.status == 'started' 
                    && offlineReport.reportStatus == 'draft' 
                    && serverReports != undefined 
                    && serverReports.length > 0 
                    && serverReports[0].status == 'completed' 
                    && offlineReport.report.report_number <= serverReports[0].reportNumber
                ) {
                    OfflineDatabase.deleteReportInProgress(offlineReport.report, $rootScope.selectedVessel.id);
                } else {
                    updatedOfflineReports.push(offlineReport);
                }
            }
           
            $rootScope.offlineOffhireReports =  OfflineDatabase.manageDisplayOfflineReports(updatedOfflineReports, serverReports);
            $scope.offhire_reports = OfflineDatabase.manageOnlineReportsDisplay($rootScope.offlineOffhireReports, serverReports);
        }).catch(error => {
            $rootScope.offlineOffhireReports = [];
        })
    }

    $scope.refreshOfflineMetaData = function() {
        // sync vessel & engine report status across multiple browsers
        if (enableOnPremise) {
            hybridService.serverMetaGet($rootScope.selectedVessel.id).then(function(offlineVesselData) {
                // refactor to update each one
                if (offlineVesselData) {
                    // vessel report meta
                    if (offlineVesselData.hasIncompleteVesselReport && offlineVesselData.incompleteVesselReport) {
                        ReportService.setVesselReport(offlineVesselData.incompleteVesselReport);
                    } else if (offlineVesselData.hasIncompleteVesselReport == false) {
                        var newVesselReport = ReportService.createNewVesselReport($state.current.name, VesselSpecificationService.getSpecifications());
                        ReportService.setVesselReport(newVesselReport);
                    }
                    if (offlineVesselData.reportNumber) {
                        ReportService.setVesselReportNumber(offlineVesselData &&  offlineVesselData.reportNumber);
                    }

                    if (offlineVesselData && offlineVesselData.hasIncompleteVesselReport != undefined) {
                        $rootScope.hasIncompleteVesselReport = offlineVesselData && offlineVesselData.hasIncompleteVesselReport;
                    }
                    
                    // engine report meta
                    if (offlineVesselData.hasIncompleteEngineReport && offlineVesselData.incompleteEngineReport) {
                        ReportService.setEngineReport(offlineVesselData.incompleteEngineReport);
                    } else if (offlineVesselData.hasIncompleteEngineReport == false) {
                        var newReport = ReportService.createNewEngineReport();
                        ReportService.setEngineReport(newReport);
                    }

                    if (offlineVesselData.meReportNumber) {
                        ReportService.setMEReportNumber(offlineVesselData &&  offlineVesselData.meReportNumber);
                    }
                    if (offlineVesselData.aeReportNumber) {
                        ReportService.setAEReportNumber(offlineVesselData &&  offlineVesselData.aeReportNumber);
                    }
                    if (offlineVesselData.hasIncompleteEngineReport != undefined) {
                        $rootScope.hasIncompleteMeEngineReport = offlineVesselData.hasIncompleteEngineReport && offlineVesselData.incompleteEngineReport && offlineVesselData.incompleteEngineReport._cls == 'EngineReport.MEReport';
                        $rootScope.hasIncompleteAeEngineReport = offlineVesselData.hasIncompleteEngineReport && offlineVesselData.incompleteEngineReport && offlineVesselData.incompleteEngineReport._cls == 'EngineReport.AEReport';    
                    }
                   
                    // offhire reports
                    if (offlineVesselData.hasIncompleteOffhireReport && offlineVesselData.incompleteOffhireReport) {
                        ReportService.setOffhireReport(offlineVesselData.incompleteOffhireReport);
                    } else if (offlineVesselData.hasIncompleteOffhireReport == false) {
                        var newOffhireReport = ReportService.createNewOffhireReport();
                        ReportService.setOffhireReport(newOffhireReport);
                    }
                    if (offlineVesselData.offhireReportNumber) {
                        ReportService.setOffhireReportNumber(offlineVesselData.offhireReportNumber);
                    }
                    if (offlineVesselData.hasIncompleteOffhireReport != undefined) {
                        $rootScope.hasIncompleteOffhireReport = offlineVesselData && offlineVesselData.incompleteOffhireReport && offlineVesselData.hasIncompleteOffhireReport;
                    }
                }
            })
        }
    }

    // make sure to call this whenever the controller is initialized
    $scope.refreshOfflineReports();
    if ((enableHybrid || enableOnPremise) && $rootScope.offlineReportsSyncJob == undefined) {
        $rootScope.offlineReportsSyncJob = $interval(function() {  
            $scope.refreshOfflineReports().then(()=> {
                $scope.refreshOfflineMetaData();
            });
            $scope.refreshOfflineEngineReports();
            $scope.refreshOfflineOffhireReports();
        }, 10000);
    };

    // refreshes dashboard data and sets them on the report service
    $scope.refreshDashboardData = function(callback) {
        // makes sure we only show the loaidng modal once upon loading
        if (!$rootScope.loadingModalUsed) {
            $rootScope.loadingModal = true;
            $rootScope.loadingModalUsed = true;
        }
        $rootScope.loadingModalMessage = "Customizing Vessel Dashboard ...";
        ReportsApiService.getDashboardData($rootScope.selectedVessel.id, $rootScope.username).then(function(response) {
            var data = response.data;
            ReportService.setDashboardData(data);
            $scope.$broadcast('dashboard-data-received', {});

            if (callback != undefined) {
                callback();
            }
            $timeout(function() {
                $rootScope.loadingModal = false;
            }, 1000);
        });
    }

    // refreshes vessel reports, callback is a function that gets called once
    // the reports are refreshed
    $scope.refreshVesselReports = function(callback) {
        ReportsApiService.setLoadingReports(true);
        ReportsApiService.getReports($rootScope.selectedVessel.id).then(function(response) {
            var data = response.data;
            ReportService.setVesselReportsList(data);
            $rootScope.$broadcast('vessel-reports-list');
            ReportsApiService.setLoadingReports(false);

            if (callback != undefined) {
                callback();
            }
        }, function() {
            ReportsApiService.setLoadingReports(false);
        });

        if ($rootScope.selectedVessel && $scope.report && !(enableHybrid || enableOnPremise)) {
            ReportsApiService.getReportHistory($rootScope.selectedVessel.id, $scope.report.id, $scope);
        }

        ReportsOffshoreService.refreshReports()
    }

    $scope.refreshEngineReports = function(callback) {
        return EngineReportService.getReports($rootScope.selectedVessel.id).then(function(response) {
            var data = response.data;
            $scope.new_me_report_number = data.me_reports.length;
            $scope.new_ae_report_number = data.ae_reports.length;
            $scope.me_reports = data.me_reports;
            $scope.ae_reports = data.ae_reports;
            ReportService.setMEReportList($scope.me_reports);
            ReportService.setAEReportList($scope.ae_reports);
            $rootScope.$broadcast('engine-reports-list');
            if (callback != undefined) {
                callback();
            }
        });
    }

    $scope.refreshOffhireReports = function(callback) {
        return OffhireReportService.getReports($rootScope.selectedVessel.id).then(function(response) {
            var data = response.data;
            $scope.offhire_reports = data.data;
            ReportService.setOffhireReportList($scope.offhire_reports);
            if (callback != undefined) {
                callback();
            }
        });
    };

    // refreshes vessel specification objects
    $scope.refreshVesselSpecifications = function(callback) {
        VesselSpecificationService.getVesselSpecifications($rootScope.selectedVessel.id).then(function(response) {
            var data = response.data;
            VesselSpecificationService.setSpecifications(data);
            if (data.carry_over_form) {
                ReportService.setCarryForwardOptions(data.carry_over_form);
            }
            $scope.$broadcast('vessel-specifications-received', {});

            if (callback != undefined) {
                callback();
            }
        });
    }

    $scope.refreshClassModelReports = function(callback) {
        VesselSpecificationService.getClassModelReports().then(function(response) {
            var data = response.data;
            VesselSpecificationService.setReportLogs(data);
            $scope.$broadcast('class-model-reports-received', {});
            if (callback != undefined) {
                callback();
            }
        });
    }

    // refreshes form configuration
    $scope.refreshFormConfiguration = function() {
        VesselSpecificationService.getFormConfiguration($rootScope.selectedVessel.id).then(function(response) {
            var data = response.data;
            $rootScope.formConfiguration = VesselSpecificationService.buildFormConfigurationObject(data.data);
        });
    }

    $scope.engineIndices = [0];
    $scope.$on('vessel-specifications-received', function(event, args) {
        var vesselSpecs = VesselSpecificationService.getSpecifications();
        if (vesselSpecs.propeller && vesselSpecs.propeller.propeller_type) {
            $rootScope.selectedVessel.hasControllablePropeller = vesselSpecs.propeller.propeller_type == 'controllable';
        }
        if (vesselSpecs.engine && vesselSpecs.engine.number_of_main_engines) {
            var numberOfEngines = vesselSpecs.engine.number_of_main_engines;
            var engineIndices = [];
            var engineIndex = 0;
            for (var i = 0; i < numberOfEngines; i++) {
                engineIndices.push(engineIndex);
                engineIndex ++;
            }
            $scope.engineIndices = engineIndices;
            if (engineIndices.length > 1) {
                $rootScope.selectedVessel.dualEngine = true;
            }
        }
        if (vesselSpecs.prime_mover && vesselSpecs.prime_mover.number_of_prime_movers) {
            var numberOfPrimeMovers = vesselSpecs.prime_mover.number_of_prime_movers;
            var primeMoverIndices = [];
            var primeMoverIndex = 0;
            for (var i = 0; i < numberOfPrimeMovers; i++) {
                primeMoverIndices.push(primeMoverIndex);
                primeMoverIndex ++;
            }
            $scope.primeMoverIndices = primeMoverIndices;
            if (primeMoverIndices.length > 1) {
                $rootScope.selectedVessel.dualMotor = true;
            }
        }
        ValidationService.setStore('vesselSpecs', vesselSpecs);
        $scope.vesselSpecs = vesselSpecs;
    });
    // $scope.engineIndices = [0, 1];

    $scope.$on('class-model-reports-received', function(event, args) {
        var reportLogs = VesselSpecificationService.getReportLogs();
        $scope.reportLogs = reportLogs;
    });

    $rootScope.$on('edit-report', function(event, args) {
        $scope.backupReport = $scope.report;
        $scope.report = args.report;
    });

    // get last incomplete vessel report
    // todo: this should only be called once, upon landing
    $scope.refreshLastIncompleteReport = function() {

        ReportsApiService.getLastIncompleteReport($rootScope.selectedVessel.id).then(function(response) {
            var data = response.data;

            if (enableHybrid || enableOnPremise) {
                hybridService.serverMetaGet($rootScope.selectedVessel.id).then(function(offlineVesselData) {
                    if (offlineVesselData != undefined && offlineVesselData.hasIncompleteVesselReport && offlineVesselData.incompleteVesselReport) {
                        $scope.report = offlineVesselData.incompleteVesselReport;
                        ReportService.setVesselReport($scope.report);
                        $rootScope.hasIncompleteVesselReport = true;
                    } else if (data != undefined && offlineVesselData != undefined && data.report_number > offlineVesselData.reportNumber) {
                        data = ReportService.applyDatesOnReport(data);
                        $scope.report = data;
                        ReportService.setVesselReport(data);
                        $scope.$broadcast('vessel-report-received', {});
                        $rootScope.hasIncompleteVesselReport = true;
                    } else if (data == undefined || data != undefined && offlineVesselData != undefined && offlineVesselData.hasIncompleteVesselReport === false && data.report_number < offlineVesselData.reportNumber) {
                        $rootScope.hasIncompleteVesselReport = false;
                    } else if (data != undefined) {
                        data = ReportService.applyDatesOnReport(data);
                        $scope.report = data;
                        ReportService.setVesselReport(data);
                        $scope.$broadcast('vessel-report-received', {});
                        $rootScope.hasIncompleteVesselReport = true;
                    }
                    
                }).catch(function(err) {
                    if (data) {
                        $scope.report = data;
                        data = ReportService.applyDatesOnReport(data);
                        ReportService.setVesselReport(data);
                        $scope.$broadcast('vessel-report-received', {});
                        $rootScope.hasIncompleteVesselReport = true;
                    } else {
                        $rootScope.hasIncompleteVesselReport = false;
                    }
                })
            } else {
                if (data != undefined) {
                    $log.debug('received last incomplete vessel report, storing it in the service');
                    ReportService.setVesselReport(data);
                    $scope.report = data;
                    // tell everyone to re-pull report from service
                    $scope.$broadcast('vessel-report-received', {});
                    $rootScope.hasIncompleteVesselReport = true;
                } else {
                    $rootScope.hasIncompleteVesselReport = false;
                }
            }
            

        });
    }

    const updatePreviousStockForCarryOver = (carryOverReport) => {
        if (!carryOverReport.bdn_based_reporting) return carryOverReport

        const tanks = carryOverReport.stock.tanks
        const fuelGradeTotal = Object.keys(tanks).reduce((prev, curr) => {
            if (!tanks[curr].new_bdn) return prev
            if (isNaN(tanks[curr].new_bdn.amount)) return prev

            const fuelGrade = tanks[curr].new_bdn.fuel_grade
            if (prev[fuelGrade]) {
                prev[fuelGrade] += tanks[curr].new_bdn.amount
            } else {
                prev[fuelGrade] = tanks[curr].new_bdn.amount
            }

            return prev
        }, {})

        for (let key in fuelGradeTotal) {
            carryOverReport.stock[key] = fuelGradeTotal[key]
        }

        return carryOverReport
    }

    // get carry over data for last vessel report
    $scope.refreshCarryOverData = function() {
        $scope.pageState.setLoadingData();
        ReportsApiService.getCarryOverData($rootScope.selectedVessel.id, $stateParams.report && $stateParams.report.id).then(function(response) {
            var carryOverReport = response.data;
            if (carryOverReport != null) {
                $scope.carryOverReport = updatePreviousStockForCarryOver(carryOverReport);
                
                if ($scope.vesselSpecs != null) {
                    var vesselSpecsTanks = $scope.vesselSpecs.tanks;
                    ReportService.setVesselSpecsTanks(vesselSpecsTanks);
                }
                let applyCarryForwardRule = (enableHybrid || enableOnPremise) ? false : true;
                if (applyCarryForwardRule) {
                    ReportService.updateVesselReportWithCarryForwardReport(ReportService.getVesselReport(), carryOverReport, true);
                }
                const reportObj = {
                  carryOverReport: carryOverReport,
                  latest: $stateParams.report && $stateParams.report.id ? false : true
                }
                // tell everyone to re-pull report from service
                $scope.$broadcast('vessel-report-received', reportObj);
            }

            if (enableHybrid || enableOnPremise) {
                hybridService.serverMetaGet($rootScope.selectedVessel.id).then(function(offlineVesselData) {
                    var shouldFormatDateObjects = true;

                    // we should use the most recent report
                    // when the server carry over report and local carry over report are the same, default to server because we can push fixes to it from our end
                    var localCarryForwardReport = offlineVesselData && offlineVesselData.carryForwardReport;
                    var shouldUseLocalCarryForwardReport = localCarryForwardReport && (!carryOverReport || carryOverReport.report_number < localCarryForwardReport.report_number);                    
                    if (localCarryForwardReport && carryOverReport && localCarryForwardReport.operational?.report_to && localCarryForwardReport.operational?.timezone && carryOverReport.operational?.report_to && carryOverReport.operational?.timezone) {
                        let localReportDatetime = TimezoneService.dtWithTz(localCarryForwardReport.operational.report_to, localCarryForwardReport.operational.timezone);
                        let serverReportDateTime = TimezoneService.dtWithTz(ReportService.formatMoment(ReportService.parseFieldDate(carryOverReport.operational.report_to)), carryOverReport.operational.timezone);
                        shouldUseLocalCarryForwardReport = localReportDatetime.isAfter(serverReportDateTime);
                    }
                    if (shouldUseLocalCarryForwardReport) {
                        // Check completed report's ReportTo, Timezone or Current engine running hrs has edited based on last updated timestamp.
                        // If changed, apply them into local carry forward values to synchronize values correctly.
                        $scope.carryOverReport = $scope.updateCarryForwardDataMismatches(carryOverReport,localCarryForwardReport);
                        shouldFormatDateObjects = false;
                    } else if (carryOverReport) {
                        $scope.carryOverReport = carryOverReport;
                    }
                    if (!offlineVesselData.hasIncompleteVesselReport && $scope.carryOverReport) {
                        //Map previously completed report's data as carry forward data for the new report
                        ReportService.updateVesselReportWithCarryForwardReport(ReportService.getVesselReport(), $scope.carryOverReport, shouldFormatDateObjects);
                    }
                    $scope.$broadcast('vessel-report-received', { carryOverReport: carryOverReport });
                }).catch(function() {
                    $scope.carryOverReport = carryOverReport;
                    ReportService.updateVesselReportWithCarryForwardReport(ReportService.getVesselReport(), carryOverReport, true);
                    $scope.$broadcast('vessel-report-received', { carryOverReport: carryOverReport });
                })
            }
            $scope.pageState.setDataLoaded();
            
        }, function(error) {
            $scope.pageState.setDataLoaded();
            console.log(error);
        });
    }

    // This function will replace local, previously completed report's readonly properties (which will then mapped to carry forward properties later) with server's previously completed properties which has been updated by customer support
    $scope.updateCarryForwardDataMismatches = function(serverCarryOverReport, localCarryForwardReport) {

        // Check submitted report has been modified and local cached data is older than server data
        switch (serverCarryOverReport.submission_date < serverCarryOverReport.last_updated_date && serverCarryOverReport.last_updated_date != localCarryForwardReport.last_updated_date)
        {
            //Operational date times with zones
            case serverCarryOverReport.operational?.report_to != localCarryForwardReport.operational?.report_to : localCarryForwardReport.operational.report_to = serverCarryOverReport.operational.report_to;
            case serverCarryOverReport.operational?.timezone != localCarryForwardReport.operational?.timezone : localCarryForwardReport.operational.timezone = serverCarryOverReport.operational.timezone;

            //Main engine 
            case serverCarryOverReport.power?.main_engine_1_load != localCarryForwardReport.power?.main_engine_1_load : localCarryForwardReport.power.main_engine_1_load = serverCarryOverReport.power.main_engine_1_load;
            case serverCarryOverReport.power?.main_engine_1_diff_rh != localCarryForwardReport.power?.main_engine_1_diff_rh : localCarryForwardReport.power.main_engine_1_diff_rh = serverCarryOverReport.power.main_engine_1_diff_rh;
            case serverCarryOverReport.power?.main_engine_12_load != localCarryForwardReport.power?.main_engine_12_load : localCarryForwardReport.power.main_engine_12_load = serverCarryOverReport.power.main_engine_12_load;
            case serverCarryOverReport.power?.main_engine_1_and_2_rh != localCarryForwardReport.power?.main_engine_1_and_2_rh : localCarryForwardReport.power.main_engine_1_and_2_rh = serverCarryOverReport.power.main_engine_1_and_2_rh;
            
            //Generator engine            
            case serverCarryOverReport.power?.aux_engine_1_max_rating != localCarryForwardReport.power?.aux_engine_1_max_rating : localCarryForwardReport.power.aux_engine_1_max_rating = serverCarryOverReport.power.aux_engine_1_max_rating;
            case serverCarryOverReport.power?.aux_engine_1_diff_rh != localCarryForwardReport.power?.aux_engine_1_diff_rh : localCarryForwardReport.power.aux_engine_1_diff_rh = serverCarryOverReport.power.aux_engine_1_diff_rh;
            case serverCarryOverReport.power?.aux_engine_2_max_rating != localCarryForwardReport.power?.aux_engine_2_max_rating : localCarryForwardReport.power.aux_engine_2_max_rating = serverCarryOverReport.power.aux_engine_2_max_rating;
            case serverCarryOverReport.power?.aux_engine_2_diff_rh != localCarryForwardReport.power?.aux_engine_2_diff_rh : localCarryForwardReport.power.aux_engine_2_diff_rh = serverCarryOverReport.power.aux_engine_2_diff_rh;
            case serverCarryOverReport.power?.aux_engine_3_max_rating != localCarryForwardReport.power?.aux_engine_3_max_rating : localCarryForwardReport.power.aux_engine_3_max_rating = serverCarryOverReport.power.aux_engine_3_max_rating;
            case serverCarryOverReport.power?.aux_engine_3_diff_rh != localCarryForwardReport.power?.aux_engine_3_diff_rh : localCarryForwardReport.power.aux_engine_3_diff_rh = serverCarryOverReport.power.aux_engine_3_diff_rh;
            case serverCarryOverReport.power?.aux_engine_4_max_rating != localCarryForwardReport.power?.aux_engine_4_max_rating : localCarryForwardReport.power.aux_engine_4_max_rating = serverCarryOverReport.power.aux_engine_4_max_rating;
            case serverCarryOverReport.power?.aux_engine_4_diff_rh != localCarryForwardReport.power?.aux_engine_4_diff_rh : localCarryForwardReport.power.aux_engine_4_diff_rh = serverCarryOverReport.power.aux_engine_4_diff_rh;

            //Machinery running hrs counters
            case serverCarryOverReport.power?.main_engine_1_cur_rh != localCarryForwardReport.power?.main_engine_1_cur_rh : localCarryForwardReport.power.main_engine_1_cur_rh = serverCarryOverReport.power.main_engine_1_cur_rh;
            case serverCarryOverReport.power?.aux_engine_1_cur_rh != localCarryForwardReport.power?.aux_engine_1_cur_rh : localCarryForwardReport.power.aux_engine_1_cur_rh = serverCarryOverReport.power.aux_engine_1_cur_rh;
            case serverCarryOverReport.power?.aux_engine_2_cur_rh != localCarryForwardReport.power?.aux_engine_2_cur_rh : localCarryForwardReport.power.aux_engine_2_cur_rh = serverCarryOverReport.power.aux_engine_2_cur_rh;
            case serverCarryOverReport.power?.aux_engine_3_cur_rh != localCarryForwardReport.power?.aux_engine_3_cur_rh : localCarryForwardReport.power.aux_engine_3_cur_rh = serverCarryOverReport.power.aux_engine_3_cur_rh;
            case serverCarryOverReport.power?.aux_engine_4_cur_rh != localCarryForwardReport.power?.aux_engine_4_cur_rh : localCarryForwardReport.power.aux_engine_4_cur_rh = serverCarryOverReport.power.aux_engine_4_cur_rh;
            case serverCarryOverReport.power?.aux_boiler_cur_rh != localCarryForwardReport.power?.aux_boiler_cur_rh : localCarryForwardReport.power.aux_boiler_cur_rh = serverCarryOverReport.power.aux_boiler_cur_rh;
            case serverCarryOverReport.power?.aux_boiler_2_cur_rh != localCarryForwardReport.power?.aux_boiler_2_cur_rh : localCarryForwardReport.power.aux_boiler_2_cur_rh = serverCarryOverReport.power.aux_boiler_2_cur_rh;
            case serverCarryOverReport.power?.main_engine_comp_1_cur_rh != localCarryForwardReport.power?.main_engine_comp_1_cur_rh : localCarryForwardReport.power.main_engine_comp_1_cur_rh = serverCarryOverReport.power.main_engine_comp_1_cur_rh;
            case serverCarryOverReport.power?.main_engine_comp_2_cur_rh != localCarryForwardReport.power?.main_engine_comp_2_cur_rh : localCarryForwardReport.power.main_engine_comp_2_cur_rh = serverCarryOverReport.power.main_engine_comp_2_cur_rh;
                break
            default:
                break;
        }
        return localCarryForwardReport;
    }

    // get most recently modified engine report if it exists and set it as the
    // current report, if nothing is returned we shouldn't do anything, and
    // let the report service use the default pre-initialized report
    $scope.refreshLastIncompleteEngineReport = function() {
        var lastIncompleteEngineReportPromise = EngineReportService.getLastIncompleteEngineReport();

        if (enableHybrid || enableOnPremise) {
            lastIncompleteEngineReportPromise.then(function(response) {
                var serverLastIncompleteEngineReport = response.data;
                hybridService.serverMetaGet($rootScope.selectedVessel.id).then(function(offlineVesselData) {
                    var engineReport = offlineVesselData && offlineVesselData.incompleteEngineReport;
                    var offlineReportNumber = engineReport && engineReport._cls  == 'EngineReport.MEReport' ?
                        offlineVesselData.meReportNumber : 
                        offlineVesselData && offlineVesselData.aeReportNumber;
                    
                    if (offlineVesselData != undefined && offlineVesselData.hasIncompleteEngineReport && offlineVesselData.incompleteEngineReport) {
                        var engineReport = offlineVesselData.incompleteEngineReport;
                        $scope.engine_report = engineReport;
                        ReportService.setEngineReport($scope.engine_report);
                        var reportType = engineReport._cls;
                        if (reportType == 'EngineReport.MEReport') {
                            $rootScope.hasIncompleteMeEngineReport = true;
                        } else {
                            $rootScope.hasIncompleteMeEngineReport = false;
                        }
                        if (reportType == 'EngineReport.AEReport') {
                            $rootScope.hasIncompleteAeEngineReport = true;
                        } else {
                            $rootScope.hasIncompleteAeEngineReport = false;
                        }
                    } else if ((serverLastIncompleteEngineReport != undefined || serverLastIncompleteEngineReport != null) && offlineVesselData != undefined && serverLastIncompleteEngineReport.report_number > offlineReportNumber) {
                        var engineReport = ReportService.applyDatesOnEngineReport(serverLastIncompleteEngineReport);
                        $scope.engine_report = engineReport;
                        ReportService.setEngineReport(engineReport);
                        $scope.$broadcast('engine-report-received', {});
                        var reportType = engineReport._cls;
                        if (reportType == 'EngineReport.MEReport') {
                            $rootScope.hasIncompleteMeEngineReport = true;
                        } else {
                            $rootScope.hasIncompleteMeEngineReport = false;
                        }
                        if (reportType == 'EngineReport.AEReport') {
                            $rootScope.hasIncompleteAeEngineReport = true;
                        } else {
                            $rootScope.hasIncompleteAeEngineReport = false;
                        }
                    } else if (serverLastIncompleteEngineReport == undefined || serverLastIncompleteEngineReport == null) {
                        $rootScope.hasIncompleteMeEngineReport = false;
                        $rootScope.hasIncompleteAeEngineReport = false;
                    } else if (serverLastIncompleteEngineReport != undefined || serverLastIncompleteEngineReport != null) {
                        var engineReport = ReportService.applyDatesOnEngineReport(serverLastIncompleteEngineReport);
                        $scope.engine_report = engineReport;
                        ReportService.setEngineReport(engineReport);
                        $scope.$broadcast('engine-report-received', {});
                        var reportType = engineReport._cls;
                        if (reportType == 'EngineReport.MEReport') {
                            $rootScope.hasIncompleteMeEngineReport = true;
                        } else {
                            $rootScope.hasIncompleteMeEngineReport = false;
                        }
                        if (reportType == 'EngineReport.AEReport') {
                            $rootScope.hasIncompleteAeEngineReport = true;
                        } else {
                            $rootScope.hasIncompleteAeEngineReport = false;
                        }
                    }
                })
            })
        } else {
            lastIncompleteEngineReportPromise.then(function(response) {
                var data = response.data;
                if (data != null) {
                    var report = ReportService.applyDatesOnEngineReport(data);
                    ReportService.setEngineReport(report);
                    $scope.$broadcast('engine-report-received', {});
                    var reportType = report._cls;
                    if (reportType == 'EngineReport.MEReport') {
                        $rootScope.hasIncompleteMeEngineReport = true;
                    } else {
                        $rootScope.hasIncompleteMeEngineReport = false;
                    }
                    if (reportType == 'EngineReport.AEReport') {
                        $rootScope.hasIncompleteAeEngineReport = true;
                    } else {
                        $rootScope.hasIncompleteAeEngineReport = false;
                    }
                } else {
                    $rootScope.hasIncompleteMeEngineReport = false;
                    $rootScope.hasIncompleteAeEngineReport = false;
                }
            })
        }
    }

    $scope.refreshLastIncompleteOffhireReport = function() {
        var lastIncompleteOffhireReportPromise = OffhireReportService.getLastIncompleteOffhireReport();
        if (enableOnPremise) {
            lastIncompleteOffhireReportPromise.then(function(response) {
                var serverOffhireReport = response.data;
                return hybridService.serverMetaGet($rootScope.selectedVessel.id).then(function(offlineVesselData) {
                    var offhireReport = offlineVesselData && offlineVesselData.incompleteOffhireReport;
                    var offlineReportNumber = offhireReport && offhireReport.report_number || 1;
                    
                    if (offlineVesselData != undefined && offlineVesselData.hasIncompleteOffhireReport && offlineVesselData.incompleteOffhireReport) {
                        $scope.offhire_report = offhireReport;
                        ReportService.setOffhireReport($scope.offhire_report);
                        $rootScope.hasIncompleteOffhireReport = offlineVesselData.hasIncompleteOffhireReport;
                    } else if ((serverOffhireReport != undefined || serverOffhireReport != null) && offlineVesselData != undefined && serverOffhireReport.report_number > offlineReportNumber) {
                        var offhireReport = ReportService.applyDatesOnOffhireReport(serverOffhireReport);
                        $scope.offhire_report = offhireReport;
                        ReportService.setOffhireReport(offhireReport);
                        $scope.$broadcast('offhire-report-received', {});
                    } else if (serverOffhireReport == undefined || serverOffhireReport == null) {
                        $rootScope.hasIncompleteOffhireReport = false;
                    } else if (serverOffhireReport != undefined || serverOffhireReport != null) {
                        var offhireReport = ReportService.applyDatesOnOffhireReport(serverOffhireReport);
                        $scope.offhire_report = offhireReport;
                        ReportService.setOffhireReport(offhireReport);
                        $scope.$broadcast('offhire-report-received', {});
                        $rootScope.hasIncompleteOffhireReport = true;
                    }
                })
            })
        } else {
            lastIncompleteOffhireReportPromise.then(function(response) {
                var data = response.data;
                if (data != undefined) {
                    var report = ReportService.applyDatesOnOffhireReport(data);
                    report.id = report["_id"]["$oid"];
                    ReportService.setOffhireReport(report);
                    $scope.$broadcast('offhire-report-received', {});
                    $rootScope.hasIncompleteOffhireReport = true;
                } else {
                    $rootScope.hasIncompleteOffhireReport = false;
                }
            });
        }
    };

    // loading sequence for when the app is open
    // load vessel data first
    $scope.refreshDashboardData();
    $scope.refreshVesselReports();
    $scope.refreshLastIncompleteReport();
    $scope.refreshCarryOverData();
    $scope.refreshFormConfiguration();

    // then vessel specifications
    $scope.refreshVesselSpecifications();
    $scope.refreshClassModelReports();

    // then engine data
    $scope.refreshEngineReports();
    $scope.refreshOffhireReports();
    $scope.refreshLastIncompleteEngineReport();
    $scope.refreshLastIncompleteOffhireReport();

    // reporting functions
    $scope.validateTab = function(reportForm, tab) {
        if (reportForm[tab] != undefined) {
            reportForm[tab].$pristine = false;
            angular.forEach(reportForm[tab], function(control) {
                if (typeof control === 'object' && control.hasOwnProperty('$modelValue')) {
                    control.$pristine = false;
                }
            });

            // Validate sub-forms with recursion
            if (tab === 'position') {
                $scope.validateTab(reportForm['position'], 'observation');
            }

            if (tab === 'power') {
                $scope.validateTab(reportForm['power'], 'runningHours');
                $scope.validateTab(reportForm['power'], 'generatorEngine');
            }
        }
    }

    $scope.$on('vessel-report-received', function(event, args) {
        if (args.latest) {
            $rootScope.lastReportWasSeaReport = false;
            $rootScope.lastReportWasPortReport = false;
            $rootScope.lastReportWasPortOrAnchor = false;
            var report = args.carryOverReport;
            if (report != undefined) {
                $rootScope.lastReportWasSeaReport = (report._cls == 'Report.SeaReport');
                $rootScope.lastReportWasPortReport = (report._cls == 'Report.PortReport');
                $rootScope.lastReportWasPortOrAnchor = (report._cls == 'Report.PortReport') || (report._cls == 'Report.AnchorReport' && report.operational.anchor_drifting == 'anchor');
            }
        }
    });

    $scope.showPortWarning = function(toggleModal, reportType) {
        if (toggleModal) {
            $('#portWarning').modal('show');
        } else {
            $state.go('site.' + reportType, { report: undefined, viewReport: false }, { reload: true });
        }
    };

    $scope.createNewSeaReport = function() {
        $state.go('site.seaReport', { report: undefined, viewReport: false }, { reload: true });
        $('.modal-backdrop').remove();
    }
    $scope.createNewPortReport = function() {
        $state.go('site.portReport', { report: undefined, viewReport: false }, { reload: true });
        $('.modal-backdrop').remove();
    }
    $scope.createNewManeuveringReport = function() {
        $state.go('site.maneuveringReport', { report: undefined, viewReport: false }, { reload: true });
        $('.modal-backdrop').remove();
    }

    $scope.isSeaReport = function(report) {
        return isOfReportType(report, 'Report.SeaReport') || isOfReportType(report, 'Report.ManeuveringReport');
    }
    $scope.isPortReport = function(report) {
        return isOfReportType(report, 'Report.PortReport');
    }
    $scope.isAnchorReport = function(report) {
        return isOfReportType(report, 'Report.AnchorReport');
    }
    $scope.isManReport = function(report) {
        return isOfReportType(report, 'Report.ManeuveringReport');
    }
    $scope.isPortOrAnchorReport = function(report) {
        return $scope.isPortReport(report) || $scope.isAnchorReport(report);
    }
    $scope.isReportSavedLocally = function(report) : boolean {
        return OfflineDatabase.isReportSavedLocally(report);
    }

    var isOfReportType = function(report, type) {
        if (report != undefined) {
            return report._cls == type;
        } else {
            return false;
        }
    }
    $scope.isOfReportType = isOfReportType;

    // opens the last performance report being edited
    $scope.openReport = function(report, activeTab, viewReport) {
        var reportId = report.id;
        if ($scope.vessel_specifications?.form?.tabs['cii']?.visible == true) activeTab = 'cii';
        else if (activeTab == 'results') activeTab = 'operational';

        $scope.pageState.setLoadingData();
        ReportsApiService.getReport($scope.selectedVessel.id, reportId).then(function(response) {
          var report = response.data;
          if ($scope.reportForm) { $scope.reportForm.$pristine = false }
          if (!report.bdn_based_reporting) { report.bunker.reporting_fuel_type = true}
          report = ReportService.applyDatesOnReport(report);
            report.id = report["_id"]["$oid"];
            if (!OfflineDatabase.isOnline()) {
                report = ReportService.getVesselReport();
            }
            var targetState = ReportsApiService.getReportView(report);
            $state.go(targetState, { report: report, activeTab: activeTab, viewReport: viewReport });
            $scope.pageState.setDataLoaded();
        }).catch(function() {
            // connection could not have been made or reportId is undefined
            if (enableHybrid || enableOnPremise) {
                var targetState = ReportsApiService.getReportView(report);
                $state.go(targetState, { report: report, activeTab: activeTab, viewReport: viewReport });
                $scope.pageState.setDataLoaded();
            }
            
        });
    };

    // opens offline report
    $scope.openOfflineReport = function(offlineReport) {
        var report = offlineReport.report;
        $scope.pageState.setLoadingData();
        var targetState = ReportsApiService.getReportView(report);
        var params = { report: report, activeTab: null, viewReport: true };
        if (targetState == 'site.offhireReport') {
            params['offhireReport'] = report;
        }
        $state.go(targetState, params);
        $scope.pageState.setDataLoaded();
    };

    $scope.goToOfflineReport = (report) => {
        return hybridService.serverReportsGet(OfflineDatabase.getReportKey(report))
        .then(offlineReport => $scope.openOfflineReport(offlineReport))
        .catch(()=> $scope.openReport(report, undefined, true))
    }

    $scope.backToNewReport = function() {
        var report = ReportService.getVesselReport();
        if (enableOnPremise) {
            $scope.goToOfflineReport(report)
        } else{
            $scope.openReport(report, undefined, true);
        }
    }

    $scope.goToInProgressOffshoreReport = function() {
        $state.go('site.offshoreReport', { report: ReportsOffshoreService.getInprogressReport(), viewReport: true });
    }

    $scope.goToEngineReport = function(targetState) {
        var report = ReportService.getEngineReport();
        $scope.pageState.setLoadingData();
        let engineReportPromise;
        if (targetState.indexOf('meReport') > -1) {
            engineReportPromise = EngineReportService.getMEReport(report.id);
        } else {
            engineReportPromise = EngineReportService.getAEReport(report.id);
        }
     
        engineReportPromise.then(function(response) {
            var report = response.data;
            if (!OfflineDatabase.isOnline()) {
                report = ReportService.getEngineReport();
            }
            report = ReportService.applyDatesOnEngineReport(report, report);
            $state.go(targetState, { engineReport: report, viewReport: report.status == 'completed' });
            $scope.pageState.setDataLoaded();
        }).catch(function(error) {
             // connection could not have been made or reportId is undefined
             if (enableHybrid || enableOnPremise) {
                $state.go(targetState, { engineReport: report, viewReport: report.status == 'completed' });
            } else {
                notify({message: 'Error loading report from server, try again in a bit!', duration: 2000, classes: ['bad-notification']});
            }
            $scope.pageState.setDataLoaded();
        });
    }

    $scope.goToOffhireReport = function() {
        var report = ReportService.getOffhireReport();
        $scope.pageState.setLoadingData();
        return OffhireReportService.getOffhireReport(report.id)
        .then(response => {
            var report = response.data;
            if (!OfflineDatabase.isOnline()) {
                report = ReportService.getOffhireReport();
            }
            report = ReportService.applyDatesOnOffhireReport(report);
            $state.go('site.offhireReport', { offhireReport: report, viewReport: report.status == 'completed' });
            $scope.pageState.setDataLoaded();
        }).catch(error => {
            // connection could not have been made or reportId is undefined
            if (enableHybrid || enableOnPremise) {
                $state.go('site.offhireReport', { offhireReport: report, viewReport: report.status == 'completed' });
            } else {
                notify({message: 'Error loading report from server, try again in a bit!', duration: 2000, classes: ['bad-notification']});
            }
            $scope.pageState.setDataLoaded();
        })
    }

    $scope.logout = function() {
        fetch(baseUrl + 'logout', {
            method: 'POST',
            credentials: 'include',
        }).then(function(response) {
            $timeout(function() {
                $state.go('login');
            }, 2000);
        })
        if ((enableHybrid || enableOnPremise )&& !OfflineDatabase.isOnline()) {
            $timeout(function() {
                $state.go('login');
            }, 2000);
        }
    }

    $scope.goToAnalytics = function() {
        if (!$scope.features) return;
        const moduleUrl = redirectToModule($scope.features, 'fleet_tracker');
        $state.go(moduleUrl);
    }

    $scope.allTimezones = TimezoneService.getTimezones();

    $scope.$watch("loadingModal", function(value) {
        if (value) {
            $("#loadingModal").modal('show');
        } else {
             $("#loadingModal").modal('hide');
             $('.modal-backdrop').remove();
        }
    });

    $scope.$watch("offlineModal", function(value) {
        if (value) {
            $("#offlineModal").modal('show');
            $rootScope.offlineModalUsed = true;
        }
    });

    $scope.$watchGroup(['report.operational.maneuvering_type'], function(newValues, oldValues, scope) {
        if (newValues[0] == 'departure') {
           $scope.report.operational.cosp = $scope.report.operational.report_to;
        }
    });

    $rootScope.initializedData = true;

    $scope.isAdmin = function() {
        return $scope.role.includes('admin');
    }

    $scope.isInternalAdmin = function() {
        return $scope.role == 'internal_admin';
    }

    $scope.isShipUser = function() {
        return $scope.role == 'ship_user';
    }

    $scope.isShoreUser = function() {
        return $scope.role == 'shore_user';
    }

    $rootScope.staticDownloadButtonText = "Download Data";
    $rootScope.downloadButtonText = $rootScope.staticDownloadButtonText;
    $rootScope.downloadDisabled = false;

    $scope.isGCCVessel = function() {
        return $scope.selectedVessel.company == 'Gram Car Carriers';
    };

    $scope.isEBVessel = function() {
        return $scope.selectedVessel.company == 'Eagle Bulk Shipping, Inc';
    };

    $scope.isDorianVessel = function() {
        return $scope.selectedVessel.company == 'Dorian LPG';
    };

    $scope.isRioTintoVessel = function() {
        return $scope.selectedVessel.company == 'Rio Tinto';
    }

    $scope.isINSWVessel = function() {
        return $scope.selectedVessel.company == 'International Seaways, Inc.';
    };
    $scope.isCapitalVessel = function() {
        return $scope.selectedVessel.company == 'Capital Ship Management';
    };
    $scope.isRioTintoVessel = function() {
        return $scope.selectedVessel.company == 'Rio Tinto';
    };

    $scope.isSmyrilVessel = function() {
        return $scope.selectedVessel.company == 'Smyril Line';
    }

    // boolean used to determine which vessels are enabled for TI XML
    $scope.isTIVessel = function() {
        return $scope.isINSWVessel() && $scope.vesselSpecs && $scope.vesselSpecs.information.segment == 'VLCC';
    }

    $scope.isAasenVessel = () => $scope.selectedVessel.company === "Aasen Shipping AS";

    $scope.isDieselElectricPropulsion = function() {
        return $scope.vesselSpecs?.information?.prime_mover_type == 'diesel_electric_propulsion'
    }

    $scope.ciiReportingOnlyEnabled = function() {
        return $scope.selectedVessel?.ciiReportingOnlyEnabled;
    }

    $scope.isEmissionsComplianceClient = function() {
        return $scope.selectedVessel.emissionsCompliant || $scope.vesselSpecs?.information?.is_emissions_compliant;
    }

    $scope.reports = ReportService.getVesselReportsList();

    $scope.getCurrentReportIndex = function(currentReport) {
        var index = -1;
        for (var i = 0; i < $scope.reports.length; i++) {
            var report = $scope.reports[i];
            if (report.id == currentReport.id) {
                return i;
            }
        }
        return index;
    };

    $scope.openReportAtIndex = function(index, activeTab) {
        if (index < 0) index = 0;

        if ($rootScope.hasIncompleteVesselReport && index == 0) {
            $scope.backToNewReport();
        } else {
            var report = $scope.reports[index];
            $scope.openReport(report, activeTab, report.status == 'completed');
        }
    }

    $scope.openNextReport = function(report, activeTab) {
        if ($scope.reports.length > 0) {
            $scope.openReportAtIndex($scope.getCurrentReportIndex(report) - 1, activeTab);
        } else {
            $scope.pageState.setLoadingData();
            $scope.refreshVesselReports(function() {
                $scope.reports = ReportService.getVesselReportsList();
                $scope.openReportAtIndex($scope.getCurrentReportIndex(report) - 1, activeTab);
            });
        }
    };

    $scope.openPreviousReport = function(report, activeTab) {
        if ($scope.reports.length > 0) {
            $scope.openReportAtIndex($scope.getCurrentReportIndex(report) + 1, activeTab);
        } else {
            $scope.pageState.setLoadingData();
            $scope.refreshVesselReports(function() {
                $scope.reports = ReportService.getVesselReportsList();
                $scope.openReportAtIndex($scope.getCurrentReportIndex(report) + 1, activeTab);
            });
        }
    };
    $scope.enableBDNReporting = function(scope) {
        /**
         * Used to check bunker tab bdn checkbox
         */
      scope.report.bdn_based_reporting = true;
      ReportService.addBdnNumbers(scope);
      ReportService.setPreTransferStock(scope.report.stock.tanks)
    }

    // Fixes plotLine labels
    // https://github.com/highcharts/highcharts/issues/8477#issuecomment-424353401
    Highcharts.wrap(Highcharts.Axis.prototype, 'getPlotLinePath', function(proceed) {
        var path = proceed.apply(this, Array.prototype.slice.call(arguments, 1));
        if (path) {
            path.flat = false;
        }
        return path;
    });
    // Highcharts global settings for dark mode
    Highcharts.setOptions({
        chart: {
            backgroundColor: themePalette.colors.CHART_BG,
        },
        title: {
            style: {
                color: themePalette.colors.THEME_TEXT_COLOR,
                // font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
            }
        },
        subtitle: {
            style: {
                color: themePalette.colors.THEME_TEXT_COLOR,
            }
        },
        xAxis: {
            // gridLineColor: Color.WHITE,
            // gridLineWidth: 1,
            lineColor: themePalette.colors.CHART_LINE_COLOR,
            tickColor: themePalette.colors.CHART_LINE_COLOR,
            labels: {
                style: {
                    color: themePalette.colors.THEME_TEXT_COLOR,
                    font: `11px ${themePalette.fonts.FONT_FAMILY}`
                }
        
            },
            title: {
                style: {
                    color: themePalette.colors.CHART_AXIS_TITLE_COLOR,
                    // font: `bold 12px ${themePalette.fonts.FONT_FAMILY}`,
                    fontWeight: 'bold',
                    fontSize: '12px',
                    fontFamily: themePalette.fonts.FONT_FAMILY
        
                }            
            }
        },
        yAxis: {
            gridLineColor: themePalette.colors.CHART_GRID_LINE_COLOR,
            lineColor: themePalette.colors.CHART_LINE_COLOR,
            lineWidth: 1,
            tickColor: themePalette.colors.CHART_LINE_COLOR,
            labels: {
                style: {
                    color: themePalette.colors.THEME_TEXT_COLOR,
                    font: `11px ${themePalette.fonts.FONT_FAMILY}`
                }
            },
            title: {
                style: {
                    color: themePalette.colors.CHART_AXIS_TITLE_COLOR,
                    fontWeight: themePalette.fonts.BOLD,
                    fontSize: '12px',
                    fontFamily: themePalette.fonts.FONT_FAMILY
                }            
            }
        },
        legend: {
            itemHoverStyle: {
                color: themePalette.colors.CHART_LEGEND_HIGHLIGHT,
            },
            itemStyle: {
                color: themePalette.colors.THEME_TEXT_COLOR,
                fontWeight: themePalette.fonts.LEGEND_WEIGHT
            }
        }
    });

    $scope.goToNavfleet = function() {
        window.open('https://navfleet.navtor.com/', '_blank');
    }

    $scope.enableValidationsV51 = function(report) {
        return isOfReportType(report, 'Report.SeaReport');
    };

    $scope.isVikingQueen = function() {
        // TODO: Clean up/refactor
        return $rootScope.selectedVessel.name.indexOf('M.V. Viking Queen') != -1;
    }

    $scope.isTransCatalonia = function() {
        // TODO: Clean up/refactor
        return $rootScope.selectedVessel.name.indexOf('Trans Catalonia') != -1;
    }

    $scope.hasPTOorPTI = function() {
        return $scope.vesselSpecs && ($scope.vesselSpecs.information.has_pto || $scope.vesselSpecs.information.has_pti);
    }

    $scope.shouldHideReportCreation = function(reportType) {
        var enforceReportSequence = $scope.vesselSpecs && $scope.vesselSpecs.information.enforce_report_sequence;
        if (!enforceReportSequence) return false;

        // There cannot be a Sea Report just after a Port or Anchor Report. (Drifting Report is excluded)
        var hideSeaReport = reportType == 'sea' && $rootScope.lastReportWasPortOrAnchor;

        // Ensure Maneuvering Report is submitted after a Port Report
        var hideSeaAndAnchor = (reportType == 'sea' || reportType == 'anchor') && $rootScope.lastReportWasPortReport;

        return hideSeaReport || hideSeaAndAnchor;
    };

    $scope.shouldHideOffshoreReportCreation = function() {
        return ReportsOffshoreService.isReportInProgress()
    }

    $scope.isDarkTheme = function() {
        return themePalette.getCurrentTheme() == 'skin-navfleet';
    };

    $scope.isLightTheme = function() {
        return themePalette.getCurrentTheme() == 'skin-blue';
    };
    
    $scope.enableHybrid = function() {
        return enableHybrid;
    };

    $scope.enableOnPremise = function() {
        return enableOnPremise;
    };

    $scope.enableOfflineFeature = function() {
        return (enableHybrid || enableOnPremise);
    };

    $scope.switchTheme = function() {
        if (themePalette.isDarkTheme()) {
            $rootScope.buildTheme = 'skin-blue';
            themePalette.setTheme('skin-blue');
        } else {
            $rootScope.buildTheme = 'skin-navfleet';
            themePalette.setTheme('skin-navfleet');
        }
        $state.reload();
    };

    $scope.register = function(localScope, validationName) {

        var validationObject = ValidationService.getValidations(validationName);
        if (!validationObject) {
            console.log('Error:', validationName);
            $log.error('Registered incorrect validation name in ValidationService.');
            return;
        }

        const watchVariables = () => {
            if (validationObject.scopeRequired) return validationObject.watchVariables($scope)

            return validationObject.watchVariables
        }

        const getFormPath = () => {
            if (validationObject.scopeRequired) return validationObject.formPath($scope)

            return validationObject.formPath
        }
        
        localScope.$watchGroup(watchVariables(), function(newValues, oldValues, scope) {
            
            var validationResult = validationObject.check(newValues, oldValues, scope)
            if (!validationResult) return;

            if (localScope.reportForm.warningMessages) {
                localScope.reportForm.warningMessages[validationResult.path] = null;
                if (validationResult.type == 'error' && validationResult.valid != true) {
                    localScope.reportForm.warningMessages[validationResult.path] = { message: validationResult.message };
                }
    
                localScope.report.warningMessages[validationResult.path] = null;
                if (validationResult.type == 'warning') {
                    localScope.report.warningMessages[validationResult.path] = { message: validationResult.message };
                }
            }
  
            getFormPath().forEach(function(fm) {
                var formPath = localScope.$eval(fm.path);
                if (formPath) {
                    formPath.$setValidity(fm.errorKey, validationResult.valid);
                }
            })
        })
    };

    $scope.saveEngineReportToLocalDb = function(report, closeReport) {
        var vesselId = $scope.selectedVessel.id;
        var reportEngType = {
            'EngineReport.AEReport' : 'aeReports',
            'EngineReport.MEReport' : 'meReports'
        }
        var reportType = reportEngType[report._cls];
        var engReportKey = OfflineDatabase.getEngineReportKey(report);
        var url = baseUrl + vesselId + '/' + reportType;
        var reportStatus = 'draft';
        if (closeReport == true) {
            report.status = 'completed';
            reportStatus = 'ready';
            url += "?closeReport=true";
        }
        
        var method = "POST";
        var body = report;
        return hybridService.serverEngineReportsUpdate({
            reportKey: engReportKey,
            url: url,
            method: method,
            body: JSON.stringify(body),
            type: report._cls,
            vesselId: vesselId,
            username: $rootScope.username,
            report: report,
            reportStatus: reportStatus
        }).then(function(response) {
            var notificationMessage = 'Engine report added to submission queue!';
            var engineReportNumber = report.report_number;
            var engineNumberKey = reportType == 'meReports' ? 'meReportNumber' : 'aeReportNumber';
            if (closeReport == true) {
                // if engine report ready to be submitted then register engine sync
                hybridService.syncReportsServiceWorker('new-engine-report')
                engineReportNumber = report.report_number + 1;
            }
            
            return hybridService.serverMetaGet(vesselId)
            .then(function(offlineVesselData: any) {
                // get updated meta data
                let updatedMetaData: any = offlineVesselData ? Object.assign({}, offlineVesselData) : {vesselId: vesselId};;
                var hasIncompleteEngineReport = !closeReport;
                updatedMetaData.hasIncompleteEngineReport = hasIncompleteEngineReport;
                updatedMetaData[engineNumberKey] = engineReportNumber
                if (hasIncompleteEngineReport) {
                    notificationMessage = 'Report saved locally, will sync automatically when ready!';
                    updatedMetaData.incompleteEngineReport = report;
                } 

                return updatedMetaData;
            }).then(updatedMetaData => {
                // update meta data
                return hybridService.serverMetaUpdate(updatedMetaData).then(response => {
                    _log.okNotify({message: notificationMessage});
                    var engineReport = updatedMetaData.incompleteEngineReport;
                    if (closeReport) {
                        engineReport = ReportService.createNewEngineReport();
                        engineReport.report_number = engineReportNumber;
                    }
                    ReportService.setEngineReport(engineReport);
                    if (reportType == 'meReports') {
                        ReportService.setMEReportNumber(engineReportNumber);
                        $rootScope.hasIncompleteMeEngineReport = updatedMetaData.hasIncompleteEngineReport;
                    } else {
                        ReportService.setAEReportNumber(engineReportNumber);
                        $rootScope.hasIncompleteAeEngineReport = updatedMetaData.hasIncompleteEngineReport;
                    }
                })
               
            }).catch(function() {
                _log.badNotify({message: 'Could not add engine report to local database for submission, please try again later.'});
            }).finally(function(){
                $scope.$broadcast('engine-report-received');
                $scope.$broadcast('engine-reports-list');
            });
        })
    };

    $rootScope.copyToClipboard = function (textContent) {
        var el = document.createElement('textarea');
        el.value = textContent;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    $rootScope.copyContentFromContainer = function(containerId) {
        var copyText = document.getElementById(containerId).textContent;
        $scope.copyToClipboard(copyText);
        $rootScope.emailReport.selected = containerId;
    };

    $scope.refreshSensorData = function(override) {
        $scope.$broadcast('update-report-sensor');
        $scope.hideModal('refreshSensorData');
    };

    $scope.modals = [];
    $scope.$on('modal-message', function(event, args) {
        // 1. open report should show refresh modal
        // 2. refresh report should show refresh modal
        // 3. manual save report should show merge modal
        // 4. auto save should not show any modal
        if (args.modalId && modalService.modalQueue.indexOf(args.modalId) == -1) {
            let skip = args.modalId == 'refreshSensorData' && (modalService.modalQueue.indexOf('reportMerge') > -1 || AutoSaveService.reportWasAutoSaved());
            if (!skip) {
                modalService.enqueue(args.modalId);
            }
        }
        $scope.modals = modalService.modalQueue;
        $scope.currentModalId = modalService.getCurrentModal();
    });

    $scope.hideModal = function(modalIdToRemove) {
        $scope.modals = modalService.dequeue(modalIdToRemove);
        $scope.currentModalId = modalService.getCurrentModal();
    }
    
    function removeModalOnCloseEventListener() {
        $('#reportModal').on('hidden.bs.modal', function() {
            if (modalService.modalQueue && modalService.modalQueue.length > 0) {
                $scope.hideModal(modalService.getCurrentModal());
            }
        })
    }
    
    executeFnOnElementLoad('#reportModal', removeModalOnCloseEventListener);
}]);
