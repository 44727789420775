import { routerApp, baseUrl, sumValues, enableHybrid, enableOnPremise } from '../app.module';
import moment from 'moment';
import angular from 'angular';
import get from 'lodash.get';
import { Hoses } from '../models/report';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import { httpRequestGenerator } from './utilities';
import cloneDeep from 'lodash.clonedeep';
import _ from 'lodash';
import { TankStock, TankStockObject } from '../models/TankStock';

var reportTypeStates = {
    "Report.SeaReport": "site.seaReport",
    "Report.PortReport": "site.portReport",
    "Report.AnchorReport": "site.anchorReport",
    "Report.ManeuveringReport": "site.maneuveringReport",
};
const statesToReportTypes = _.invert(reportTypeStates);

routerApp.factory('ReportService', function() {

    // the format to serialize all the dates to, this should be used when
    // dealing with datepickers
    var _dateFormat = 'DD/MM/YYYY HH:mm';

    var newVesselReport;
    var carryForwardVesselReport;;

    var newEngineReport;
    var newOffhireReport;
    var offhireReportNumber;

    var vesselReportNumber;
    var meReportNumber;
    var aeReportNumber;

    // dashboard data
    var dashboardData;

    // lists of reports
    var vesselReportsList = [];
    var meReportList = [];
    var aeReportList = [];
    var offhireReportList = [];
    // lists of tanksNotInUse
    var tankInUseHidden = {};
    // vessel carry over rules
    var carryForwardFields = {};

    var engineReportTypeStates = {
        'EngineReport.MEReport': 'site.meReport',
        'EngineReport.AEReport': 'site.aeReport',
    }
  let preTransferStock = {};

    var clearService = function() {
        // clears all objects in the service, this is used when we switch to a
        // new vessel to make sure that all the saved data needed for reporting
        // is cleared up
        newVesselReport = null;
        carryForwardVesselReport = null;

        newEngineReport = null;
        newOffhireReport = null;

        vesselReportNumber = 1;
        meReportNumber = 1;
        aeReportNumber = 1;

        vesselReportsList = [];
        meReportList = [];
        aeReportList = [];
        offhireReportList = [];
        carryForwardFields = {};
        preTransferStock = {};    
    }
  
  const setPreTransferStock = function (stock) {
    if (!_.isEmpty(preTransferStock)) return
    preTransferStock = _.cloneDeep(stock)
  }
  const getPreTransferStock = function () {
    return preTransferStock
  }

    var parseFieldDate = function(field, returnDefaultField=false) {
        if (field != undefined && field._isAMomentObject) {
            return field;
        }
        if (field != undefined && field["$date"] != undefined) {
            // we must parse this and change it to UTC so that we can extract
            // the hours and date into a string in the right format, anything
            // is fine here as long as it gets displayed the same machine.
            // since we're showing both timezones and time on the page, we would
            // like to see the timezone of the vessel and not our timezone
            // since we don't care about our own timezone in the vessel's reports
            return moment(field["$date"]).utc();
        } else if ((enableHybrid || enableOnPremise || returnDefaultField)) {
            // report saved offline sometimes have date field as string or is undefined. 
            return field;
        } else {
            return null;
        }
    }

    var formatMoment = function(date) {
        if (date != undefined && date._isAMomentObject) {
            return date.format(_dateFormat);
        } else {
            return date;
        }
    }

    // when we get a report back from the database, we run this functiono on the
    // report to make sure that all the dates are parsed into the right format
    // the dates we get from mongoengine engine are objects that have a $date
    // object, so we just parse that and use the datepicker date format
    var applyDatesOnReport = function(report) {

        // operational dates
        if (report.operational == undefined) report.operational = {};
        if (report.operational.report_from) report.operational.report_from = formatMoment(parseFieldDate(report.operational.report_from));
        if (report.operational.report_to) report.operational.report_to = formatMoment(parseFieldDate(report.operational.report_to));
        if (report.operational.report_creation) report.operational.report_creation = formatMoment(parseFieldDate(report.operational.report_creation));
        if (report.operational.eta) report.operational.eta = formatMoment(parseFieldDate(report.operational.eta));
        if (report.operational.previous_eta) report.operational.previous_eta = formatMoment(parseFieldDate(report.operational.eta));
        if (report.operational.ballast_water_sample_date) report.operational.ballast_water_sample_date = formatMoment(parseFieldDate(report.operational.ballast_water_sample_date));
        if (report.operational.enter_eca) report.operational.enter_eca = formatMoment(parseFieldDate(report.operational.enter_eca));
        if (report.operational.exit_eca) report.operational.exit_eca = formatMoment(parseFieldDate(report.operational.exit_eca));
        if (report.operational.first_line) report.operational.first_line = formatMoment(parseFieldDate(report.operational.first_line));
        if (report.operational.gangway_down) report.operational.gangway_down = formatMoment(parseFieldDate(report.operational.gangway_down));

        // port report dates, in case those are there
        if (report.operational.etd) report.operational.etd = formatMoment(parseFieldDate(report.operational.etd));
        if (report.operational.all_fast) report.operational.all_fast = formatMoment(parseFieldDate(report.operational.all_fast));
        if (report.operational.gangway_ready) report.operational.gangway_ready = formatMoment(parseFieldDate(report.operational.gangway_ready));
        if (report.operational.hoses_connected) report.operational.hoses_connected = formatMoment(parseFieldDate(report.operational.hoses_connected));
        if (report.operational.commence) report.operational.commence = formatMoment(parseFieldDate(report.operational.commence));
        if (report.operational.finish) report.operational.finish = formatMoment(parseFieldDate(report.operational.finish));
        if (report.operational.hoses_disconnected) report.operational.hoses_disconnected = formatMoment(parseFieldDate(report.operational.hoses_disconnected));
        if (report.operational.standby_engines) report.operational.standby_engines = formatMoment(parseFieldDate(report.operational.standby_engines));
        if (report.operational.eta_pilot_station) report.operational.eta_pilot_station = formatMoment(parseFieldDate(report.operational.eta_pilot_station));

        // Anchor/Maneuvering report dates
        if (report.operational.cosp) report.operational.cosp = formatMoment(parseFieldDate(report.operational.cosp));
        if (report.operational.eosp) report.operational.eosp = formatMoment(parseFieldDate(report.operational.eosp));
        if (report.operational.anchored) report.operational.anchored = formatMoment(parseFieldDate(report.operational.anchored));
        if (report.operational.anchor_heaved) report.operational.anchor_heaved = formatMoment(parseFieldDate(report.operational.anchor_heaved));
        if (report.operational.inspection_date) report.operational.inspection_date = formatMoment(parseFieldDate(report.operational.inspection_date));
        if (report.operational.berthing_time) report.operational.berthing_time = formatMoment(parseFieldDate(report.operational.berthing_time));
        if (report.operational.main_engine_standby) report.operational.main_engine_standby = formatMoment(parseFieldDate(report.operational.main_engine_standby));
        if (report.operational.time_1_hour_notice) report.operational.time_1_hour_notice = formatMoment(parseFieldDate(report.operational.time_1_hour_notice));
        if (report.operational.commenced_drifting) report.operational.commenced_drifting = formatMoment(parseFieldDate(report.operational.commenced_drifting));
        if (report.operational.completed_drifting) report.operational.completed_drifting = formatMoment(parseFieldDate(report.operational.completed_drifting));
        
        // Port dates
        if (report.operational.nor_tendered) report.operational.nor_tendered = formatMoment(parseFieldDate(report.operational.nor_tendered));
        if (report.operational.start_ballasting) report.operational.start_ballasting = formatMoment(parseFieldDate(report.operational.start_ballasting));
        if (report.operational.end_ballasting) report.operational.end_ballasting = formatMoment(parseFieldDate(report.operational.end_ballasting));
        if (report.operational.pilot_disembarked) report.operational.pilot_disembarked = formatMoment(parseFieldDate(report.operational.pilot_disembarked));
        if (report.operational.start_deballasting) report.operational.start_deballasting = formatMoment(parseFieldDate(report.operational.start_deballasting));
        if (report.operational.end_deballasting) report.operational.end_deballasting = formatMoment(parseFieldDate(report.operational.end_deballasting));
        if (report.operational.cargo_documents_onboard) report.operational.cargo_documents_onboard = formatMoment(parseFieldDate(report.operational.cargo_documents_onboard));
        if (report.operational.start_discharging) report.operational.start_discharging = formatMoment(parseFieldDate(report.operational.start_discharging));
        if (report.operational.end_discharging) report.operational.end_discharging = formatMoment(parseFieldDate(report.operational.end_discharging));
        if (report.operational.completed_tank_inspection) report.operational.completed_tank_inspection = formatMoment(parseFieldDate(report.operational.completed_tank_inspection));
        if (report.operational.finish_with_engines) report.operational.finish_with_engines = formatMoment(parseFieldDate(report.operational.finish_with_engines));
        if (report.operational.start_cargo_operation) report.operational.start_cargo_operation = formatMoment(parseFieldDate(report.operational.start_cargo_operation));
        if (report.operational.end_cargo_operation) report.operational.end_cargo_operation = formatMoment(parseFieldDate(report.operational.end_cargo_operation));

        // cargo details dates
        if (report.cargo == undefined) report.cargo = {};
        if (report.cargo.bl_issue != undefined) {
            for (var i = 0; i < report.cargo.bl_issue.length; i++) {
                report.cargo.bl_issue[i] = formatMoment(parseFieldDate(report.cargo.bl_issue[i]));
            }
        }
        if (report.cargo.bl_discharge != undefined) {
            for (var i = 0; i < report.cargo.bl_discharge.length; i++) {
                report.cargo.bl_discharge[i] = formatMoment(parseFieldDate(report.cargo.bl_discharge[i]));
            }
        }
        if (report.cargo.hoses?.hose_on_operations) {
            for (let op of report.cargo.hoses.hose_on_operations) {
                op.timestamp = formatMoment(parseFieldDate(op.timestamp));
            }
        }
        if (report.cargo.hoses?.hose_off_operations) {
            for (let op of report.cargo.hoses.hose_off_operations) {
                op.timestamp = formatMoment(parseFieldDate(op.timestamp));
            }
        }

        // maneuvering report dates, in case those are there
        if (report.operational.pilot_on_board) report.operational.pilot_on_board = formatMoment(parseFieldDate(report.operational.pilot_on_board));
        if (report.operational.last_line_onboard) report.operational.last_line_onboard = formatMoment(parseFieldDate(report.operational.last_line_onboard));
        if (report.operational.pilot_off) report.operational.pilot_off = formatMoment(parseFieldDate(report.operational.pilot_off));

        // position dates
        if (report.position == undefined) report.position = {};
        if (report.position.observation) report.position.observation = formatMoment(parseFieldDate(report.position.observation));
        if (report.position.observations) {
            for (var i = 0; i < report.position.observations.length; i++) {
                if (report.position.observations[i].observation) report.position.observations[i].observation = formatMoment(parseFieldDate(report.position.observations[i].observation));
            }
        }

        // consumption dates
        if (report.consumption == undefined) report.consumption = {};
        if (report.consumption.oil_time_of_transfer) report.consumption.oil_time_of_transfer = formatMoment(parseFieldDate(report.consumption.oil_time_of_transfer));
        if (report.consumption.system_run_1_from) report.consumption.system_run_1_from = formatMoment(parseFieldDate(report.consumption.system_run_1_from));
        if (report.consumption.system_run_1_to) report.consumption.system_run_1_to = formatMoment(parseFieldDate(report.consumption.system_run_1_to));
        if (report.consumption.system_run_2_from) report.consumption.system_run_2_from = formatMoment(parseFieldDate(report.consumption.system_run_2_from));
        if (report.consumption.system_run_2_to) report.consumption.system_run_2_to = formatMoment(parseFieldDate(report.consumption.system_run_2_to));
        if (report.consumption.system_run_3_from) report.consumption.system_run_3_from = formatMoment(parseFieldDate(report.consumption.system_run_3_from));
        if (report.consumption.system_run_3_to) report.consumption.system_run_3_to = formatMoment(parseFieldDate(report.consumption.system_run_3_to));
        if (report.consumption.system_run_4_from) report.consumption.system_run_4_from = formatMoment(parseFieldDate(report.consumption.system_run_4_from));
        if (report.consumption.system_run_4_to) report.consumption.system_run_4_to = formatMoment(parseFieldDate(report.consumption.system_run_4_to));

        // stock date
        if (report.stock == undefined) report.stock = {};
        if (report.stock.fuel_water_stock_date) report.stock.fuel_water_stock_date = formatMoment(parseFieldDate(report.stock.fuel_water_stock_date));
        if (report.stock.lube_datetime) report.stock.lube_datetime = formatMoment(parseFieldDate(report.stock.lube_datetime));
        Object.keys(report.stock.tanks).map(function(tankId) {
            var stockTank = report.stock.tanks[tankId];
            if (stockTank.bunker_date) stockTank.bunker_date = formatMoment(parseFieldDate(stockTank.bunker_date));
            if (stockTank.stable_until) stockTank.stable_until = formatMoment(parseFieldDate(stockTank.stable_until));
            if (stockTank.modified_date) stockTank.modified_date = formatMoment(parseFieldDate(stockTank.modified_date));
        });

        // last reported stock date
        if (report.last_reported_stock == undefined) report.last_reported_stock = {};
        if (report.last_reported_stock != undefined) {
            if (report.last_reported_stock.fuel_water_stock_date) report.last_reported_stock.fuel_water_stock_date = formatMoment(parseFieldDate(report.last_reported_stock.fuel_water_stock_date));
            if (report.last_reported_stock.lube_datetime) report.last_reported_stock.lube_datetime = formatMoment(parseFieldDate(report.last_reported_stock.lube_datetime));
            if (report.last_reported_stock.tanks) {
                Object.keys(report.last_reported_stock.tanks).map(function(tankId) {
                    let lastReportedStockTank = report.last_reported_stock.tanks[tankId];
                    if (lastReportedStockTank.bunker_date) lastReportedStockTank.bunker_date = formatMoment(parseFieldDate(lastReportedStockTank.bunker_date));
                    if (lastReportedStockTank.stable_until) lastReportedStockTank.stable_until = formatMoment(parseFieldDate(lastReportedStockTank.stable_until));
                    if (lastReportedStockTank.modified_date) lastReportedStockTank.modified_date = formatMoment(parseFieldDate(lastReportedStockTank.modified_date));
                });
            }
        }

        // bunker dates
        if (report.bunker == undefined) report.bunker = {};
        if (report.bunker.fuel_started_bunkering_date) report.bunker.fuel_started_bunkering_date = formatMoment(parseFieldDate(report.bunker.fuel_started_bunkering_date));
        if (report.bunker.fuel_bunkering_date) report.bunker.fuel_bunkering_date = formatMoment(parseFieldDate(report.bunker.fuel_bunkering_date));
        if (report.bunker.barge_alongside) report.bunker.barge_alongside = formatMoment(parseFieldDate(report.bunker.barge_alongside));
        if (report.bunker.barge_away) report.bunker.barge_away = formatMoment(parseFieldDate(report.bunker.barge_away));
        if (report.bunker.lube_started_bunkering_date) report.bunker.lube_started_bunkering_date = formatMoment(parseFieldDate(report.bunker.lube_started_bunkering_date));
        if (report.bunker.lube_bunkering_date) report.bunker.lube_bunkering_date = formatMoment(parseFieldDate(report.bunker.lube_bunkering_date));
        if (report.bunker.fuel_started_debunkering_date) report.bunker.fuel_started_debunkering_date = formatMoment(parseFieldDate(report.bunker.fuel_started_debunkering_date));
        if (report.bunker.fuel_debunkering_date) report.bunker.fuel_debunkering_date = formatMoment(parseFieldDate(report.bunker.fuel_debunkering_date));
        if (report.bunker.stable_until_hshfo) report.bunker.stable_until_hshfo = formatMoment(parseFieldDate(report.bunker.stable_until_hshfo));
        if (report.bunker.stable_until_lshfo) report.bunker.stable_until_lshfo = formatMoment(parseFieldDate(report.bunker.stable_until_lshfo));
        if (report.bunker.stable_until_ulsfo) report.bunker.stable_until_ulsfo = formatMoment(parseFieldDate(report.bunker.stable_until_ulsfo));
        if (report.bunker.stable_until_hsmdo) report.bunker.stable_until_hsmdo = formatMoment(parseFieldDate(report.bunker.stable_until_hsmdo));
        if (report.bunker.stable_until_lsmdo) report.bunker.stable_until_lsmdo = formatMoment(parseFieldDate(report.bunker.stable_until_lsmdo));
        if (report.bunker.stable_until_hsmgo) report.bunker.stable_until_hsmgo = formatMoment(parseFieldDate(report.bunker.stable_until_hsmgo));
        if (report.bunker.stable_until_lsmgo) report.bunker.stable_until_lsmgo = formatMoment(parseFieldDate(report.bunker.stable_until_lsmgo));

        if (report.bunker.stable_until_hfo) report.bunker.stable_until_hfo = formatMoment(parseFieldDate(report.bunker.stable_until_hfo));
        if (report.bunker.stable_until_lfo) report.bunker.stable_until_lfo = formatMoment(parseFieldDate(report.bunker.stable_until_lfo));
        if (report.bunker.stable_until_mgo) report.bunker.stable_until_mgo = formatMoment(parseFieldDate(report.bunker.stable_until_mgo));
        if (report.bunker.stable_until_mdo) report.bunker.stable_until_mdo = formatMoment(parseFieldDate(report.bunker.stable_until_mdo));
        if (report.bunker.stable_until_b10lfo) report.bunker.stable_until_b10lfo = formatMoment(parseFieldDate(report.bunker.stable_until_b10lfo));
        if (report.bunker.stable_until_b10mgo) report.bunker.stable_until_b10mgo = formatMoment(parseFieldDate(report.bunker.stable_until_b10mgo));
        if (report.bunker.stable_until_biolfo) report.bunker.stable_until_biolfo = formatMoment(parseFieldDate(report.bunker.stable_until_biolfo));
        if (report.bunker.stable_until_biomgo) report.bunker.stable_until_biomgo = formatMoment(parseFieldDate(report.bunker.stable_until_biomgo));
        if (report.bunker.stable_until_ulsfo2020) report.bunker.stable_until_ulsfo2020 = formatMoment(parseFieldDate(report.bunker.stable_until_ulsfo2020));
        if (report.bunker.stable_until_ulslfo2020) report.bunker.stable_until_ulslfo2020 = formatMoment(parseFieldDate(report.bunker.stable_until_ulslfo2020));
        if (report.bunker.stable_until_ulsmdo2020) report.bunker.stable_until_ulsmdo2020 = formatMoment(parseFieldDate(report.bunker.stable_until_ulsmdo2020));
        if (report.bunker.stable_until_ulsmgo2020) report.bunker.stable_until_ulsmgo2020 = formatMoment(parseFieldDate(report.bunker.stable_until_ulsmgo2020));
        if (report.bunker.stable_until_vlsfo2020) report.bunker.stable_until_vlsfo2020 = formatMoment(parseFieldDate(report.bunker.stable_until_vlsfo2020));
        if (report.bunker.stable_until_vlslfo2020) report.bunker.stable_until_vlslfo2020 = formatMoment(parseFieldDate(report.bunker.stable_until_vlslfo2020));
        if (report.bunker.stable_until_lpgp) report.bunker.stable_until_lpgp = formatMoment(parseFieldDate(report.bunker.stable_until_lpgp));
        if (report.bunker.stable_until_lpgb) report.bunker.stable_until_lpgb = formatMoment(parseFieldDate(report.bunker.stable_until_lpgb));
        if (report.bunker.stable_until_lng) report.bunker.stable_until_lng = formatMoment(parseFieldDate(report.bunker.stable_until_lng));
        if (report.bunker.stable_until_methanol) report.bunker.stable_until_methanol = formatMoment(parseFieldDate(report.bunker.stable_until_methanol));
        if (report.bunker.stable_until_ethanol) report.bunker.stable_until_ethanol = formatMoment(parseFieldDate(report.bunker.stable_until_ethanol));
        if (report.bunker.stable_until_other) report.bunker.stable_until_other = formatMoment(parseFieldDate(report.bunker.stable_until_other));
        
        // bdn
        if (report.bunker.bdn_based_reporting_bunkered_fuels && report.bunker.bdn_based_reporting_bunkered_fuels.length > 0) {
            report.bunker.bdn_based_reporting_bunkered_fuels.map(bunker_fuel_tank_stock => {
                bunker_fuel_tank_stock.stable_until = formatMoment(parseFieldDate(bunker_fuel_tank_stock.stable_until));
                return bunker_fuel_tank_stock;
            })
        }

        // Engine dates
        if (report.engine && report.engine.main_engines) {
            for (var i = 0; i < report.engine.main_engines.length; i++) {
                if (report.engine.main_engines[i].reading_date) report.engine.main_engines[i].reading_date = formatMoment(parseFieldDate(report.engine.main_engines[i].reading_date));
            }
        }

        return report;
    }

    var applyDatesOnEngineReport = function(report) {

        // details dates
        if (report.details == undefined) report.details = {};
        report.details.report_from = formatMoment(parseFieldDate(report.details.report_from));
        report.details.report_to = formatMoment(parseFieldDate(report.details.report_to));
        report.details.report_date = formatMoment(parseFieldDate(report.details.report_date));
        report.creation_time = formatMoment(parseFieldDate(report.creation_time));

        return report;
    }

    var applyDatesOnOffhireReport = function(report) {
        report.report_from = parseFieldDate(report.report_from);
        if (report.report_from && report.report_from._isAMomentObject) {
            report.report_from = report.report_from.format('DD/MM/YYYY HH:mm');
        }
        report.report_to = parseFieldDate(report.report_to);
        if (report.report_to && report.report_to._isAMomentObject) {
            report.report_to = report.report_to.format('DD/MM/YYYY HH:mm');
        }
        return report;
    };

    var getTotalFuelConsumption = function(grade, report) {
        if (grade != undefined && typeof grade == 'string' && report.consumption != undefined) {
            var consumptions = [
                report.consumption['main_engine_' + grade], 
                report.consumption['aux_engine_' + grade], 
                report.consumption['aux_boiler_' + grade], 
                report.consumption['ig_generator_' + grade], 
                report.consumption['other_' + grade], 
                report.consumption['incinerator_' + grade],
                report.consumption['mdg_port_' + grade],
                report.consumption['mdg_starboard_' + grade],
                report.consumption['hdg_' + grade],
                report.consumption['dg_' + grade]
            ];
            return sumValues(consumptions);
        }
    };

    var updateVesselReportWithCarryForwardReport = function(report, carryForwardReport, formatMoments) {
        carryForwardVesselReport = carryForwardReport;
        if (report != undefined && report.id == undefined) {
          if (carryForwardReport.bdn_based_reporting) report.bdn_based_reporting = carryForwardReport.bdn_based_reporting;

            if (report.operational == undefined) report.operational = {};
            if (!(report._cls == 'Report.SeaReport' && carryForwardReport && carryForwardReport._cls === 'Report.ManeuveringReport' && carryForwardReport.operational && carryForwardReport.operational.maneuvering_type == 'departure')) {
                    report.operational.voyage_number = carryForwardReport.operational.voyage_number;
            }
            report.operational.cp_speed = carryForwardReport.operational.cp_speed;
            report.operational.cp_fuel = carryForwardReport.operational.cp_fuel;

            report.operational.report_from = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.report_to)) : carryForwardReport.operational.report_to;
            report.operational.report_to = formatMoments ? formatMoment(parseFieldDate(moment(report.operational.report_from, 'DD/MM/YYYY HH:mm').startOf('day').add(moment.duration(36, 'hours')))) : formatMoment(moment(report.operational.report_from, 'DD/MM/YYYY HH:mm').startOf('day').add(moment.duration(36, 'hours')));
            report.operational.eta = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.eta)) : carryForwardReport.operational.eta;
            report.operational.previous_eta = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.eta)) : carryForwardReport.operational.eta;
            report.operational.eta_timezone = carryForwardReport.operational.eta_timezone;
            if (carryForwardByDefault(carryForwardReport, report, 'operational.etd')) report.operational.etd = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.etd)) : carryForwardReport.operational.etd;

            // dates
            if (carryForwardReport.operational.current_timezone != undefined) {
               report.operational.previous_timezone = carryForwardReport.operational.current_timezone;
               report.operational.timezone = carryForwardReport.operational.current_timezone;
            } else {
                report.operational.previous_timezone = carryForwardReport.operational.timezone;
                report.operational.timezone = carryForwardReport.operational.timezone;
            }
           
            if (carryForwardReport.operational.nor_tendered && carryForwardIfEnabled(carryForwardReport, report, 'operational.nor_tendered')) report.operational.nor_tendered = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.nor_tendered)) : carryForwardReport.operational.nor_tendered;
            if (carryForwardReport.operational.all_fast && carryForwardIfEnabled(carryForwardReport, report, 'operational.all_fast')) report.operational.all_fast = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.all_fast)) : carryForwardReport.operational.all_fast;
            if (carryForwardReport.operational.finish_with_engines && carryForwardIfEnabled(carryForwardReport, report, 'operational.finish_with_engines')) report.operational.finish_with_engines = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.finish_with_engines)) : carryForwardReport.operational.finish_with_engines;
            if (carryForwardReport.operational.start_cargo_operation && carryForwardIfEnabled(carryForwardReport, report, 'operational.start_cargo_operation')) report.operational.start_cargo_operation = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.start_cargo_operation)) : carryForwardReport.operational.start_cargo_operation;
            if (carryForwardReport.operational.end_cargo_operation && carryForwardIfEnabled(carryForwardReport, report, 'operational.end_cargo_operation')) report.operational.end_cargo_operation = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.end_cargo_operation)) : carryForwardReport.operational.end_cargo_operation;
            if (carryForwardReport.operational.hoses_connected && carryForwardIfEnabled(carryForwardReport, report, 'operational.hoses_connected')) report.operational.hoses_connected = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.hoses_connected)) : carryForwardReport.operational.hoses_connected;
            if (carryForwardReport.operational.commence && carryForwardIfEnabled(carryForwardReport, report, 'operational.commence')) report.operational.commence = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.commence)) : carryForwardReport.operational.commence;
            if (carryForwardReport.operational.finish && carryForwardIfEnabled(carryForwardReport, report, 'operational.finish')) report.operational.finish = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.finish)) : carryForwardReport.operational.finish;
            if (carryForwardReport.operational.hoses_disconnected && carryForwardIfEnabled(carryForwardReport, report, 'operational.hoses_disconnected')) report.operational.hoses_disconnected = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.hoses_disconnected)) : carryForwardReport.operational.hoses_disconnected;
            if (carryForwardReport.operational.standby_engines && carryForwardIfEnabled(carryForwardReport, report, 'operational.standby_engines')) report.operational.standby_engines = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.standby_engines)) : carryForwardReport.operational.standby_engines;
            if (carryForwardReport.operational.start_ballasting && carryForwardIfEnabled(carryForwardReport, report, 'operational.start_ballasting')) report.operational.start_ballasting = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.start_ballasting)) : carryForwardReport.operational.start_ballasting;
            if (carryForwardReport.operational.end_ballasting && carryForwardIfEnabled(carryForwardReport, report, 'operational.end_ballasting')) report.operational.end_ballasting = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.end_ballasting)) : carryForwardReport.operational.end_ballasting;
            if (carryForwardReport.operational.pilot_disembarked && carryForwardIfEnabled(carryForwardReport, report, 'operational.pilot_disembarked')) report.operational.pilot_disembarked = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.pilot_disembarked)) : carryForwardReport.operational.pilot_disembarked;
            if (carryForwardReport.operational.pilot_on_board && carryForwardIfEnabled(carryForwardReport, report, 'operational.pilot_on_board')) report.operational.pilot_on_board = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.pilot_on_board)) : carryForwardReport.operational.pilot_on_board;
            if (carryForwardReport.operational.start_deballasting && carryForwardIfEnabled(carryForwardReport, report, 'operational.start_deballasting')) report.operational.start_deballasting = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.start_deballasting)) : carryForwardReport.operational.start_deballasting;
            if (carryForwardReport.operational.end_deballasting && carryForwardIfEnabled(carryForwardReport, report, 'operational.end_deballasting')) report.operational.end_deballasting = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.end_deballasting)) : carryForwardReport.operational.end_deballasting;
            if (carryForwardReport.operational.cargo_documents_onboard && carryForwardIfEnabled(carryForwardReport, report, 'operational.cargo_documents_onboard')) report.operational.cargo_documents_onboard = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.cargo_documents_onboard)) : carryForwardReport.operational.cargo_documents_onboard;
            if (carryForwardReport.operational.start_discharging && carryForwardIfEnabled(carryForwardReport, report, 'operational.start_discharging')) report.operational.start_discharging = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.start_discharging)) : carryForwardReport.operational.start_discharging;
            if (carryForwardReport.operational.end_discharging && carryForwardIfEnabled(carryForwardReport, report, 'operational.end_discharging')) report.operational.end_discharging = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.end_discharging)) : carryForwardReport.operational.end_discharging;
            if (carryForwardReport.operational.completed_tank_inspection && carryForwardIfEnabled(carryForwardReport, report, 'operational.completed_tank_inspection')) report.operational.completed_tank_inspection = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.completed_tank_inspection)) : carryForwardReport.operational.completed_tank_inspection;
            if (carryForwardReport.operational.ballast_water_sample_date && carryForwardIfEnabled(carryForwardReport, report, 'operational.ballast_water_sample_date')) report.operational.ballast_water_sample_date = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.ballast_water_sample_date)) : carryForwardReport.operational.ballast_water_sample_date;
            if (carryForwardReport.operational.ballast_water_count_of_cells && carryForwardIfEnabled(carryForwardReport, report, 'operational.ballast_water_count_of_cells')) report.operational.ballast_water_count_of_cells = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.ballast_water_count_of_cells)) : carryForwardReport.operational.ballast_water_count_of_cells;
            if (carryForwardReport.operational.eta_pilot_station && carryForwardIfEnabled(carryForwardReport, report, 'operational.eta_pilot_station')) report.operational.eta_pilot_station = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.eta_pilot_station)) : carryForwardReport.operational.eta_pilot_station;
            if (carryForwardReport.operational.time_1_hour_notice && carryForwardIfEnabled(carryForwardReport, report, 'operational.time_1_hour_notice')) report.operational.time_1_hour_notice = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.time_1_hour_notice)) : carryForwardReport.operational.time_1_hour_notice
            if (carryForwardReport.operational.commenced_drifting && carryForwardIfEnabled(carryForwardReport, report, 'operational.commenced_drifting')) report.operational.commenced_drifting = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.commenced_drifting)) : carryForwardReport.operational.commenced_drifting
            if (carryForwardReport.operational.completed_drifting && carryForwardIfEnabled(carryForwardReport, report, 'operational.completed_drifting')) report.operational.completed_drifting = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.completed_drifting)) : carryForwardReport.operational.completed_drifting
            if (carryForwardReport.operational.main_engine_standby && carryForwardIfEnabled(carryForwardReport, report, 'operational.main_engine_standby')) report.operational.main_engine_standby = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.main_engine_standby)) : carryForwardReport.operational.main_engine_standby
            if (carryForwardReport.operational.anchored && carryForwardIfEnabled(carryForwardReport, report, 'operational.anchored')) report.operational.anchored = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.anchored)) : carryForwardReport.operational.anchored
            if (carryForwardReport.operational.anchor_heaved && carryForwardIfEnabled(carryForwardReport, report, 'operational.anchor_heaved')) report.operational.anchor_heaved = formatMoments ? formatMoment(parseFieldDate(carryForwardReport.operational.anchor_heaved)) : carryForwardReport.operational.anchor_heaved
            
            // in ECA
            report.operational.previously_in_eca = carryForwardReport.operational.in_eca;

            report.operational.captain_name = carryForwardReport.operational.captain_name;
            report.operational.chief_engineer = carryForwardReport.operational.chief_engineer;
            report.operational.operating_code = undefined;
            report.operational.instruction = carryForwardReport.operational.instruction;
            report.operational.ballast_code = carryForwardReport.operational.ballast_code;
            report.operational.routing_advice_followed = carryForwardReport.operational.routing_advice_followed;
            report.operational.next_port = carryForwardReport.operational.next_port;
            // carry over Next Port
            if (carryForwardReport._cls == 'Report.PortReport' && ['Report.SeaReport', 'Report.ManeuveringReport'].indexOf(report._cls) > -1) {
                report.operational.next_port = carryForwardReport.operational.destination_port;
            } else if (['Report.SeaReport', 'Report.ManeuveringReport'].indexOf(carryForwardReport._cls) > -1 && report._cls == 'Report.PortReport') {
                report.operational.destination_port =  carryForwardReport.operational.next_port;
            } else if (carryForwardReport._cls == 'Report.PortReport' && report._cls == 'Report.PortReport') {
                report.operational.destination_port = carryForwardReport.operational.destination_port;
            }
            
            if (carryForwardReport._cls == 'Report.SeaReport' && carryForwardReport.operational.operating_code == '8') {
                if (report._cls != 'Report.SeaReport') report.operational.in_sts_voyage = true;
            }
            else if (carryForwardReport.operational.in_sts_voyage == true) {
                if (['Report.AnchorReport', 'Report.ManeuveringReport'].indexOf(report._cls) > -1) {
                    report.operational.in_sts_voyage = true;
                }
            }

            report.operational.previous_next_port = carryForwardReport.operational.next_port;
            report.operational.weight = report._cls != 'Report.PortReport' ? carryForwardReport.operational.weight : undefined;
            report.operational.ballast_water = report._cls != 'Report.PortReport' ? carryForwardReport.operational.ballast_water : undefined;
            report.operational.fixed_ballast = carryForwardReport.operational.fixed_ballast;
            report.operational.constant_weight = carryForwardReport.operational.constant_weight;
            report.operational.cargo_weight_carried_forward = carryForwardReport._cls == 'Report.SeaReport' ? carryForwardReport.operational.weight : carryForwardReport.operational.cargo_weight_carried_forward;

            report.operational.draught_forward = carryForwardReport.operational.draught_forward;
            report.operational.draught_aft = carryForwardReport.operational.draught_aft;
            report.operational.all_fast_draught_forward = carryForwardReport.operational.draught_forward;
            report.operational.all_fast_draught_aft = carryForwardReport.operational.draught_aft;

            report.operational.number_of_reefer_containers_20_ft = report._cls != 'Report.PortReport' ? carryForwardReport.operational.number_of_reefer_containers_20_ft : undefined;
            report.operational.number_of_reefer_containers_40_ft = report._cls != 'Report.PortReport' ? carryForwardReport.operational.number_of_reefer_containers_40_ft : undefined;
            report.operational.number_of_normal_containers_20_ft = report._cls != 'Report.PortReport' ? carryForwardReport.operational.number_of_normal_containers_20_ft : undefined;
            report.operational.number_of_normal_containers_40_ft = report._cls != 'Report.PortReport' ? carryForwardReport.operational.number_of_normal_containers_40_ft : undefined;

            report.operational.lpg_tanks = {};
            if (carryForwardReport.operational.lpg_tanks != undefined) {
                Object.keys(carryForwardReport.operational.lpg_tanks).map(function(lpg_tank_id) {
                    var lpg_tank = carryForwardReport.operational.lpg_tanks[lpg_tank_id];
                    report.operational.lpg_tanks[lpg_tank_id] = {
                        lpg_cargo_grade: lpg_tank.lpg_cargo_grade,
                        lpg_pressure: lpg_tank.lpg_pressure,
                        lpg_temperature: lpg_tank.lpg_temperature,
                    };
                })
            }
            

            // todo: cargo type should be loaded from recent port report and carried forward through sea reports
            // commenting out for now cause this needs testing
            // if (report._cls == 'Report.SeaReport' && carryForwardReport._cls == 'Report.PortReport' && carryForwardReport.cargo.cargo_grade[0] &&
            //  (carryForwardReport.cargo.operation_type == "Loading and Discharging" || carryForwardReport.cargo.operation_type == "Loading")) {
            //     report.operational.cargo_type = carryForwardReport.cargo.cargo_grade[0];
            // }
            
            // cargo data
            if (report._cls == 'Report.PortReport' && carryForwardReport._cls == 'Report.PortReport') {
                carryForwardReport.cargo.cargo_grade.forEach((cargoGrade, i) => {
                    report.cargo.cargo_grade[i] = carryForwardReport.cargo.cargo_grade[i];
                })
                report.cargo.n_cargoes = carryForwardReport.cargo.n_cargoes;
                if (!report.cargo.hoses) report.cargo.hoses = new Hoses();
            }

            // position data
            if (report.position == undefined) report.position = {};
            if (carryForwardReport.position) {
                report.position.observation_timezone = report.operational.previous_timezone;
                report.position.ship_lat_direction = carryForwardReport.position.ship_lat_direction;
                report.position.ship_lon_direction = carryForwardReport.position.ship_lon_direction;
            }

            if (report.power == undefined) report.power = {};
            
            report.power.main_engine_1_prev_rh = carryForwardReport.power.main_engine_1_cur_rh || carryForwardReport.power.main_engine_1_prev_rh;
            if (report.power.main_engine_1_prev_rh == undefined) report.power.main_engine_1_prev_rh = 0;

            report.power.main_engine_2_prev_rh = carryForwardReport.power.main_engine_2_cur_rh || carryForwardReport.power.main_engine_2_prev_rh;
            if (report.power.main_engine_2_prev_rh == undefined) report.power.main_engine_2_prev_rh = 0;

            report.power.main_engine_1_shaft_power_meter_counter_previous = carryForwardReport.power.main_engine_1_shaft_power_meter_counter || carryForwardReport.power.main_engine_1_shaft_power_meter_counter_previous;
            if (report.power.main_engine_1_shaft_power_meter_counter_previous == undefined) report.power.main_engine_1_shaft_power_meter_counter_previous = 0;

            report.power.aux_engine_1_prev_rh = carryForwardReport.power.aux_engine_1_cur_rh || carryForwardReport.power.aux_engine_1_prev_rh;
            if (report.power.aux_engine_1_prev_rh == undefined) report.power.aux_engine_1_prev_rh = 0;

            report.power.aux_engine_2_prev_rh = carryForwardReport.power.aux_engine_2_cur_rh || carryForwardReport.power.aux_engine_2_prev_rh;
            if (report.power.aux_engine_2_prev_rh == undefined) report.power.aux_engine_2_prev_rh = 0;

            report.power.aux_engine_3_prev_rh = carryForwardReport.power.aux_engine_3_cur_rh || carryForwardReport.power.aux_engine_3_prev_rh;
            if (report.power.aux_engine_3_prev_rh == undefined) report.power.aux_engine_3_prev_rh = 0;

            report.power.aux_engine_4_prev_rh = carryForwardReport.power.aux_engine_4_cur_rh || carryForwardReport.power.aux_engine_4_prev_rh;
            if (report.power.aux_engine_4_prev_rh == undefined) report.power.aux_engine_4_prev_rh = 0;

            report.power.diesel_gen_1_prev_rh = carryForwardReport.power.diesel_gen_1_cur_rh || carryForwardReport.power.diesel_gen_1_prev_rh;
            if (report.power.diesel_gen_1_prev_rh == undefined) report.power.diesel_gen_1_prev_rh = 0;

            report.power.diesel_gen_2_prev_rh = carryForwardReport.power.diesel_gen_2_cur_rh || carryForwardReport.power.diesel_gen_2_prev_rh;
            if (report.power.diesel_gen_2_prev_rh == undefined) report.power.diesel_gen_2_prev_rh = 0;

            report.power.diesel_gen_3_prev_rh = carryForwardReport.power.diesel_gen_3_cur_rh || carryForwardReport.power.diesel_gen_3_prev_rh;
            if (report.power.diesel_gen_3_prev_rh == undefined) report.power.diesel_gen_3_prev_rh = 0;

            report.power.diesel_gen_4_prev_rh = carryForwardReport.power.diesel_gen_4_cur_rh || carryForwardReport.power.diesel_gen_4_prev_rh;
            if (report.power.diesel_gen_4_prev_rh == undefined) report.power.diesel_gen_4_prev_rh = 0;

            report.power.diesel_gen_5_prev_rh = carryForwardReport.power.diesel_gen_5_cur_rh || carryForwardReport.power.diesel_gen_5_prev_rh;
            if (report.power.diesel_gen_5_prev_rh == undefined) report.power.diesel_gen_5_prev_rh = 0;

            report.power.diesel_gen_6_prev_rh = carryForwardReport.power.diesel_gen_6_cur_rh || carryForwardReport.power.diesel_gen_6_prev_rh;
            if (report.power.diesel_gen_6_prev_rh == undefined) report.power.diesel_gen_6_prev_rh = 0;
            
            report.power.mdg_power_meter_1_prev = carryForwardReport.power.mdg_power_meter_1_cur || carryForwardReport.power.mdg_power_meter_1_prev;
            report.power.mdg_power_meter_2_prev = carryForwardReport.power.mdg_power_meter_2_cur || carryForwardReport.power.mdg_power_meter_2_prev;
            report.power.mdg_power_meter_3_prev = carryForwardReport.power.mdg_power_meter_3_cur || carryForwardReport.power.mdg_power_meter_3_prev;
            report.power.mdg_power_meter_4_prev = carryForwardReport.power.mdg_power_meter_4_cur || carryForwardReport.power.mdg_power_meter_4_prev;
            report.power.mdg_power_meter_5_prev = carryForwardReport.power.mdg_power_meter_5_cur || carryForwardReport.power.mdg_power_meter_5_prev;
            report.power.mdg_power_meter_6_prev = carryForwardReport.power.mdg_power_meter_6_cur || carryForwardReport.power.mdg_power_meter_6_prev;

            report.power.shaft_prev_rh = carryForwardReport.power.shaft_cur_rh || carryForwardReport.power.shaft_prev_rh;
            if (report.power.shaft_prev_rh == undefined) report.power.shaft_prev_rh = 0;

            report.power.aux_boiler_prev_rh = carryForwardReport.power.aux_boiler_cur_rh || carryForwardReport.power.aux_boiler_prev_rh;
            if (report.power.aux_boiler_prev_rh == undefined) report.power.aux_boiler_prev_rh = 0;

            report.power.aux_boiler_2_prev_rh = carryForwardReport.power.aux_boiler_2_cur_rh || carryForwardReport.power.aux_boiler_2_prev_rh;
            if (report.power.aux_boiler_2_prev_rh == undefined) report.power.aux_boiler_2_prev_rh = 0;

            report.power.composite_boiler_prev_rh = carryForwardReport.power.composite_boiler_cur_rh || 0;

            report.power.main_engine_comp_1_prev_rh = carryForwardReport.power.main_engine_comp_1_cur_rh || carryForwardReport.power.main_engine_comp_1_prev_rh;
            if (report.power.main_engine_comp_1_prev_rh == undefined) report.power.main_engine_comp_1_prev_rh = 0;

            report.power.main_engine_comp_2_prev_rh = carryForwardReport.power.main_engine_comp_2_cur_rh || carryForwardReport.power.main_engine_comp_2_prev_rh;
            if (report.power.main_engine_comp_2_prev_rh == undefined) report.power.main_engine_comp_2_prev_rh = 0;

            report.power.main_engine_comp_3_prev_rh = carryForwardReport.power.main_engine_comp_3_cur_rh || carryForwardReport.power.main_engine_comp_3_prev_rh;
            if (report.power.main_engine_comp_3_prev_rh == undefined) report.power.main_engine_comp_3_prev_rh = 0;

            report.power.main_engine_comp_4_prev_rh = carryForwardReport.power.main_engine_comp_4_cur_rh || carryForwardReport.power.main_engine_comp_4_prev_rh;
            if (report.power.main_engine_comp_4_prev_rh == undefined) report.power.main_engine_comp_4_prev_rh = 0;

            report.power.main_engine_1_previous_rpm = carryForwardReport.power.main_engine_1_current_rpm || carryForwardReport.power.main_engine_1_previous_rpm;
            if (report.power.main_engine_1_previous_rpm == undefined) report.power.main_engine_1_previous_rpm = 0;

            // flow meters
            report.power.main_engine_flow_prev_rh = carryForwardReport.power.main_engine_flow_cur_rh;
            report.power.main_engine_flow_inlet_prev_rh = carryForwardReport.power.main_engine_flow_inlet_cur_rh;
            report.power.common_flow_hfo_prev_rh = carryForwardReport.power.common_flow_hfo_cur_rh;
            report.power.main_engine_flow_do_prev_rh = carryForwardReport.power.main_engine_flow_do_cur_rh;
            report.power.main_engine_flow_outlet_prev_rh = carryForwardReport.power.main_engine_flow_outlet_cur_rh;
            report.power.common_flow_mgo_prev_rh = carryForwardReport.power.common_flow_mgo_cur_rh;
            report.power.aux_engine_flow_prev_rh = carryForwardReport.power.aux_engine_flow_cur_rh;
            report.power.aux_engine_flow_inlet_prev_rh = carryForwardReport.power.aux_engine_flow_inlet_cur_rh;
            report.power.aux_engine_flow_hfo_prev_rh = carryForwardReport.power.aux_engine_flow_hfo_cur_rh;
            report.power.aux_engine_flow_do_prev_rh = carryForwardReport.power.aux_engine_flow_do_cur_rh;
            report.power.aux_engine_flow_outlet_prev_rh = carryForwardReport.power.aux_engine_flow_outlet_cur_rh;
            report.power.aux_boiler_flow_prev_rh = carryForwardReport.power.aux_boiler_flow_cur_rh;
            report.power.aux_boiler_flow_inlet_prev_rh = carryForwardReport.power.aux_boiler_flow_inlet_cur_rh;
            report.power.aux_boiler_flow_outlet_prev_rh = carryForwardReport.power.aux_boiler_flow_outlet_cur_rh;
            report.power.aux_boiler_2_flow_prev_rh = carryForwardReport.power.aux_boiler_2_flow_cur_rh;
            report.power.cylinder_lube_oil_prev = carryForwardReport.power.cylinder_lube_oil_cur;
            report.power.composite_boiler_flow_prev_rh = carryForwardReport.power.composite_boiler_flow_cur_rh;
            report.power.booster_unit_hfo_prev_rh = carryForwardReport.power.booster_unit_hfo_cur_rh;
            report.power.booster_unit_gas_oil_prev_rh = carryForwardReport.power.booster_unit_gas_oil_cur_rh;
            report.power.main_engine_flow_inlet_hfo_prev_rh = carryForwardReport.power.main_engine_flow_inlet_hfo_cur_rh;
            report.power.main_engine_flow_inlet_mgo_prev_rh = carryForwardReport.power.main_engine_flow_inlet_mgo_cur_rh;
            report.power.aux_engine_flow_inlet_hfo_prev_rh = carryForwardReport.power.aux_engine_flow_inlet_hfo_cur_rh;
            report.power.aux_engine_flow_inlet_mgo_prev_rh = carryForwardReport.power.aux_engine_flow_inlet_mgo_cur_rh;
            report.power.aux_boiler_2_flow_inlet_prev_rh = carryForwardReport.power.aux_boiler_2_flow_inlet_cur_rh;
            report.power.aux_boiler_2_flow_outlet_prev_rh = carryForwardReport.power.aux_boiler_2_flow_outlet_cur_rh;
            report.power.fo_supply_port_flow_prev_rh = carryForwardReport.power.fo_supply_port_flow_cur_rh;
            report.power.fo_supply_starboard_flow_prev_rh = carryForwardReport.power.fo_supply_starboard_flow_cur_rh;
            report.power.hdg_flow_prev_rh = carryForwardReport.power.hdg_flow_cur_rh;
            report.power.composite_boiler_1_flow_prev_rh = carryForwardReport.power.composite_boiler_1_flow_cur_rh;
            report.power.composite_boiler_2_flow_prev_rh = carryForwardReport.power.composite_boiler_2_flow_cur_rh;

            // new flow meter configuration
            Object.keys(carryForwardReport.power).forEach(property => {
                let isFlowMeterProperty = property.indexOf('fm_') > -1 && property.indexOf('_cur') > -1;
                if (isFlowMeterProperty) {
                    let prevFieldName = property.replace('_cur', '_prev');
                    report.power[prevFieldName] = carryForwardReport.power[property]
                }
            })

            // Pumps
            report.power.me_lo_pump_1_prev_rh = enableHybrid ? carryForwardReport.power.me_lo_pump_1_cur_rh || carryForwardReport.power.me_lo_pump_1_prev_rh : carryForwardReport.power.me_lo_pump_1_cur_rh;
            report.power.me_lo_pump_2_prev_rh = enableHybrid ? carryForwardReport.power.me_lo_pump_2_cur_rh || carryForwardReport.power.me_lo_pump_2_prev_rh : carryForwardReport.power.me_lo_pump_2_cur_rh;
            report.power.sea_water_pump_1_prev_rh = enableHybrid ? carryForwardReport.power.sea_water_pump_1_cur_rh || carryForwardReport.power.sea_water_pump_1_prev_rh : carryForwardReport.power.sea_water_pump_1_cur_rh;
            report.power.sea_water_pump_2_prev_rh = enableHybrid ? carryForwardReport.power.sea_water_pump_2_cur_rh || carryForwardReport.power.sea_water_pump_2_prev_rh : carryForwardReport.power.sea_water_pump_2_cur_rh;
            report.power.sg_pump_1_prev_rh = enableHybrid ? carryForwardReport.power.sg_pump_1_cur_rh || carryForwardReport.power.sg_pump_1_prev_rh : carryForwardReport.power.sg_pump_1_cur_rh;
            report.power.sg_pump_2_prev_rh = enableHybrid ? carryForwardReport.power.sg_pump_2_cur_rh || carryForwardReport.power.sg_pump_2_prev_rh : carryForwardReport.power.sg_pump_2_cur_rh;
            report.power.fo_purifier_1_prev_rh = enableHybrid ? carryForwardReport.power.fo_purifier_1_cur_rh || carryForwardReport.power.fo_purifier_1_prev_rh : carryForwardReport.power.fo_purifier_1_cur_rh;
            report.power.fo_purifier_2_prev_rh = enableHybrid ? carryForwardReport.power.fo_purifier_2_cur_rh || carryForwardReport.power.fo_purifier_2_prev_rh : carryForwardReport.power.fo_purifier_2_cur_rh;
            report.power.er_fan_1_prev_rh = enableHybrid ? carryForwardReport.power.er_fan_1_cur_rh || carryForwardReport.power.er_fan_1_prev_rh : carryForwardReport.power.er_fan_1_cur_rh;
            report.power.er_fan_2_prev_rh = enableHybrid ? carryForwardReport.power.er_fan_2_cur_rh || carryForwardReport.power.er_fan_2_prev_rh : carryForwardReport.power.er_fan_2_cur_rh;
            report.power.er_fan_3_prev_rh = enableHybrid ? carryForwardReport.power.er_fan_3_cur_rh || carryForwardReport.power.er_fan_3_prev_rh : carryForwardReport.power.er_fan_3_cur_rh;
            report.power.er_fan_4_prev_rh = enableHybrid ? carryForwardReport.power.er_fan_4_cur_rh || carryForwardReport.power.er_fan_4_prev_rh : carryForwardReport.power.er_fan_4_cur_rh;

            report.power.cargo_compressor_1_prev_rh = enableHybrid ? carryForwardReport.power.cargo_compressor_1_cur_rh || carryForwardReport.power.cargo_compressor_1_prev_rh : carryForwardReport.power.cargo_compressor_1_cur_rh;
            report.power.cargo_compressor_2_prev_rh = enableHybrid ? carryForwardReport.power.cargo_compressor_2_cur_rh || carryForwardReport.power.cargo_compressor_2_prev_rh : carryForwardReport.power.cargo_compressor_2_cur_rh;
            report.power.cargo_compressor_3_prev_rh = enableHybrid ? carryForwardReport.power.cargo_compressor_3_cur_rh || carryForwardReport.power.cargo_compressor_3_prev_rh : carryForwardReport.power.cargo_compressor_3_cur_rh;
            report.power.cargo_compressor_4_prev_rh = enableHybrid ? carryForwardReport.power.cargo_compressor_4_cur_rh || carryForwardReport.power.cargo_compressor_4_prev_rh : carryForwardReport.power.cargo_compressor_4_cur_rh;
            report.power.cargo_pump_1_prev_rh = carryForwardReport.power.cargo_pump_1_cur_rh || carryForwardReport.power.cargo_pump_1_prev_rh;
            report.power.cargo_pump_2_prev_rh = carryForwardReport.power.cargo_pump_2_cur_rh || carryForwardReport.power.cargo_pump_2_prev_rh;
            report.power.cargo_pump_3_prev_rh = carryForwardReport.power.cargo_pump_3_cur_rh || carryForwardReport.power.cargo_pump_3_prev_rh;
            report.power.cargo_pump_4_prev_rh = carryForwardReport.power.cargo_pump_4_cur_rh || carryForwardReport.power.cargo_pump_4_prev_rh;
            report.power.cargo_pump_5_prev_rh = carryForwardReport.power.cargo_pump_5_cur_rh || carryForwardReport.power.cargo_pump_5_prev_rh;
            report.power.cargo_pump_6_prev_rh = carryForwardReport.power.cargo_pump_6_cur_rh || carryForwardReport.power.cargo_pump_6_prev_rh;
            report.power.cargo_pump_7_prev_rh = carryForwardReport.power.cargo_pump_7_cur_rh || carryForwardReport.power.cargo_pump_7_prev_rh;
            report.power.cargo_pump_8_prev_rh = carryForwardReport.power.cargo_pump_8_cur_rh || carryForwardReport.power.cargo_pump_8_prev_rh;
            report.power.ballast_pump_1_prev_rh = carryForwardReport.power.ballast_pump_1_cur_rh || carryForwardReport.power.ballast_pump_1_prev_rh;
            report.power.ballast_pump_2_prev_rh = carryForwardReport.power.ballast_pump_2_cur_rh || carryForwardReport.power.ballast_pump_2_prev_rh;
            if (report._cls != 'Report.PortReport') {
                report.power.cargo_pump_1_cur_rh = carryForwardReport.power.cargo_pump_1_cur_rh || carryForwardReport.power.cargo_pump_1_prev_rh;
                report.power.cargo_pump_2_cur_rh = carryForwardReport.power.cargo_pump_2_cur_rh || carryForwardReport.power.cargo_pump_2_prev_rh;
                report.power.cargo_pump_3_cur_rh = carryForwardReport.power.cargo_pump_3_cur_rh || carryForwardReport.power.cargo_pump_3_prev_rh;
                report.power.cargo_pump_4_cur_rh = carryForwardReport.power.cargo_pump_4_cur_rh || carryForwardReport.power.cargo_pump_4_prev_rh;
                report.power.cargo_pump_5_cur_rh = carryForwardReport.power.cargo_pump_5_cur_rh || carryForwardReport.power.cargo_pump_5_prev_rh;
                report.power.cargo_pump_6_cur_rh = carryForwardReport.power.cargo_pump_6_cur_rh || carryForwardReport.power.cargo_pump_6_prev_rh;
                report.power.cargo_pump_7_cur_rh = carryForwardReport.power.cargo_pump_7_cur_rh || carryForwardReport.power.cargo_pump_7_prev_rh;
                report.power.cargo_pump_8_cur_rh = carryForwardReport.power.cargo_pump_8_cur_rh || carryForwardReport.power.cargo_pump_8_prev_rh;
                report.power.ballast_pump_1_cur_rh = carryForwardReport.power.ballast_pump_1_cur_rh || carryForwardReport.power.ballast_pump_1_prev_rh;
                report.power.ballast_pump_2_cur_rh = carryForwardReport.power.ballast_pump_2_cur_rh || carryForwardReport.power.ballast_pump_2_prev_rh;
            }
            report.power.bow_thruster_prev_rh = enableHybrid ? carryForwardReport.power.bow_thruster_cur_rh || carryForwardReport.power.bow_thruster_prev_rh : carryForwardReport.power.bow_thruster_cur_rh;
            report.power.stern_thruster_prev_rh = enableHybrid ? carryForwardReport.power.stern_thruster_cur_rh || carryForwardReport.power.stern_thruster_prev_rh : carryForwardReport.power.stern_thruster_cur_rh;

            report.power.er_ums_prev_rh = enableHybrid ? carryForwardReport.power.er_ums_cur_rh || carryForwardReport.power.er_ums_prev_rh : carryForwardReport.power.er_ums_cur_rh;
            report.power.diesel_power_pack_1_prev_rh = enableHybrid ? carryForwardReport.power.diesel_power_pack_1_cur_rh || carryForwardReport.power.diesel_power_pack_1_prev_rh : carryForwardReport.power.diesel_power_pack_1_cur_rh;
            report.power.diesel_power_pack_2_prev_rh = enableHybrid ? carryForwardReport.power.diesel_power_pack_2_cur_rh || carryForwardReport.power.diesel_power_pack_2_prev_rh : carryForwardReport.power.diesel_power_pack_2_cur_rh;
            report.power.diesel_power_pack_3_prev_rh = enableHybrid ? carryForwardReport.power.diesel_power_pack_3_cur_rh || carryForwardReport.power.diesel_power_pack_3_prev_rh : carryForwardReport.power.diesel_power_pack_3_cur_rh;
            report.power.electrical_power_pack_1_prev_rh = enableHybrid ? carryForwardReport.power.electrical_power_pack_1_cur_rh || carryForwardReport.power.electrical_power_pack_1_prev_rh : carryForwardReport.power.electrical_power_pack_1_cur_rh;
            report.power.electrical_power_pack_2_prev_rh = enableHybrid ? carryForwardReport.power.electrical_power_pack_2_cur_rh || carryForwardReport.power.electrical_power_pack_2_prev_rh : carryForwardReport.power.electrical_power_pack_2_cur_rh;
            report.power.ballast_pump_steam_prev_rh = enableHybrid ? carryForwardReport.power.ballast_pump_steam_cur_rh || carryForwardReport.power.ballast_pump_steam_prev_rh : carryForwardReport.power.ballast_pump_steam_cur_rh;
            report.power.ballast_pump_1_electrical_prev_rh = enableHybrid ? carryForwardReport.power.ballast_pump_1_electrical_cur_rh || carryForwardReport.power.ballast_pump_1_electrical_prev_rh : carryForwardReport.power.ballast_pump_1_electrical_cur_rh;
            report.power.ballast_pump_2_electrical_prev_rh = enableHybrid ? carryForwardReport.power.ballast_pump_2_electrical_cur_rh || carryForwardReport.power.ballast_pump_2_electrical_prev_rh : carryForwardReport.power.ballast_pump_2_electrical_cur_rh;
            report.power.ballast_pump_1_hydraulic_prev_rh = enableHybrid ? carryForwardReport.power.ballast_pump_1_hydraulic_cur_rh || carryForwardReport.power.ballast_pump_1_hydraulic_prev_rh : carryForwardReport.power.ballast_pump_1_hydraulic_cur_rh;
            report.power.ballast_pump_2_hydraulic_prev_rh = enableHybrid ? carryForwardReport.power.ballast_pump_2_hydraulic_cur_rh || carryForwardReport.power.ballast_pump_2_hydraulic_prev_rh : carryForwardReport.power.ballast_pump_2_hydraulic_cur_rh;
            report.power.incinerator_malfunctional = carryForwardReport.power.incinerator_malfunctional;
            report.power.odme_malfunctional = carryForwardReport.power.odme_malfunctional;
            report.power.bwts_malfunctional = carryForwardReport.power.bwts_malfunctional;
            report.power.scrubber_malfunctional = carryForwardReport.power.scrubber_malfunctional;
            report.power.ows_malfunctional = carryForwardReport.power.ows_malfunctional;

            report.power.cargo_compressor_1_prev_reading = carryForwardReport.power.cargo_compressor_1_cur_reading;
            report.power.cargo_compressor_2_prev_reading = carryForwardReport.power.cargo_compressor_2_cur_reading;
            report.power.cargo_compressor_3_prev_reading = carryForwardReport.power.cargo_compressor_3_cur_reading;
            report.power.cargo_compressor_4_prev_reading = carryForwardReport.power.cargo_compressor_4_cur_reading;
            report.power.other_cargo_cooling_prev_reading = carryForwardReport.power.other_cargo_cooling_cur_reading;

            // carry forward fuel specifications for consumptions
            var old_to_new_fuel_types = {
                'hshfo':'hfo',
                'lshfo':'ulsfo2020',
                'ulsfo':'ulslfo2020',
                'lsmdo':'ulsmdo2020',
                'hsmgo':'ulsmgo2020',
                'lsmgo':'ulsmgo2020'
            }
            var default_consumption_fuel_types = []
            Object.keys(old_to_new_fuel_types).map(function(old_fuel_grade,index) {
                if (carryForwardReport.consumption['total_consumption_'+old_fuel_grade] > 0 && default_consumption_fuel_types.includes(old_to_new_fuel_types[old_fuel_grade]) != true) {
                    default_consumption_fuel_types.push(old_to_new_fuel_types[old_fuel_grade])
                }
            })
            report.consumption.fuel_type_1 = carryForwardReport.consumption.fuel_type_1 ? carryForwardReport.consumption.fuel_type_1 : default_consumption_fuel_types[0] ? default_consumption_fuel_types[0] : undefined;
            report.consumption.fuel_type_2 = carryForwardReport.consumption.fuel_type_2 ? carryForwardReport.consumption.fuel_type_2 : default_consumption_fuel_types[1] ? default_consumption_fuel_types[1] : undefined;
            report.consumption.fuel_type_3 = carryForwardReport.consumption.fuel_type_3 ? carryForwardReport.consumption.fuel_type_3 : default_consumption_fuel_types[2] ? default_consumption_fuel_types[2] : undefined;

            report.consumption.lcv_hshfo = carryForwardReport.consumption.lcv_hshfo;
            report.consumption.lcv_lshfo = carryForwardReport.consumption.lcv_lshfo;
            report.consumption.lcv_ulsfo = carryForwardReport.consumption.lcv_ulsfo;
            report.consumption.lcv_hsmdo = carryForwardReport.consumption.lcv_hsmdo;
            report.consumption.lcv_lsmdo = carryForwardReport.consumption.lcv_lsmdo;
            report.consumption.lcv_hsmgo = carryForwardReport.consumption.lcv_hsmgo;
            report.consumption.lcv_lsmgo = carryForwardReport.consumption.lcv_lsmgo;

            report.consumption.lcv_hfo = carryForwardReport.consumption.lcv_hfo ? carryForwardReport.consumption.lcv_hfo: carryForwardReport.consumption.lcv_hshfo;
            report.consumption.lcv_lfo = carryForwardReport.consumption.lcv_lfo;
            report.consumption.lcv_mgo = carryForwardReport.consumption.lcv_mgo;
            report.consumption.lcv_mdo = carryForwardReport.consumption.lcv_mdo;
            report.consumption.lcv_b10lfo = carryForwardReport.consumption.lcv_b10lfo;
            report.consumption.lcv_b10mgo = carryForwardReport.consumption.lcv_b10mgo;
            report.consumption.lcv_biolfo = carryForwardReport.consumption.lcv_biolfo;
            report.consumption.lcv_biomgo = carryForwardReport.consumption.lcv_biomgo;
            report.consumption.lcv_ulsfo2020 = carryForwardReport.consumption.lcv_ulsfo2020 ? carryForwardReport.consumption.lcv_ulsfo2020: carryForwardReport.consumption.lcv_lshfo;
            report.consumption.lcv_ulslfo2020 = carryForwardReport.consumption.lcv_ulslfo2020 ? carryForwardReport.consumption.lcv_ulslfo2020: carryForwardReport.consumption.lcv_ulsfo;
            report.consumption.lcv_ulsmdo2020 = carryForwardReport.consumption.lcv_ulsmdo2020 ? carryForwardReport.consumption.lcv_ulsmdo2020: carryForwardReport.consumption.lcv_lsmdo;
            report.consumption.lcv_ulsmgo2020 = carryForwardReport.consumption.lcv_ulsmgo2020 ? carryForwardReport.consumption.lcv_ulsmgo2020: carryForwardReport.consumption.lcv_hsmgo ? carryForwardReport.consumption.lcv_hsmgo : carryForwardReport.consumption.lcv_lsmgo;
            report.consumption.lcv_vlsfo2020 = carryForwardReport.consumption.lcv_vlsfo2020;
            report.consumption.lcv_vlslfo2020 = carryForwardReport.consumption.lcv_vlslfo2020;
            report.consumption.lcv_lpgp = carryForwardReport.consumption.lcv_lpgp;
            report.consumption.lcv_lpgb = carryForwardReport.consumption.lcv_lpgb;
            report.consumption.lcv_lng = carryForwardReport.consumption.lcv_lng;
            report.consumption.lcv_methanol = carryForwardReport.consumption.lcv_methanol;
            report.consumption.lcv_ethanol = carryForwardReport.consumption.lcv_ethanol;
            report.consumption.lcv_other = carryForwardReport.consumption.lcv_other;

            report.consumption.sulphur_hshfo = carryForwardReport.consumption.sulphur_hshfo;
            report.consumption.sulphur_lshfo = carryForwardReport.consumption.sulphur_lshfo;
            report.consumption.sulphur_ulsfo = carryForwardReport.consumption.sulphur_ulsfo;
            report.consumption.sulphur_hsmdo = carryForwardReport.consumption.sulphur_hsmdo;
            report.consumption.sulphur_lsmdo = carryForwardReport.consumption.sulphur_lsmdo;
            report.consumption.sulphur_hsmgo = carryForwardReport.consumption.sulphur_hsmgo;
            report.consumption.sulphur_lsmgo = carryForwardReport.consumption.sulphur_lsmgo;

            report.consumption.sulphur_hfo = carryForwardReport.consumption.sulphur_hfo ? carryForwardReport.consumption.sulphur_hfo: carryForwardReport.consumption.sulphur_hshfo;
            report.consumption.sulphur_lfo = carryForwardReport.consumption.sulphur_lfo;
            report.consumption.sulphur_mgo = carryForwardReport.consumption.sulphur_mgo;
            report.consumption.sulphur_mdo = carryForwardReport.consumption.sulphur_mdo;
            report.consumption.sulphur_b10lfo = carryForwardReport.consumption.sulphur_b10lfo;
            report.consumption.sulphur_b10mgo = carryForwardReport.consumption.sulphur_b10mgo;
            report.consumption.sulphur_biolfo = carryForwardReport.consumption.sulphur_biolfo;
            report.consumption.sulphur_biomgo = carryForwardReport.consumption.sulphur_biomgo;
            report.consumption.sulphur_ulsfo2020 = carryForwardReport.consumption.sulphur_ulsfo2020 ? carryForwardReport.consumption.sulphur_ulsfo2020: carryForwardReport.consumption.sulphur_lshfo;
            report.consumption.sulphur_ulslfo2020 = carryForwardReport.consumption.sulphur_ulslfo2020 ? carryForwardReport.consumption.sulphur_ulslfo2020: carryForwardReport.consumption.sulphur_ulsfo;
            report.consumption.sulphur_ulsmdo2020 = carryForwardReport.consumption.sulphur_ulsmdo2020 ? carryForwardReport.consumption.sulphur_ulsmdo2020: carryForwardReport.consumption.sulphur_lsmdo;
            report.consumption.sulphur_ulsmgo2020 = carryForwardReport.consumption.sulphur_ulsmgo2020 ? carryForwardReport.consumption.sulphur_ulsmgo2020: carryForwardReport.consumption.sulphur_hsmgo ? carryForwardReport.consumption.sulphur_hsmgo : carryForwardReport.consumption.sulphur_lsmgo;
            report.consumption.sulphur_vlsfo2020 = carryForwardReport.consumption.sulphur_vlsfo2020;
            report.consumption.sulphur_vlslfo2020 = carryForwardReport.consumption.sulphur_vlslfo2020;
            report.consumption.sulphur_lpgp = carryForwardReport.consumption.sulphur_lpgp;
            report.consumption.sulphur_lpgb = carryForwardReport.consumption.sulphur_lpgb;
            report.consumption.sulphur_lng = carryForwardReport.consumption.sulphur_lng;
            report.consumption.sulphur_methanol = carryForwardReport.consumption.sulphur_methanol;
            report.consumption.sulphur_ethanol = carryForwardReport.consumption.sulphur_ethanol;
            report.consumption.sulphur_other = carryForwardReport.consumption.sulphur_other;

            report.consumption.density_hshfo = carryForwardReport.consumption.density_hshfo;
            report.consumption.density_lshfo = carryForwardReport.consumption.density_lshfo;
            report.consumption.density_ulsfo = carryForwardReport.consumption.density_ulsfo;
            report.consumption.density_hsmdo = carryForwardReport.consumption.density_hsmdo;
            report.consumption.density_lsmdo = carryForwardReport.consumption.density_lsmdo;
            report.consumption.density_hsmgo = carryForwardReport.consumption.density_hsmgo;
            report.consumption.density_lsmgo = carryForwardReport.consumption.density_lsmgo;

            report.consumption.density_hfo = carryForwardReport.consumption.density_hfo ? carryForwardReport.consumption.density_hfo: carryForwardReport.consumption.density_hshfo;
            report.consumption.density_lfo = carryForwardReport.consumption.density_lfo;
            report.consumption.density_mgo = carryForwardReport.consumption.density_mgo;
            report.consumption.density_mdo = carryForwardReport.consumption.density_mdo;
            report.consumption.density_b10lfo = carryForwardReport.consumption.density_b10lfo;
            report.consumption.density_b10mgo = carryForwardReport.consumption.density_b10mgo;
            report.consumption.density_biolfo = carryForwardReport.consumption.density_biolfo;
            report.consumption.density_biomgo = carryForwardReport.consumption.density_biomgo;
            report.consumption.density_ulsfo2020 = carryForwardReport.consumption.density_ulsfo2020 ? carryForwardReport.consumption.density_ulsfo2020: carryForwardReport.consumption.density_lshfo;
            report.consumption.density_ulslfo2020 = carryForwardReport.consumption.density_ulslfo2020 ? carryForwardReport.consumption.density_ulslfo2020: carryForwardReport.consumption.density_ulsfo;
            report.consumption.density_ulsmdo2020 = carryForwardReport.consumption.density_ulsmdo2020 ? carryForwardReport.consumption.density_ulsmdo2020: carryForwardReport.consumption.density_lsmdo;
            report.consumption.density_ulsmgo2020 = carryForwardReport.consumption.density_ulsmgo2020 ? carryForwardReport.consumption.density_ulsmgo2020: carryForwardReport.consumption.density_hsmgo ? carryForwardReport.consumption.density_hsmgo : carryForwardReport.consumption.density_lsmgo;
            report.consumption.density_vlsfo2020 = carryForwardReport.consumption.density_vlsfo2020;
            report.consumption.density_vlslfo2020 = carryForwardReport.consumption.density_vlslfo2020;
            report.consumption.density_lpgp = carryForwardReport.consumption.density_lpgp;
            report.consumption.density_lpgb = carryForwardReport.consumption.density_lpgb;
            report.consumption.density_lng = carryForwardReport.consumption.density_lng;
            report.consumption.density_methanol = carryForwardReport.consumption.density_methanol;
            report.consumption.density_ethanol = carryForwardReport.consumption.density_ethanol;
            report.consumption.density_other = carryForwardReport.consumption.density_other;

            report.consumption.serv_hshfo = carryForwardReport.consumption.serv_hshfo;
            report.consumption.serv_lshfo = carryForwardReport.consumption.serv_lshfo;
            report.consumption.serv_ulsfo = carryForwardReport.consumption.serv_ulsfo;
            report.consumption.serv_hsmdo = carryForwardReport.consumption.serv_hsmdo;
            report.consumption.serv_lsmdo = carryForwardReport.consumption.serv_lsmdo;
            report.consumption.serv_hsmgo = carryForwardReport.consumption.serv_hsmgo;
            report.consumption.serv_lsmgo = carryForwardReport.consumption.serv_lsmgo;

            report.consumption.serv_hfo = carryForwardReport.consumption.serv_hfo ? carryForwardReport.consumption.serv_hfo: carryForwardReport.consumption.serv_hshfo;
            report.consumption.serv_lfo = carryForwardReport.consumption.serv_lfo;
            report.consumption.serv_mgo = carryForwardReport.consumption.serv_mgo;
            report.consumption.serv_mdo = carryForwardReport.consumption.serv_mdo;
            report.consumption.serv_b10lfo = carryForwardReport.consumption.serv_b10lfo;
            report.consumption.serv_b10mgo = carryForwardReport.consumption.serv_b10mgo;
            report.consumption.serv_biolfo = carryForwardReport.consumption.serv_biolfo;
            report.consumption.serv_biomgo = carryForwardReport.consumption.serv_biomgo;
            report.consumption.serv_ulsfo2020 = carryForwardReport.consumption.serv_ulsfo2020 ? carryForwardReport.consumption.serv_ulsfo2020: carryForwardReport.consumption.serv_lshfo;
            report.consumption.serv_ulslfo2020 = carryForwardReport.consumption.serv_ulslfo2020 ? carryForwardReport.consumption.serv_ulslfo2020: carryForwardReport.consumption.serv_ulsfo;
            report.consumption.serv_ulsmdo2020 = carryForwardReport.consumption.serv_ulsmdo2020 ? carryForwardReport.consumption.serv_ulsmdo2020: carryForwardReport.consumption.serv_lsmdo;
            report.consumption.serv_ulsmgo2020 = carryForwardReport.consumption.serv_ulsmgo2020 ? carryForwardReport.consumption.serv_ulsmgo2020: carryForwardReport.consumption.serv_hsmgo ? carryForwardReport.consumption.serv_hsmgo : carryForwardReport.consumption.serv_lsmgo;
            report.consumption.serv_vlsfo2020 = carryForwardReport.consumption.serv_vlsfo2020;
            report.consumption.serv_vlslfo2020 = carryForwardReport.consumption.serv_vlslfo2020;
            report.consumption.serv_lpgp = carryForwardReport.consumption.serv_lpgp;
            report.consumption.serv_lpgb = carryForwardReport.consumption.serv_lpgb;
            report.consumption.serv_lng = carryForwardReport.consumption.serv_lng;
            report.consumption.serv_methanol = carryForwardReport.consumption.serv_methanol;
            report.consumption.serv_ethanol = carryForwardReport.consumption.serv_ethanol;
            report.consumption.serv_other = carryForwardReport.consumption.serv_other;

            // keep track of previous values so that new specs can be recalculated when vessel takes bunkers
            report.consumption.previous_lcv_hshfo = carryForwardReport.consumption.lcv_hshfo;
            report.consumption.previous_lcv_lshfo = carryForwardReport.consumption.lcv_lshfo;
            report.consumption.previous_lcv_ulsfo = carryForwardReport.consumption.lcv_ulsfo;
            report.consumption.previous_lcv_hsmdo = carryForwardReport.consumption.lcv_hsmdo;
            report.consumption.previous_lcv_lsmdo = carryForwardReport.consumption.lcv_lsmdo;
            report.consumption.previous_lcv_hsmgo = carryForwardReport.consumption.lcv_hsmgo;
            report.consumption.previous_lcv_lsmgo = carryForwardReport.consumption.lcv_lsmgo;

            report.consumption.previous_lcv_hfo = carryForwardReport.consumption.previous_lcv_hfo ? carryForwardReport.consumption.previous_lcv_hfo: carryForwardReport.consumption.previous_lcv_hshfo;
            report.consumption.previous_lcv_lfo = carryForwardReport.consumption.previous_lcv_lfo;
            report.consumption.previous_lcv_mgo = carryForwardReport.consumption.previous_lcv_mgo;
            report.consumption.previous_lcv_mdo = carryForwardReport.consumption.previous_lcv_mdo;
            report.consumption.previous_lcv_b10lfo = carryForwardReport.consumption.previous_lcv_b10lfo;
            report.consumption.previous_lcv_b10mgo = carryForwardReport.consumption.previous_lcv_b10mgo;
            report.consumption.previous_lcv_biolfo = carryForwardReport.consumption.previous_lcv_biolfo;
            report.consumption.previous_lcv_biomgo = carryForwardReport.consumption.previous_lcv_biomgo;
            report.consumption.previous_lcv_ulsfo2020 = carryForwardReport.consumption.previous_lcv_ulsfo2020 ? carryForwardReport.consumption.previous_lcv_ulsfo2020: carryForwardReport.consumption.previous_lcv_lshfo;
            report.consumption.previous_lcv_ulslfo2020 = carryForwardReport.consumption.previous_lcv_ulslfo2020 ? carryForwardReport.consumption.previous_lcv_ulslfo2020: carryForwardReport.consumption.previous_lcv_ulsfo;
            report.consumption.previous_lcv_ulsmdo2020 = carryForwardReport.consumption.previous_lcv_ulsmdo2020 ? carryForwardReport.consumption.previous_lcv_ulsmdo2020: carryForwardReport.consumption.previous_lcv_lsmdo;
            report.consumption.previous_lcv_ulsmgo2020 = carryForwardReport.consumption.previous_lcv_ulsmgo2020 ? carryForwardReport.consumption.previous_lcv_ulsmgo2020: carryForwardReport.consumption.previous_lcv_hsmgo ? carryForwardReport.consumption.previous_lcv_hsmgo : carryForwardReport.consumption.previous_lcv_lsmgo;
            report.consumption.previous_lcv_vlsfo2020 = carryForwardReport.consumption.previous_lcv_vlsfo2020;
            report.consumption.previous_lcv_vlslfo2020 = carryForwardReport.consumption.previous_lcv_vlslfo2020;
            report.consumption.previous_lcv_lpgp = carryForwardReport.consumption.previous_lcv_lpgp;
            report.consumption.previous_lcv_lpgb = carryForwardReport.consumption.previous_lcv_lpgb;
            report.consumption.previous_lcv_lng = carryForwardReport.consumption.previous_lcv_lng;
            report.consumption.previous_lcv_methanol = carryForwardReport.consumption.previous_lcv_methanol;
            report.consumption.previous_lcv_ethanol = carryForwardReport.consumption.previous_lcv_ethanol;
            report.consumption.previous_lcv_other = carryForwardReport.consumption.previous_lcv_other;

            report.consumption.previous_sulphur_hshfo = carryForwardReport.consumption.sulphur_hshfo;
            report.consumption.previous_sulphur_lshfo = carryForwardReport.consumption.sulphur_lshfo;
            report.consumption.previous_sulphur_ulsfo = carryForwardReport.consumption.sulphur_ulsfo;
            report.consumption.previous_sulphur_hsmdo = carryForwardReport.consumption.sulphur_hsmdo;
            report.consumption.previous_sulphur_lsmdo = carryForwardReport.consumption.sulphur_lsmdo;
            report.consumption.previous_sulphur_hsmgo = carryForwardReport.consumption.sulphur_hsmgo;
            report.consumption.previous_sulphur_lsmgo = carryForwardReport.consumption.sulphur_lsmgo;

            report.consumption.previous_sulphur_hfo = carryForwardReport.consumption.previous_sulphur_hfo ? carryForwardReport.consumption.previous_sulphur_hfo: carryForwardReport.consumption.previous_sulphur_hshfo;
            report.consumption.previous_sulphur_lfo = carryForwardReport.consumption.previous_sulphur_lfo;
            report.consumption.previous_sulphur_mgo = carryForwardReport.consumption.previous_sulphur_mgo;
            report.consumption.previous_sulphur_mdo = carryForwardReport.consumption.previous_sulphur_mdo;
            report.consumption.previous_sulphur_b10lfo = carryForwardReport.consumption.previous_sulphur_b10lfo;
            report.consumption.previous_sulphur_b10mgo = carryForwardReport.consumption.previous_sulphur_b10mgo;
            report.consumption.previous_sulphur_biolfo = carryForwardReport.consumption.previous_sulphur_biolfo;
            report.consumption.previous_sulphur_biomgo = carryForwardReport.consumption.previous_sulphur_biomgo;
            report.consumption.previous_sulphur_ulsfo2020 = carryForwardReport.consumption.previous_sulphur_ulsfo2020 ? carryForwardReport.consumption.previous_sulphur_ulsfo2020: carryForwardReport.consumption.previous_sulphur_lshfo;
            report.consumption.previous_sulphur_ulslfo2020 = carryForwardReport.consumption.previous_sulphur_ulslfo2020 ? carryForwardReport.consumption.previous_sulphur_ulslfo2020: carryForwardReport.consumption.previous_sulphur_ulsfo;
            report.consumption.previous_sulphur_ulsmdo2020 = carryForwardReport.consumption.previous_sulphur_ulsmdo2020 ? carryForwardReport.consumption.previous_sulphur_ulsmdo2020: carryForwardReport.consumption.previous_sulphur_lsmdo;
            report.consumption.previous_sulphur_ulsmgo2020 = carryForwardReport.consumption.previous_sulphur_ulsmgo2020 ? carryForwardReport.consumption.previous_sulphur_ulsmgo2020: carryForwardReport.consumption.previous_sulphur_hsmgo ? carryForwardReport.consumption.previous_sulphur_hsmgo : carryForwardReport.consumption.previous_sulphur_lsmgo;
            report.consumption.previous_sulphur_vlsfo2020 = carryForwardReport.consumption.previous_sulphur_vlsfo2020;
            report.consumption.previous_sulphur_vlslfo2020 = carryForwardReport.consumption.previous_sulphur_vlslfo2020;
            report.consumption.previous_sulphur_lpgp = carryForwardReport.consumption.previous_sulphur_lpgp;
            report.consumption.previous_sulphur_lpgb = carryForwardReport.consumption.previous_sulphur_lpgb;
            report.consumption.previous_sulphur_lng = carryForwardReport.consumption.previous_sulphur_lng;
            report.consumption.previous_sulphur_methanol = carryForwardReport.consumption.previous_sulphur_methanol;
            report.consumption.previous_sulphur_ethanol = carryForwardReport.consumption.previous_sulphur_ethanol;
            report.consumption.previous_sulphur_other = carryForwardReport.consumption.previous_sulphur_other;

            report.consumption.previous_density_hshfo = carryForwardReport.consumption.density_hshfo;
            report.consumption.previous_density_lshfo = carryForwardReport.consumption.density_lshfo;
            report.consumption.previous_density_ulsfo = carryForwardReport.consumption.density_ulsfo;
            report.consumption.previous_density_hsmdo = carryForwardReport.consumption.density_hsmdo;
            report.consumption.previous_density_lsmdo = carryForwardReport.consumption.density_lsmdo;
            report.consumption.previous_density_hsmgo = carryForwardReport.consumption.density_hsmgo;
            report.consumption.previous_density_lsmgo = carryForwardReport.consumption.density_lsmgo;

            report.consumption.previous_density_hfo = carryForwardReport.consumption.density_hfo ? carryForwardReport.consumption.density_hfo: carryForwardReport.consumption.density_hshfo;
            report.consumption.previous_density_lfo = carryForwardReport.consumption.density_lfo;
            report.consumption.previous_density_mgo = carryForwardReport.consumption.density_mgo;
            report.consumption.previous_density_mdo = carryForwardReport.consumption.density_mdo;
            report.consumption.previous_density_b10lfo = carryForwardReport.consumption.density_b10lfo;
            report.consumption.previous_density_b10mgo = carryForwardReport.consumption.density_b10mgo;
            report.consumption.previous_density_biolfo = carryForwardReport.consumption.density_biolfo;
            report.consumption.previous_density_biomgo = carryForwardReport.consumption.density_biomgo;
            report.consumption.previous_density_ulsfo2020 = carryForwardReport.consumption.density_ulsfo2020 ? carryForwardReport.consumption.density_ulsfo2020: carryForwardReport.consumption.density_lshfo;
            report.consumption.previous_density_ulslfo2020 = carryForwardReport.consumption.density_ulslfo2020 ? carryForwardReport.consumption.density_ulslfo2020: carryForwardReport.consumption.density_ulsfo;
            report.consumption.previous_density_ulsmdo2020 = carryForwardReport.consumption.density_ulsmdo2020 ? carryForwardReport.consumption.density_ulsmdo2020: carryForwardReport.consumption.density_lsmdo;
            report.consumption.previous_density_ulsmgo2020 = carryForwardReport.consumption.density_ulsmgo2020 ? carryForwardReport.consumption.density_ulsmgo2020: carryForwardReport.consumption.density_hsmgo ? carryForwardReport.consumption.density_hsmgo : carryForwardReport.consumption.density_lsmgo;
            report.consumption.previous_density_vlsfo2020 = carryForwardReport.consumption.density_vlsfo2020;
            report.consumption.previous_density_vlslfo2020 = carryForwardReport.consumption.density_vlslfo2020;
            report.consumption.previous_density_lpgp = carryForwardReport.consumption.density_lpgp;
            report.consumption.previous_density_lpgb = carryForwardReport.consumption.density_lpgb;
            report.consumption.previous_density_lng = carryForwardReport.consumption.density_lng;
            report.consumption.previous_density_methanol = carryForwardReport.consumption.density_methanol;
            report.consumption.previous_density_ethanol = carryForwardReport.consumption.density_ethanol;
            report.consumption.previous_density_other = carryForwardReport.consumption.density_other;

            report.consumption.cyl_oil_code = carryForwardReport.consumption.cyl_oil_code;
            report.consumption.cyl_oil_bn = carryForwardReport.consumption.cyl_oil_bn;
            report.consumption.cyl_oil_density_at_15 = carryForwardReport.consumption.cyl_oil_density_at_15;
            report.consumption.cyl_oil_tank_temperature = carryForwardReport.consumption.cyl_oil_tank_temperature;

            // always carry forward port name for DNV purposes, regardless of report type
            report.operational.dnv_port_name = carryForwardReport.operational.dnv_port_name;

            if (report._cls != 'Report.SeaReport' && carryForwardReport.operational.port_name != undefined && carryForwardReport.operational.port_name != '') {
                report.operational.port_name = carryForwardReport.operational.port_name;
                report.operational.port_latitude_hours = carryForwardReport.operational.port_latitude_hours;
                report.operational.port_latitude_minutes = carryForwardReport.operational.port_latitude_minutes;
                report.operational.port_latitude_seconds = carryForwardReport.operational.port_latitude_seconds;
                report.operational.port_latitude_direction = carryForwardReport.operational.port_latitude_direction;
                report.operational.port_longitude_hours = carryForwardReport.operational.port_longitude_hours;
                report.operational.port_longitude_minutes = carryForwardReport.operational.port_longitude_minutes;
                report.operational.port_longitude_seconds = carryForwardReport.operational.port_longitude_seconds;
                report.operational.port_longitude_direction = carryForwardReport.operational.port_longitude_direction;
            }

            if (report.stock == undefined) report.stock = {};
            report.stock.tank_based_reporting = carryForwardReport.stock.tank_based_reporting;
            report.stock.tanks = report.stock.tanks || {};
            report.stock.total_stock_fuel = carryForwardReport.stock.total_stock_fuel;

            if (carryForwardReport.stock.aux_engine_lube_oil && carryForwardIfEnabled(carryForwardReport, report, 'stock.aux_engine_lube_oil')) report.stock.aux_engine_lube_oil = carryForwardReport.stock.aux_engine_lube_oil;
            
            var lube_oil_rob_fields = ['lube_oil_system','lube_oil_spare','cyl_oil_low_bn','cyl_oil','aux_engine_lube_oil','turbo_oil','hydraulic_oil','ho_mooring','ho_cranes','ho_grabs','ho_hatch','other_oil']
            lube_oil_rob_fields.forEach(key => {if (carryForwardReport.stock[key]==0) report.stock[key]=0; })

            var default_stock_fuel_types = []
            if (carryForwardReport.stock != undefined) {
                if (carryForwardReport._cls === 'Report.ManeuveringReport') {
                    // checks if any _calculated value from server exist then use carryover report, else assume server is delayed or error or we're using local report
                    // so FE will do the calculations to avoid validation errors after double man report
                    // https://tressolutions.zendesk.com/agent/tickets/411869
                    var hasStockCalculatedFuel = 
                    carryForwardReport.stock.hshfo_calculated != undefined ||
                    carryForwardReport.stock.lshfo_calculated != undefined ||
                    carryForwardReport.stock.ulsfo_calculated != undefined ||
                    carryForwardReport.stock.hsmdo_calculated != undefined ||
                    carryForwardReport.stock.lsmdo_calculated != undefined ||
                    carryForwardReport.stock.hsmgo_calculated != undefined ||
                    carryForwardReport.stock.lsmgo_calculated != undefined ||
                    carryForwardReport.stock.hfo_calculated != undefined ||
                    carryForwardReport.stock.lfo_calculated != undefined ||
                    carryForwardReport.stock.mgo_calculated != undefined ||
                    carryForwardReport.stock.mdo_calculated != undefined ||
                    carryForwardReport.stock.b10lfo_calculated != undefined ||
                    carryForwardReport.stock.b10mgo_calculated != undefined ||
                    carryForwardReport.stock.biolfo_calculated != undefined ||
                    carryForwardReport.stock.biomgo_calculated != undefined ||
                    carryForwardReport.stock.ulsfo2020_calculated != undefined ||
                    carryForwardReport.stock.ulslfo2020_calculated != undefined ||
                    carryForwardReport.stock.ulsmdo2020_calculated != undefined ||
                    carryForwardReport.stock.ulsmgo2020_calculated != undefined ||
                    carryForwardReport.stock.vlsfo2020_calculated != undefined ||
                    carryForwardReport.stock.vlslfo2020_calculated != undefined ||
                    carryForwardReport.stock.lpgp_calculated != undefined ||
                    carryForwardReport.stock.lpgb_calculated != undefined ||
                    carryForwardReport.stock.lng_calculated != undefined ||
                    carryForwardReport.stock.methanol_calculated != undefined ||
                    carryForwardReport.stock.ethanol_calculated != undefined ||
                    carryForwardReport.stock.other_calculated != undefined

                    report.stock.previous_hshfo = hasStockCalculatedFuel ? carryForwardReport.stock.hshfo_calculated : carryForwardReport.stock.previous_hshfo - getTotalFuelConsumption('hshfo', carryForwardReport);
                    report.stock.previous_lshfo = hasStockCalculatedFuel ? carryForwardReport.stock.lshfo_calculated : carryForwardReport.stock.previous_lshfo - getTotalFuelConsumption('lshfo', carryForwardReport);
                    report.stock.previous_ulsfo = hasStockCalculatedFuel ? carryForwardReport.stock.ulsfo_calculated : carryForwardReport.stock.previous_ulsfo - getTotalFuelConsumption('ulsfo', carryForwardReport);
                    report.stock.previous_hsmdo = hasStockCalculatedFuel ? carryForwardReport.stock.hsmdo_calculated : carryForwardReport.stock.previous_hsmdo - getTotalFuelConsumption('hsmdo', carryForwardReport);
                    report.stock.previous_lsmdo = hasStockCalculatedFuel ? carryForwardReport.stock.lsmdo_calculated : carryForwardReport.stock.previous_lsmdo - getTotalFuelConsumption('lsmdo', carryForwardReport);
                    report.stock.previous_hsmgo = hasStockCalculatedFuel ? carryForwardReport.stock.hsmgo_calculated : carryForwardReport.stock.previous_hsmgo - getTotalFuelConsumption('hsmgo', carryForwardReport);
                    report.stock.previous_lsmgo = hasStockCalculatedFuel ? carryForwardReport.stock.lsmgo_calculated : carryForwardReport.stock.previous_lsmgo - getTotalFuelConsumption('lsmgo', carryForwardReport);

                    report.stock.previous_hfo = hasStockCalculatedFuel ? carryForwardReport.stock.hfo_calculated ? carryForwardReport.stock.hfo_calculated : carryForwardReport.stock.hshfo_calculated : carryForwardReport.stock.previous_hfo ? carryForwardReport.stock.previous_hfo - getTotalFuelConsumption('hfo', carryForwardReport) : carryForwardReport.stock.previous_hshfo - getTotalFuelConsumption('hshfo', carryForwardReport);
                    report.stock.previous_lfo = hasStockCalculatedFuel ? carryForwardReport.stock.lfo_calculated : carryForwardReport.stock.previous_lfo - getTotalFuelConsumption('lfo', carryForwardReport);
                    report.stock.previous_mgo = hasStockCalculatedFuel ? carryForwardReport.stock.mgo_calculated : carryForwardReport.stock.previous_mgo - getTotalFuelConsumption('mgo', carryForwardReport);
                    report.stock.previous_mdo = hasStockCalculatedFuel ? carryForwardReport.stock.mdo_calculated : carryForwardReport.stock.previous_mdo - getTotalFuelConsumption('mdo', carryForwardReport);
                    report.stock.previous_b10lfo = hasStockCalculatedFuel ? carryForwardReport.stock.b10lfo_calculated : carryForwardReport.stock.previous_b10lfo - getTotalFuelConsumption('b10lfo', carryForwardReport);
                    report.stock.previous_b10mgo = hasStockCalculatedFuel ? carryForwardReport.stock.b10mgo_calculated : carryForwardReport.stock.previous_b10mgo - getTotalFuelConsumption('b10mgo', carryForwardReport);
                    report.stock.previous_biolfo = hasStockCalculatedFuel ? carryForwardReport.stock.biolfo_calculated : carryForwardReport.stock.previous_biolfo - getTotalFuelConsumption('biolfo', carryForwardReport);
                    report.stock.previous_biomgo = hasStockCalculatedFuel ? carryForwardReport.stock.biomgo_calculated : carryForwardReport.stock.previous_biomgo - getTotalFuelConsumption('biomgo', carryForwardReport);
                    report.stock.previous_ulsfo2020 = hasStockCalculatedFuel ? carryForwardReport.stock.ulsfo2020_calculated ? carryForwardReport.stock.ulsfo2020_calculated : carryForwardReport.stock.lshfo_calculated : carryForwardReport.stock.previous_ulsfo2020 ? carryForwardReport.stock.previous_ulsfo2020 - getTotalFuelConsumption('ulsfo2020', carryForwardReport) : carryForwardReport.stock.previous_lshfo - getTotalFuelConsumption('lshfo', carryForwardReport);
                    report.stock.previous_ulslfo2020 = hasStockCalculatedFuel ? carryForwardReport.stock.ulslfo2020_calculated ? carryForwardReport.stock.ulslfo2020_calculated : carryForwardReport.stock.ulsfo_calculated : carryForwardReport.stock.previous_ulslfo2020 ? carryForwardReport.stock.previous_ulslfo2020 - getTotalFuelConsumption('ulslfo2020', carryForwardReport) : carryForwardReport.stock.previous_ulsfo - getTotalFuelConsumption('ulsfo', carryForwardReport);
                    report.stock.previous_ulsmdo2020 = hasStockCalculatedFuel ? carryForwardReport.stock.ulsmdo2020_calculated ? carryForwardReport.stock.ulsmdo2020_calculated : carryForwardReport.stock.lsmdo_calculated : carryForwardReport.stock.previous_ulsmdo2020 ? carryForwardReport.stock.previous_ulsmdo2020 - getTotalFuelConsumption('ulsmdo2020', carryForwardReport) : carryForwardReport.stock.previous_lsmdo - getTotalFuelConsumption('lsmdo', carryForwardReport);
                    report.stock.previous_ulsmgo2020 = hasStockCalculatedFuel ? carryForwardReport.stock.ulsmgo2020_calculated ? carryForwardReport.stock.ulsmgo2020_calculated : carryForwardReport.stock.hsmgo_calculated ? carryForwardReport.stock.hsmgo_calculated : carryForwardReport.stock.lsmgo_calculated : carryForwardReport.stock.previous_ulsmgo2020 ? carryForwardReport.stock.previous_ulsmgo2020 - getTotalFuelConsumption('ulsmgo2020', carryForwardReport) : carryForwardReport.stock.previous_hsmgo ? carryForwardReport.stock.previous_hsmgo - getTotalFuelConsumption('hsmgo', carryForwardReport) : carryForwardReport.stock.previous_lsmgo - getTotalFuelConsumption('lsmgo', carryForwardReport);
                    report.stock.previous_vlsfo2020 = hasStockCalculatedFuel ? carryForwardReport.stock.vlsfo2020_calculated : carryForwardReport.stock.previous_vlsfo2020 - getTotalFuelConsumption('vlsfo2020', carryForwardReport);
                    report.stock.previous_vlslfo2020 = hasStockCalculatedFuel ? carryForwardReport.stock.vlslfo2020_calculated : carryForwardReport.stock.previous_vlslfo2020 - getTotalFuelConsumption('vlslfo2020', carryForwardReport);
                    report.stock.previous_lpgp = hasStockCalculatedFuel ? carryForwardReport.stock.lpgp_calculated : carryForwardReport.stock.previous_lpgp - getTotalFuelConsumption('lpgp', carryForwardReport);
                    report.stock.previous_lpgb = hasStockCalculatedFuel ? carryForwardReport.stock.lpgb_calculated : carryForwardReport.stock.previous_lpgb - getTotalFuelConsumption('lpgb', carryForwardReport);
                    report.stock.previous_lng = hasStockCalculatedFuel ? carryForwardReport.stock.lng_calculated : carryForwardReport.stock.previous_lng - getTotalFuelConsumption('lng', carryForwardReport);
                    report.stock.previous_methanol = hasStockCalculatedFuel ? carryForwardReport.stock.methanol_calculated : carryForwardReport.stock.previous_methanol - getTotalFuelConsumption('methanol', carryForwardReport);
                    report.stock.previous_ethanol = hasStockCalculatedFuel ? carryForwardReport.stock.ethanol_calculated : carryForwardReport.stock.previous_ethanol - getTotalFuelConsumption('ethanol', carryForwardReport);
                    report.stock.previous_other = hasStockCalculatedFuel ? carryForwardReport.stock.other_calculated : carryForwardReport.stock.previous_other - getTotalFuelConsumption('other', carryForwardReport);

                    report.stock.bdn_id_hfo = carryForwardReport.stock.bdn_id_hfo;
                    report.stock.bdn_id_lfo = carryForwardReport.stock.bdn_id_lfo;
                    report.stock.bdn_id_mgo = carryForwardReport.stock.bdn_id_mgo;
                    report.stock.bdn_id_mdo = carryForwardReport.stock.bdn_id_mdo;
                    report.stock.bdn_id_b10lfo = carryForwardReport.stock.bdn_id_b10lfo;
                    report.stock.bdn_id_b10mgo = carryForwardReport.stock.bdn_id_b10mgo;
                    report.stock.bdn_id_biolfo = carryForwardReport.stock.bdn_id_biolfo;
                    report.stock.bdn_id_biomgo = carryForwardReport.stock.bdn_id_biomgo;
                    report.stock.bdn_id_ulsfo2020 = carryForwardReport.stock.bdn_id_ulsfo2020;
                    report.stock.bdn_id_ulslfo2020 = carryForwardReport.stock.bdn_id_ulslfo2020;
                    report.stock.bdn_id_ulsmdo2020 = carryForwardReport.stock.bdn_id_ulsmdo2020;
                    report.stock.bdn_id_ulsmgo2020 = carryForwardReport.stock.bdn_id_ulsmgo2020;
                    report.stock.bdn_id_vlsfo2020 = carryForwardReport.stock.bdn_id_vlsfo2020;
                    report.stock.bdn_id_vlslfo2020 = carryForwardReport.stock.bdn_id_vlslfo2020;
                    report.stock.bdn_id_lpgp = carryForwardReport.stock.bdn_id_lpgp;
                    report.stock.bdn_id_lpgb = carryForwardReport.stock.bdn_id_lpgb;
                    report.stock.bdn_id_lng = carryForwardReport.stock.bdn_id_lng;
                    report.stock.bdn_id_methanol = carryForwardReport.stock.bdn_id_methanol;
                    report.stock.bdn_id_ethanol = carryForwardReport.stock.bdn_id_ethanol;
                    report.stock.bdn_id_other = carryForwardReport.stock.bdn_id_other;
                    
                    report.stock.prev_aux_engine_lube_oil = 
                    carryForwardReport.stock.prev_aux_engine_lube_oil
                    - (carryForwardReport.power.aux_engine_1_lo || 0)
                    - (carryForwardReport.power.aux_engine_2_lo || 0)
                    - (carryForwardReport.power.aux_engine_3_lo || 0)
                    - (carryForwardReport.power.aux_engine_4_lo || 0);

                    if (carryForwardReport.stock != undefined && carryForwardReport.stock.tanks != undefined) {
                        Object.keys(carryForwardReport.stock.tanks).map(function(tankId, index) {
                            var stockTank = carryForwardReport.stock.tanks[tankId];
                            report.stock.tanks[tankId] = {};
                            if (!stockTank.in_use) {
                                report.stock.tanks[tankId] = {
                                    amount: stockTank.amount,
                                    in_use: stockTank.in_use,
                                }
                            }
                        
                            report.stock.tanks[tankId].amount_before_bunkering = stockTank.amount;
                            report.stock.tanks[tankId].fuel_grade = stockTank.fuel_grade;
                            report.stock.tanks[tankId].fuel_grade_added_in_tank = stockTank.fuel_grade;
                            report.stock.tanks[tankId].sulphur_after_bunkering = stockTank.sulphur_after_bunkering || carryForwardReport.consumption['sulphur_' + stockTank.fuel_grade];
                            report.stock.tanks[tankId].lcv = stockTank.lcv || carryForwardReport.consumption['lcv_' + stockTank.fuel_grade];
                            report.stock.tanks[tankId].density = stockTank.density || carryForwardReport.consumption['density_' + stockTank.fuel_grade];
                            report.stock.tanks[tankId].sulphur_before_bunkering = stockTank.sulphur_after_bunkering || carryForwardReport.consumption['sulphur_' + stockTank.fuel_grade];
                            report.stock.tanks[tankId].lcv_before_bunkering = stockTank.lcv || carryForwardReport.consumption['lcv_' + stockTank.fuel_grade];
                            report.stock.tanks[tankId].density_before_bunkering = stockTank.density || carryForwardReport.consumption['density_' + stockTank.fuel_grade];
                            report.stock.tanks[tankId].bunker_date = formatMoment(parseFieldDate(stockTank.bunker_date));
                            report.stock.tanks[tankId].bunker_port = stockTank.bunker_port;
                            report.stock.tanks[tankId].stable_until = formatMoment(parseFieldDate(stockTank.stable_until));
                            report.stock.tanks[tankId].bdn_number = stockTank.bdn_number;
                            report.stock.tanks[tankId].co2_eq_wtt = stockTank.co2_eq_wtt;
                            report.stock.tanks[tankId].cf_co2 = stockTank.cf_co2;
                            report.stock.tanks[tankId].cf_ch4 = stockTank.cf_ch4;
                            report.stock.tanks[tankId].cf_n2o = stockTank.cf_n2o;
                            report.stock.tanks[tankId].c_slip = stockTank.c_slip;
                            report.stock.tanks[tankId].is_blend = stockTank.is_blend;
                        });
                    }

                    Object.keys(old_to_new_fuel_types).map(function(old_fuel_grade,index) {
                        if (carryForwardReport.stock[old_fuel_grade+'_calculated'] > 0 && default_stock_fuel_types.includes(old_to_new_fuel_types[old_fuel_grade]) != true) {
                            default_stock_fuel_types.push(old_to_new_fuel_types[old_fuel_grade])
                        }
                    })
                    
                } else {
                    // stock tab carry over
                    if (carryForwardReport.stock != undefined && carryForwardReport.stock.tanks != undefined) {
                        Object.keys(carryForwardReport.stock.tanks).map(function(tankId, index) {
                            var stockTank = carryForwardReport.stock.tanks[tankId];
                            if (report.bdn_based_reporting) {
                                /**
                                 * For BDN Based Reporting, new_bdn should have all the calculated values in the previous report
                                 */
                                if (stockTank.bdn_added_in_tank && stockTank.new_bdn) {
                                    stockTank = cloneDeep(stockTank.new_bdn);
                                }
                            }
                            if (!stockTank.in_use) {
                                report.stock.tanks[tankId] = {
                                    amount: stockTank.amount,
                                    fuel_grade: stockTank.fuel_grade,
                                    in_use: stockTank.in_use,
                                }
                            } else {
                                report.stock.tanks[tankId] = {
                                    fuel_grade: stockTank.fuel_grade,
                                    in_use: stockTank.in_use
                                }
                            }
                            report.stock.tanks[tankId].amount_before_bunkering = stockTank.amount;

                            var old_fuel_grade_to_new_fuel_grade_mapping = {
                                'hshfo':'hfo',
                                'lshfo':'ulsfo2020',
                                'ulsfo':'ulslfo2020',
                                'lsmdo':'ulsmdo2020',
                                'hsmgo':'ulsmgo2020',
                                'lsmgo':'ulsmgo2020'
                            }
                            if (report.stock.new_fuel_configuration) {
                                stockTank.fuel_grade = old_fuel_grade_to_new_fuel_grade_mapping[stockTank.fuel_grade] || stockTank.fuel_grade;
                                report.stock.tanks[tankId].fuel_grade = stockTank.fuel_grade;
                            } else {
                                report.stock.tanks[tankId].fuel_grade = stockTank.fuel_grade;
                            }
                            report.stock.tanks[tankId].fuel_grade_added_in_tank = stockTank.fuel_grade;
                            report.stock.tanks[tankId].sulphur_after_bunkering = stockTank.sulphur_after_bunkering || carryForwardReport.consumption['sulphur_' + stockTank.fuel_grade];
                            report.stock.tanks[tankId].lcv = stockTank.lcv || carryForwardReport.consumption['lcv_' + stockTank.fuel_grade];
                            report.stock.tanks[tankId].density = stockTank.density || carryForwardReport.consumption['density_' + stockTank.fuel_grade];
                            report.stock.tanks[tankId].sulphur_before_bunkering = stockTank.sulphur_after_bunkering || carryForwardReport.consumption['sulphur_' + stockTank.fuel_grade];
                            report.stock.tanks[tankId].lcv_before_bunkering = stockTank.lcv || carryForwardReport.consumption['lcv_' + stockTank.fuel_grade];
                            report.stock.tanks[tankId].density_before_bunkering = stockTank.density || carryForwardReport.consumption['density_' + stockTank.fuel_grade];
                            report.stock.tanks[tankId].bunker_date = formatMoment(parseFieldDate(stockTank.bunker_date));
                            report.stock.tanks[tankId].bunker_port = stockTank.bunker_port;
                            report.stock.tanks[tankId].stable_until = formatMoment(parseFieldDate(stockTank.stable_until));
                            report.stock.tanks[tankId].bdn_number = stockTank.bdn_number;
                            report.stock.tanks[tankId].co2_eq_wtt = stockTank.co2_eq_wtt;
                            report.stock.tanks[tankId].cf_co2 = stockTank.cf_co2;
                            report.stock.tanks[tankId].cf_ch4 = stockTank.cf_ch4;
                            report.stock.tanks[tankId].cf_n2o = stockTank.cf_n2o;
                            report.stock.tanks[tankId].c_slip = stockTank.c_slip;
                            report.stock.tanks[tankId].is_blend = stockTank.is_blend;
                        });
                    }
                    report.stock.previous_hshfo = carryForwardReport.stock.hshfo;
                    report.stock.previous_lshfo = carryForwardReport.stock.lshfo;
                    report.stock.previous_ulsfo = carryForwardReport.stock.ulsfo;
                    report.stock.previous_hsmdo = carryForwardReport.stock.hsmdo;
                    report.stock.previous_lsmdo = carryForwardReport.stock.lsmdo;
                    report.stock.previous_hsmgo = carryForwardReport.stock.hsmgo;
                    report.stock.previous_lsmgo = carryForwardReport.stock.lsmgo;

                    report.stock.previous_hfo = carryForwardReport.stock.hfo ? carryForwardReport.stock.hfo : carryForwardReport.stock.hshfo;
                    report.stock.previous_lfo = carryForwardReport.stock.lfo;
                    report.stock.previous_mgo = carryForwardReport.stock.mgo;
                    report.stock.previous_mdo = carryForwardReport.stock.mdo;
                    report.stock.previous_b10lfo = carryForwardReport.stock.b10lfo;
                    report.stock.previous_b10mgo = carryForwardReport.stock.b10mgo;
                    report.stock.previous_biolfo = carryForwardReport.stock.biolfo;
                    report.stock.previous_biomgo = carryForwardReport.stock.biomgo;
                    report.stock.previous_ulsfo2020 = carryForwardReport.stock.ulsfo2020 ? carryForwardReport.stock.ulsfo2020 : carryForwardReport.stock.lshfo;
                    report.stock.previous_ulslfo2020 = carryForwardReport.stock.ulslfo2020 ? carryForwardReport.stock.ulslfo2020 : carryForwardReport.stock.ulsfo;
                    report.stock.previous_ulsmdo2020 = carryForwardReport.stock.ulsmdo2020 ? carryForwardReport.stock.ulsmdo2020 : carryForwardReport.stock.lsmdo;
                    report.stock.previous_ulsmgo2020 = carryForwardReport.stock.ulsmgo2020 ? carryForwardReport.stock.ulsmgo2020 : carryForwardReport.stock.hsmgo ? carryForwardReport.stock.hsmgo: carryForwardReport.stock.lsmgo;
                    report.stock.previous_vlsfo2020 = carryForwardReport.stock.vlsfo2020;
                    report.stock.previous_vlslfo2020 = carryForwardReport.stock.vlslfo2020;
                    report.stock.previous_lpgp = carryForwardReport.stock.lpgp;
                    report.stock.previous_lpgb = carryForwardReport.stock.lpgb;
                    report.stock.previous_lng = carryForwardReport.stock.lng;
                    report.stock.previous_methanol = carryForwardReport.stock.methanol;
                    report.stock.previous_ethanol = carryForwardReport.stock.ethanol;
                    report.stock.previous_other = carryForwardReport.stock.other;

                    report.stock.prev_aux_engine_lube_oil = carryForwardReport.stock.aux_engine_lube_oil;

                    Object.keys(old_to_new_fuel_types).map(function(old_fuel_grade,index) {
                        if (carryForwardReport.stock[old_fuel_grade] > 0 && default_stock_fuel_types.includes(old_to_new_fuel_types[old_fuel_grade]) != true) {
                            default_stock_fuel_types.push(old_to_new_fuel_types[old_fuel_grade])
                        }
                    })
                    
                }
                report.stock.fuel_type_1 = carryForwardReport.stock.fuel_type_1 ? carryForwardReport.stock.fuel_type_1 : default_stock_fuel_types[0] ? default_stock_fuel_types[0] : undefined;
                report.stock.fuel_type_2 = carryForwardReport.stock.fuel_type_2 ? carryForwardReport.stock.fuel_type_2 : default_stock_fuel_types[1] ? default_stock_fuel_types[1] : undefined;
                report.stock.fuel_type_3 = carryForwardReport.stock.fuel_type_3 ? carryForwardReport.stock.fuel_type_3 : default_stock_fuel_types[2] ? default_stock_fuel_types[2] : undefined;
            }
            
            report.last_reported_stock = carryForwardReport._cls === 'Report.ManeuveringReport' ? cloneDeep(carryForwardReport.last_reported_stock) : cloneDeep(carryForwardReport.stock);
            if (report.last_reported_stock != undefined) {
                if (report.last_reported_stock.fuel_water_stock_date) report.last_reported_stock.fuel_water_stock_date = formatMoment(parseFieldDate(report.last_reported_stock.fuel_water_stock_date, true));
                if (report.last_reported_stock.lube_datetime) report.last_reported_stock.lube_datetime = formatMoment(parseFieldDate(report.last_reported_stock.lube_datetime, true));
                if (report.last_reported_stock.tank_transfers && report.last_reported_stock.tank_transfers.length > 0) {
                    report.last_reported_stock.tank_transfers.map(function(tankTransfer) {

                        if (tankTransfer.from_tank && tankTransfer.from_tank['$oid']) {
                            tankTransfer.from_tank = tankTransfer.from_tank['$oid'];
                        }
                        if (tankTransfer.to_tank && tankTransfer.to_tank['$oid']) {
                            tankTransfer.to_tank = tankTransfer.to_tank['$oid'];
                        }
                    })
                }
                if (report.last_reported_stock.tanks) {
                    Object.keys(report.last_reported_stock.tanks).map(function(tankId) {
                        let lastReportedStockTank = report.last_reported_stock.tanks[tankId];
                        if (report.last_reported_stock.tanks[tankId].bunker_date) {
                            report.last_reported_stock.tanks[tankId].bunker_date = formatMoment(parseFieldDate(lastReportedStockTank.bunker_date, true));
                        }
                        if (report.last_reported_stock.tanks[tankId].stable_until) {
                            report.last_reported_stock.tanks[tankId].stable_until = formatMoment(parseFieldDate(lastReportedStockTank.stable_until, true));
                        }
                        if (report.last_reported_stock.tanks[tankId].modified_date) {
                            report.last_reported_stock.tanks[tankId].modified_date = formatMoment(parseFieldDate(lastReportedStockTank.modified_date, true));
                        }
                    });
                }
            }
        }
        newVesselReport = report;
        return report;
    };
    
    /**
     * @param carryForwardReport - previous report
     * @param report - new report
     * @param propertyPath - field to carry over e.g. 'operational.etd'
     */
    function carryForwardByDefault(carryForwardReport, report, propertyPath) {
        // return carry over value when...
        // vessel has no carry over configurations or if propertyPath doesn't have a rule
        if (!carryForwardFields || Object.keys(carryForwardFields).length == 0 || carryForwardFields && Object.keys(carryForwardFields).length > 0 && !carryForwardFields[propertyPath]) {
            return true;
        } else if (carryForwardFields && Object.keys(carryForwardFields).length > 0 && carryForwardFields[propertyPath]) { 
            // check path config exist
            // if carry over rule is enabled and rules apply
            return carryForwardFields[propertyPath].enabled && applyCarryForwardRule(carryForwardReport, report, carryForwardFields[propertyPath].rules);
        } else {
            return true;
        }
    }

    function carryForwardIfEnabled(carryForwardReport, report, propertyPath) {
        // skip by default
        if (!carryForwardFields || Object.keys(carryForwardFields).length == 0 || carryForwardFields && Object.keys(carryForwardFields).length > 0 && !carryForwardFields[propertyPath]) {
            return false;
        } else if (carryForwardFields && Object.keys(carryForwardFields).length > 0 && carryForwardFields[propertyPath]) { 
            // if carry over rule is enabled and rules apply
            return carryForwardFields[propertyPath].enabled && applyCarryForwardRule(carryForwardReport, report, carryForwardFields[propertyPath].rules)
        } else {
            return false;
        }
    }

    /**
     * @param carryForwardReport - previous report
     * @param report - new report
     * @param rules - list of rules and conditions to check against a path for either carryForward report or new report
     * condition1 = {carry_over_report: false, path: '_cls': path_value: 'Report.PortReport'}
     * rule1 = [condition1,... condition2]
     * ex: rules = [rule1, rule2, etc]
     */
    function applyCarryForwardRule(carryForwardReport, receivingReport, rules) {
        var result = false;
        // if no defined rules, then carry over data
        if (rules && rules.length == 0) {
            return true;
        }
        // if there are some rules
        for(var rule of rules) {
            // loop through OR rules, if any true immediately return true
            result = true;
            let conditions = rule;
            for(var condition of conditions) {
                // loop through all conditions if any apply
                let report = condition.carry_over_report ? carryForwardReport : receivingReport;
                let path = condition.path;
                let reportValue = get(report, path);
                // check if field exists and has value to carry over
                if (reportValue != undefined && condition.path_value != undefined && condition.path_value != reportValue) {
                    result = false;
                    break;
                }
            }
            if (result) {
                return result;
            } 
        }
        // if we get here, most likely false
        return result;
    }
    const getMatchingBdns = (newBdn: string, existingBDNs: Array<string>) => {
        return existingBDNs.filter((bdn) => {
        if (bdn.lastIndexOf("_") > -1) return newBdn == bdn.substring(0, bdn.lastIndexOf("_"));
        return newBdn == bdn;
        }).sort();
    }
  
  const getTankBdns = (tanks) => {
    return Object.values(tanks)
      .filter((tank: any) => tank.bdn_number)
      .map((tank: any) => tank.bdn_number)
  }

  const getBdnNumber = ((fuel_grade: string, dateTime: string) => {
    const tankStock = new TankStock();
    if (dateTime == undefined) {
      tankStock.setFuelGrade(fuel_grade);
    } else {
      tankStock.setFuelGrade(fuel_grade, moment(dateTime, "DD/MM/YYYY hh:mm" ));
    }
    return tankStock.bdn_number;
  })

  const isBdnUnique = (matchingBdns) => {
      return matchingBdns.length <= 1
  }
  
  const getBdnIteration = (matchingBdns: string[], bdn: string) => {
    const lastBdnIteration = matchingBdns.pop();

    if (lastBdnIteration.lastIndexOf("_") == -1) return `${bdn}_01`;
    
    return `${bdn}_${(Number(lastBdnIteration.split("_").pop()) + 1)
      .toString().padStart(2, '0')}`
  }

  const addBdnNumbers = (scope) => {
    const tankIds = Object.keys(scope.report.stock.tanks).filter((id)=> id != 'undefined')
    const tankValues: Array<any> = Object.values(scope.report.stock.tanks).filter((value)=> value != undefined)

    tankValues.map((tank: any, index) => {
      if (tank?.bdn_number) return;
      const bdn = getBdnNumber(tank?.fuel_grade, tank?.bunker_date);
      scope.report.stock.tanks[tankIds[index]].bdn_number = bdn

      const allBdns = getTankBdns(scope.report.stock.tanks)
      const matchingExistingBdns = getMatchingBdns(bdn, allBdns);

      if (isBdnUnique(matchingExistingBdns)) return
    
      scope.report.stock.tanks[tankIds[index]].bdn_number = getBdnIteration(matchingExistingBdns, bdn);
    })
  }

  const generateActiveBDNFromReport = (report) => {
    // temp: if you want to test this make report.bdn_based_reporting= true: this will come from the backend and 
    // is carried over from report to report on th front-end in updateVesselReportWithCarryForwardReport
    return Object.values(report.stock.tanks).map((tank: TankStockObject) => new TankStock(tank));
}

  return {
        addBdnNumbers: addBdnNumbers,
        generateActiveBDNFromReport: generateActiveBDNFromReport,
        applyDatesOnReport: applyDatesOnReport,
        applyDatesOnEngineReport: applyDatesOnEngineReport,
        applyDatesOnOffhireReport: applyDatesOnOffhireReport,
        // call this function when you have no report to start from, so if the
        // last incomplete vessel report is none (all reports are submitted and
        // there are no open reports)
        createNewVesselReport: function(reportTypeState: string, vessel_specfications) {
            var todayDate = formatMoment(moment());
            let cls = statesToReportTypes[reportTypeState] || "Report.SeaReport";
            newVesselReport = {
                _cls: cls,
                status: 'started',
                report_number: vesselReportNumber,
                operational: {
                    report_creation: todayDate,
                    operating_code: undefined,
                    speed_instruction: '1',
                    ballast_code: '1',
                },
                cargo: {
                    cargo_grade: [],
                    bl_quantity: [],
                    surveyors_cargo_quantity: [],
                    flash_point: [],
                    density: [],
                    pour_point: [],
                    load_rate: [],
                    load_temp: [],
                    bl_code: [],
                    bl_discharge: [],
                    bl_issue: [],
                    destination_port: [],
                    shipper: [],
                    consignee: [],
                    charterer: [],
                    s2s_ship_name: [],
                    n_ship_cranes: [],
                    n_shore_cranes: [],
                    berth: [],
                    ship_cranes: [],
                    shore_cranes: []
                },
                position: {
                    swell: 1,
                    ship_lat_direction: 'N',
                    ship_lon_direction: 'E'
                },
                engine: {
                     main_engines: [{}, {}]
                },
                consumption: {
                    oil_time_of_transfer: new Date(),
                    oil_code: '1',
                    oil_discharge: '1',
                    cyl_oil_code: '1',
                    total_main_engine_consumption: 0,
                    total_aux_engine_consumption: 0
                },
                stock: {
                    tank_sounding: true, 
                    tank_transfers: [], 
                },
                bunker: {
                    took_fuel_oil: true,
                    bunkered_fuel_grades: [],
                    debunkered_fuel_grades: [],
                    bdn_based_reporting_debunkered_fuels: [new TankStock(), new TankStock()],
                },
                computed: {}
            };
            if (vessel_specfications && vessel_specfications.bdn_based_reporting) {
                newVesselReport = {...newVesselReport, bdn_based_reporting: vessel_specfications.bdn_based_reporting}
            }
            if (carryForwardVesselReport != undefined) {
                updateVesselReportWithCarryForwardReport(newVesselReport, carryForwardVesselReport, true);
            }
            if (vesselReportNumber != undefined) {
                newVesselReport.report_number = vesselReportNumber;
            }
            return newVesselReport;
        },
        initializeUploadReport: function(uploadedReport, reportType) {
            if (reportType === 'bunker_only') {
                Object.assign(newVesselReport.bunker, uploadedReport.bunker);
            } else {
                this.createNewVesselReport();
                newVesselReport._cls = uploadedReport._cls;
                newVesselReport.comments = uploadedReport.comments
                Object.assign(newVesselReport.operational, uploadedReport.operational);
                Object.assign(newVesselReport.position, uploadedReport.position);
                Object.assign(newVesselReport.power, uploadedReport.power);
                Object.assign(newVesselReport.engine, uploadedReport.engine);
                Object.assign(newVesselReport.consumption, uploadedReport.consumption);
                Object.assign(newVesselReport.stock, uploadedReport.stock);
                Object.assign(newVesselReport.cargo, uploadedReport.cargo);
                if (reportType === 'port') {
                    newVesselReport.cargo.operation_type = uploadedReport.cargo.operation_type
                }
            }
            this.applyDatesOnReport(newVesselReport);
        },
        // set vessel report
        setVesselReport: function(report) {
            newVesselReport = report;
            if (newVesselReport.operational == undefined) newVesselReport.operational = {};
            newVesselReport.operational.report_number = vesselReportNumber;
            if (newVesselReport["_id"] != undefined) {
                // prepare the forms with the report information that we got
                // from the server
                newVesselReport.id = newVesselReport["_id"]["$oid"];
                newVesselReport["_id"] = undefined;
                newVesselReport = applyDatesOnReport(newVesselReport);
            }

            if (newVesselReport.warningMessages == undefined) newVesselReport.warningMessages = {};
        },
        // return vessel report that was being edited
        getVesselReport: function() {
            return newVesselReport;
        },

        setVesselSpecsTanks: function(vesselSpecsTanks) {
            var i;
            for (i = 0; i < vesselSpecsTanks.length; i++) {
                if (!!vesselSpecsTanks[i].name.match(/SERV|SETT|OVERFLOW/i)) {
                    tankInUseHidden[vesselSpecsTanks[i].tank_id["$oid"]] = true;
                }
            }
        },

        updateVesselReportWithCarryForwardReport: updateVesselReportWithCarryForwardReport,

        setVesselReportNumber: function(reportNumber) {

            vesselReportNumber = reportNumber;
        },

        // vessel performance list
        setVesselReportsList: function(list) {
            vesselReportsList = list;
        },
        setMEReportList: function(list) {
            meReportList = list;
        },
        setAEReportList: function(list) {
            aeReportList = list;
        },
        setOffhireReportList: function(list) {
            offhireReportList = list;
        },
        setDashboardData: function(data) {
            dashboardData = data;
        },
        getDashboardData: function() {
            return dashboardData
        },
        getVesselReportsList: function() {
            return vesselReportsList;
        },
        getMEReportList: function() {
            return meReportList;
        },
        getAEReportList: function() {
            return aeReportList;
        },
        getOffhireReportList: function() {
            return offhireReportList;
        },
        // removes the report from the list given the reportId, this is used
        // when we want to cancel a report and we don't want to make a full
        // query to the server to get the reports list again
        removeReportFromList: function(reportId) {
            vesselReportsList = vesselReportsList.filter(function(report) {
                return report.id != reportId;
            });
        },
        // return engine report in edit mode, returns empty report
        createNewEngineReport: function() {
            var todayDate = formatMoment(moment());
            return {
                _cls: 'EngineReport.MEReport',
                report_number: meReportNumber,
                status: 'started',
                creation_time: todayDate,
                turbocharging: {
                    turbo: []
                },
                cylinder_units: {
                    cyl: []
                },
                details: {
                    creation_time: todayDate
                }
            };
        },
        // set engine report
        setEngineReport: function(report) {
            newEngineReport = report;
            if (newEngineReport["_id"] != undefined) {
                newEngineReport.id = newEngineReport["_id"]["$oid"];
                newEngineReport["_id"] = undefined;
            }
        },
        getEngineReport: function() {
            return newEngineReport;
        },
        getEngineReportView: function(report) {
            // report must have the _cls field set
            return engineReportTypeStates[report._cls];
        },
        setMEReportNumber: function(reportNumber) {
            meReportNumber = reportNumber || 1;
        },
        getMEReportNumber: function() {
            return meReportNumber;
        },
        setAEReportNumber: function(reportNumber) {
            aeReportNumber = reportNumber || 1;
        },
        getAEReportNumber: function() {
            return aeReportNumber;
        },
        createNewOffhireReport: function() {
            return {
                _cls: 'OffhireReport',
                report_number: offhireReportNumber,
                status: 'started'
            };
        },
        getOffhireReport: function() {
            return newOffhireReport;
        },
        setOffhireReport: function(report) {
            report['_cls'] = 'OffhireReport';
            newOffhireReport = report;
        },
        setOffhireReportNumber: function(reportNumber) {
            offhireReportNumber = reportNumber || 1;
      },
        
        
        clearService: clearService,
        formatMoment: formatMoment,
        parseFieldDate: parseFieldDate, 
        setCarryForwardOptions: function({ fields }) {
            carryForwardFields = fields;
    },
        setPreTransferStock: setPreTransferStock,
        getPreTransferStock: getPreTransferStock,
    }
});

routerApp.factory('ReportsApiService', ['$http', function($http) {
    const requestFunc = httpRequestGenerator(enableOnPremise, $http);
    // this maintains the report that is being edited, there should always only be one report being edited
    // this report is either a new version of the report (that has the required data from the previous versions and any
    // other newly initialized fields. this should remain here and this service should handle all the report switching
    var newReport: any = {};
    var hasNewReportBackup = false;
    var isLoadingReports = false;

    var reportTypeStates = {
        'Report.SeaReport': 'site.seaReport',
        'Report.PortReport': 'site.portReport',
        'Report.AnchorReport': 'site.anchorReport',
        'Report.ManeuveringReport': 'site.maneuveringReport',
        'OffhireReport': 'site.offhireReport',
    }

    return {
        updateNewReportWithLastReportData: function(lastReport) {
            if (newReport.operational == undefined) newReport.operational = {};
            newReport.operational.captain_name = "captain_name";
            newReport.operational.chief_engineer = "chief_engineer";
        },
        setNewReport: function(report) {
            // use this function before you try to load any other historical reports
            if (hasNewReportBackup == false) {
                newReport = report;
                hasNewReportBackup = true;
            }
        },
        getNewReport: function() {
            // returns the report that is being edited, and assumes that the backed up new report is no longer needed
            hasNewReportBackup = false;
            return newReport;
        },
        hasNewReportBackup: function() {
            return hasNewReportBackup;
        },
        getReportView: function(report) {
            // follows the _cls property on the report which is set from the backend
            // ideally this would only be used with reports that are approved/saved in the backend
            // returns the view state of the report based on the report class
            return reportTypeStates[report._cls];
        },
        getReports: function(vesselId) {
            var url = baseUrl + vesselId + "/reports?callback=JSON_CALLBACK";
            return requestFunc({
                url: url,
                method: "GET",
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });
        },

        getReport: function(vesselId, reportNumber) {
            var url = baseUrl + vesselId + "/reports/" + reportNumber + "?callback=JSON_CALLBACK";
            return requestFunc({
                    url: url,
                    method: "GET",
                    headers: {
                                'Content-Type': 'application/json; charset=utf-8'
                    }
            });
        },
        deleteEngineReport: function(vesselId, reportId) {
            var url = baseUrl + vesselId + "/engineReports/" + reportId;
            return $http.delete(url);
        },
        getDashboardData: function(vesselId, username) {
            var url = baseUrl + vesselId + "/dashboardData";
            if (username) {
                url += "?user=" + username;
            }
            return requestFunc({
                url: url,
                method: "GET"
            });
        },

        // gets the last incomplete report from the list of reports on the vessel
        // in case the returned value here is null, we should initialize a new report
        getLastIncompleteReport: function(vesselId) {
            var url = baseUrl + vesselId + "/lastIncompleteReport";
            return requestFunc({
                url: url,
                method: "GET"
            });
        },
        // get carry over data to fill the current report
        getCarryOverData: function(vesselId, reportId) {
            var url = baseUrl + vesselId + "/carryOverData";
            return requestFunc({
                url: url,
                method: "GET",
                params: {
                    reportId: reportId
                }
            });
        },
        // get RA tab data for sea reports
        getRAData: function(vesselId, reportId) {
            var url = baseUrl + vesselId + "/raData";
            if (reportId != undefined) {
                url += "?reportId=" + reportId;
            }
            return requestFunc({
                url: url,
                method: "GET"
            });
        },
        getPreviousCoordinates: function(vesselId, reportId) {
            var url = baseUrl + vesselId + '/reports/previous_coordinates';
            return requestFunc({ url: url, params: { reportId: reportId } });
        },
        uploadReport: function(vesselId, form) {
            var url = baseUrl + vesselId + '/reportUpload';
            return $http.post(url, form, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        },
        modelReport: function(vesselId, report) {
            var url = baseUrl + vesselId + '/modelReport';
            return requestFunc({url: url, method: 'POST', data: report});
        },
        setLoadingReports: function(newValue) {
            isLoadingReports = newValue;
        },
        isLoadingReports: function() {
            return isLoadingReports;
        },
        getReportHistory: function(vesselId, reportId, scope) {
            if (vesselId && reportId && scope && scope.report) {
                var url = baseUrl + vesselId + "/reports/" + reportId + "/history";
                requestFunc({url: url})
                .then(res => {
                    scope.report.history = res.data.data.history
                    for (let historyItem of scope.report.history) {
                        historyItem.diffPrettyString = JSON.stringify(historyItem.diff, null, 2);
                        historyItem.date = moment(historyItem.timestamp, 'YYYY-MM-DD HH:mm:ss').toDate();
                    }
                });
            }
        }
    }
}]);
