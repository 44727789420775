import { baseUrl, enableHybrid, enableOnPremise, reportRequestTimeout, routerApp } from '../app.module';
import { httpRequestGenerator } from '../services/utilities';
import { exportOffshoreReports } from '../utils/export-offshore-reports';

routerApp.controller('offshoreReportController', ['$rootScope', '$scope', '$stateParams', '$http', '$state', 'notify', 'ReportsOffshoreService', 'ReportsOffshoreApiService',
function($rootScope, $scope, $stateParams, $http, $state, notify, ReportsOffshoreService, ReportsOffshoreApiService) {

    $rootScope.selectedLevels = ['vessel-reports', 'create-new-report', 'offshore-report'];
    $scope.report = {}

    if ($stateParams.report != undefined) {
        $scope.report = $stateParams.report;
        $scope.viewReport = $stateParams.viewReport;
    }

    const requestFunc = httpRequestGenerator(enableOnPremise, $http);

    const OPTIONAL_KEYS = ['bdn2', 'bdn3', 'bdn4', 'consumptionMt', 'consumption2Mt', 'consumption3Mt', 'consumption4Mt']

    $scope.isSaveDisabled = function() {
        const keys = Object.keys($scope.reportForm).filter((key: string) => !key.startsWith('$')) as string[]
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            if (OPTIONAL_KEYS.includes(key)) continue
            const value = $scope.reportForm[key]
            if (value.$valid) {
                return false
            }
        }
        return true
    }

    const getCarryoverData = () => {
        if ($scope.report.report_number) {
            return ReportsOffshoreService.getPreviousReport($scope.report.report_number)
        }

        const reports = ReportsOffshoreService.getReports()
        if (!reports?.length) return null
        return reports[0]
    }
    // TODO #83621 - remove next line once getCarryoverData is actually used
    getCarryoverData() // call here or build breaks due to 'unused' function

    const getCarryoverBDN = () => {
        const tanks = $scope.carryOverReport?.stock?.tanks || {}
        if (Object.keys(tanks).length === 0) return []

        const bdnNumbers = Object.keys(tanks)
            .reduce((prev, curr) => [...prev, tanks[curr].bdn_number], [])
            .filter((bdn) => bdn)

        return bdnNumbers
    }

    const getAlreadySelectedBDN = () => {
        const offshoreReports = $scope.report || {}
        if (Object.keys(offshoreReports).length === 0) return []

        const selectedBDNNumbers = Object.keys(offshoreReports)
            .reduce((prev, curr) => {
                if (curr.indexOf('bdn_') > -1) {
                    return [...prev, offshoreReports[curr]]
                }
                return prev
            }, [])
            .filter((bdn) => bdn)

        return selectedBDNNumbers
    }

    const getConsumptionIds = () => {
        if ($scope.report.bdn_based_reporting) {
            return getCarryoverBDN()
        }

        return [
            'HFO',
            'LFO',
            'ULSMGO2020',
            'MFO',
            'VLSFO2020'
        ]
    }

    $scope.exportReport = (report) => {
        exportOffshoreReports(
            [report],
            `offshore_export_${report.report_number}`
        )
    }

    $scope.getBdnOptions = function (index: number) {
        if ($scope.report.bdn_based_reporting) {
            $scope.ConsumptionType = 'BDN'
        } else {
            $scope.ConsumptionType = 'Fuel Grade'
        }
        const consumptionIds = getConsumptionIds()
        const alreadySelected = getAlreadySelectedBDN()
        const filtered = consumptionIds.filter((bdn) => {
            const isActiveOption = $scope.report?.['bdn_' + index] === bdn
            return (isActiveOption || !alreadySelected.includes(bdn)) && bdn
        })
        
        return ['', ...filtered]
    }

    $scope.deleteReport = function(report) {
        const vesselId = $rootScope.selectedVessel.id
        const reportId = report._id
        if (!vesselId || !reportId) return

        ReportsOffshoreApiService.deleteReport($rootScope.selectedVessel.id, report._id).then((response) => {
            notify({message: 'Report deleted.', duration: 2000, classes: ['ok-notification']});
            $('.modal-backdrop').remove();
            ReportsOffshoreService.removeReportFromList(report._id)
            $state.go('site.vesselOffshorePerformance');
        }, (error) => {
            notify({message: 'Failed to delete report.', duration: 2000, classes: ['bad-notification']});
        })
    }

    $scope.saveReport = function(report) {
        if (!report) return

        const url = [
            `${baseUrl}${$rootScope.selectedVessel.id}/offshoreReports`,
            report._id ? `/${report._id}` : ''
        ].join('')
        const method = report._id ? 'PUT' : 'POST'
        const options = (enableHybrid || enableOnPremise) ? {timeout: reportRequestTimeout * 1000} : {};
        requestFunc({url, method, data: report, ...options})
            .then(function (response, status, headers, config) {
                if (response.data) {
                    report._id = response.data._id['$oid']
                    report.report_number = response.data.report_number
                    report.status = response.data.status
                    ReportsOffshoreService.addReportToList(report)
                }
                notify({message: 'Report saved.', duration: 2000, classes: ['ok-notification']});
            }, function (data, status, header, config) {
                notify({message: 'Report save failed.', duration: 2000, classes: ['bad-notification']});
            })
    }

    $scope.submitReport = function(report) {
        const url = [
            `${baseUrl}${$rootScope.selectedVessel.id}/offshoreReports`,
            report._id ? `/${report._id}` : '',
            '?submit=true'
        ].join('');
        const method = report._id ? 'PUT' : 'POST'
        const options = (enableHybrid || enableOnPremise) ? {timeout: reportRequestTimeout * 1000} : {};
        requestFunc({url, method, data: report, ...options})
            .then(function (response, status, headers, config) {
                notify({message: 'Report submitted.', duration: 2000, classes: ['ok-notification']});
                $state.go('site.vesselOffshorePerformance');
            }, function (data, status, header, config) {
                notify({message: 'Report submission failed.', duration: 2000, classes: ['bad-notification']});
            })
    }
}]);
