import { routerApp } from '../app.module';
import { exportOffshoreReports } from '../utils/export-offshore-reports';

routerApp.controller('vesselOffshorePerformanceController', ['$rootScope', '$scope', '$http', '$timeout', '$state', 'ReportsOffshoreService', 'ReportsOffshoreApiService',
    function($rootScope, $scope, $http, $timeout, $state, ReportsOffshoreService, ReportsOffshoreApiService) {

    $rootScope.selectedLevels = ['view-offshore-performance'];

    ReportsOffshoreService.refreshReports()
    $scope.reports = ReportsOffshoreService.getReports();
    $scope.isLoadingReports = ReportsOffshoreApiService.isLoadingReports;

    $scope.openReport = function(report) {
        const targetState = ReportsOffshoreApiService.getReportView(report);
        $state.go(targetState, { report: report, viewReport: true });
    }

    $scope.$on('vessel-offshore-reports-list', function() {
        $scope.reports = ReportsOffshoreService.getReports();
    });

    $scope.exportReports = () => {
        const keys = Object.keys($scope.reports).filter(a => a != 'consumptions')
        const reports = keys.reduce((prev, curr) => { 
            return [...prev, $scope.reports[curr]]
        },[])

        exportOffshoreReports(reports, 'offshore_export')
    }
}]);

routerApp.filter('toDMSString', function() {
    return function(input) {
        if (!input) {
            return '-';
        }
        
        if (input.lat_hours === undefined ||
            input.lat_minutes === undefined ||
            input.lat_seconds === undefined ||
            input.lat_direction === undefined ||
            input.lon_hours === undefined ||
            input.lon_minutes === undefined ||
            input.lon_seconds === undefined ||
            input.lon_direction === undefined) {
            return '-'
        }

        return [
            `${input.lat_hours}°`,
            `${input.lat_minutes}'`,
            `${input.lat_seconds}''`,
            `${input.lat_direction}`,
            `${input.lon_hours}°`,
            `${input.lon_minutes}'`,
            `${input.lon_seconds}''`,
            `${input.lon_direction}`
        ].join(' ')
    }
});