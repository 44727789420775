import { exportCsv } from './export-csv'

const getHeaderRow = () => {
    return [
        'Report Number',
        'Event Type',
        'From', 
        'To',
        'Status',
        'Crew',
        'Passengers',
        'Position',
        'Voyage From',
        'Voyage To',
        'Distance Sailed (NM)',
        'Time Sailing (Hours)',
        'Time Loading/Unloading (Hours)',
        'Time at Anchor (Hours)',
        'Cargo Weight (Mt)', 
        'Consumption 1',
        'Consumption 1 Mt',
        'Consumption 2',
        'Consumption 2 Mt',
        'Consumption 3',
        'Consumption 3 Mt',
        'Consumption 4',
        'Consumption 4 Mt',
    ]
}

const getReportRows = (reports) => {
    return reports.map(report => {
           return [
                report.report_number,
                report.event_type,
                report.report_from,
                report.report_to,
                report.status,
                report.crew,
                report.passengers,
                [
                    `${report.lat_hours}°`.replace('°', '\u00B0'),
                    `${report.lat_minutes}'`,
                    `${report.lat_seconds}''`,
                    `${report.lat_direction}`,
                    `${report.lon_hours}°`.replace('°', '\u00B0'),
                    `${report.lon_minutes}'`,
                    `${report.lon_seconds}''`,
                    `${report.lon_direction}`
                ].join(' '),
                report.voyage_from,
                report.voyage_to,
                report.distance_sailed_NM,
                report.hours_spent_sailing,
                report.hours_spent_loading_unloading,
                report.hours_spent_at_anchor,
                report.cargo_weight_Mt, 
                report.bdn_1,
                report.consumption_1_Mt,
                report.bdn_2 || '',
                report.consumption_2_Mt || '',
                report.bdn_3 || '',
                report.consumption_3_Mt || '',
                report.bdn_4 || '',
                report.consumption_4_Mt || '',
            ]
        })
    
}

export const exportOffshoreReports = (reports, reportName) => {
    const rows = [
        getHeaderRow(),
        ...getReportRows(reports)
    ]

    exportCsv(rows, reportName)
}