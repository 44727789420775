import { routerApp } from '../app.module';
import { Vessel, numberOfMainEngineOptions, numberOfAuxEngineOptions, numberOfDieselGenOptions, numberOfAuxBoilerOptions, propellerTypes, nCylinders, nTurbochargers, veslinkFuelMapping } from '../models/vessel';
import { mainFlowMeterHasFuelAndGas, boilerFlowMeterHasFuelAndGas, autoPopulateMainFlowMeterTags, autoPopulateBoilerFlowMeterTags } from '../services/utilities';
import { isNull } from 'lodash';
import angular from 'angular';
import moment from 'moment';

routerApp.controller('managementVesselsController', ['ManagementVesselsService', 'ManagementClientsService', 'VesselSpecificationService', '$rootScope', '$scope', 'notify', '$state',
    function (ManagementVesselsService, ManagementClientsService, VesselSpecificationService, $rootScope, $scope, notify, $state) {
        
    $rootScope.selectedLevels = ['management', 'vessels'];
    $scope.pageState.pageTitle = 'View Vessels';
    $scope.selectedVessel = null;
    $scope.editing = false;
    $scope.creating = false;
    $scope.manually_enter_engine_model = false;
    $scope.upload_engine_model = false;

    $rootScope.selectedFormSubset = ['fields', 'sections', 'tabs'];
    $rootScope.selectedCarryOverFormSubset =['carry_over_fields'];
    
    ManagementVesselsService.getVessels().then((res: any) => {
        $scope.vessels = res.data.data;
        $scope.vessel_names = []
        $scope.vessel_imos = []
        $scope.availableFormConfigs = []
        for (var i=0; i<$scope.vessels.length; i++){
            $scope.vessel_names.push($scope.vessels[i].information.name)
            $scope.vessel_imos.push($scope.vessels[i].information.imo)
            if ($scope.availableFormConfigs.includes($scope.vessels[i].form_configuration) != true) {
                $scope.availableFormConfigs.push($scope.vessels[i].form_configuration)
            }
        }
    });

    ManagementClientsService.getClients().then((res: any) => {
        $scope.clients = res.data.data;
        $scope.client_names = $scope.clients.map(client => client.name)
    });

    $scope.selectClient = function(clientId: string) {
        var client_id = clientId['$oid']
        $state.go('site.managementClients', {cameFromVessel:true, clientId:client_id }, { reload: true})
    }

    $scope.switchClient = function() {
        $scope.selectedClient = $scope.clientVessel?.client_name;
    }
    $scope.btnReset = function(vessel) {
        $scope.selectedClient = $scope.clientVessel = null;
    }

    $scope.vesselFilter = function(vessel) {
        if (!$scope.vesselSearch && !$scope.selectedClient) {
            return true;  // if no search term is entered, show all vessels
        }

        if ($scope.vesselSearch && $scope.selectedClient) {
            return (
                vessel.client_name.toLowerCase().includes($scope.selectedClient.toLowerCase()) && 
                (vessel.information.name.toLowerCase().includes($scope.vesselSearch.toLowerCase()) 
                || (vessel.information.imo && vessel.information.imo.toString().includes($scope.vesselSearch.toLowerCase())))
            );
        }
        else if ($scope.vesselSearch && !$scope.selectedClient) {
            return (
                vessel.information.name.toLowerCase().includes($scope.vesselSearch.toLowerCase()) 
                || (vessel.information.imo && vessel.information.imo.toString().includes($scope.vesselSearch.toLowerCase()))
            )
        }
        else if (!$scope.vesselSearch && $scope.selectedClient) {
            return (
                vessel.client_name.toLowerCase().includes($scope.selectedClient.toLowerCase())
            );
        }

        let search = $scope.vesselSearch.toLowerCase();
        if ($scope.vesselSearch && !$scope.selectedClient) {
            return (
                vessel.information.name.toLowerCase().includes(search) 
                || (vessel.information.imo && vessel.information.imo.toString().includes(search))
            )
        }
        else if($scope.vesselSearch && $scope.selectedClient) {
            return (
                vessel.client_name.toLowerCase().includes($scope.selectedClient.toLowerCase()) && 
                (vessel.information.name.toLowerCase().includes(search) 
                || (vessel.information.imo && vessel.information.imo.toString().includes(search)))
            );
        }
    };

    var parseFieldDate = function(field) {
        if (field != undefined && field._isAMomentObject) {
            return field;
        }
        if (field != undefined && field["$date"] != undefined) {
            return moment(field["$date"]).utc();
        } else {
            return null;
        }
    }

    var formatMoment = function(date) {
        if (date != undefined && date._isAMomentObject) {
            return date.format('DD/MM/YYYY HH:mm');
        } else {
            return date;
        }
    }

    $scope.selectVessel = (vesselId: string) => {
        $scope.pageState.setLoadingData();
        ManagementVesselsService.getVessel(vesselId).then((res: any) => {
            $scope.selectedVessel = res.data.data;
            $scope.selectedVesselId = vesselId;
            $scope.pageState.setDataLoaded();
            $scope.pageState.pageTitle = "Vessel Details - " + $scope.selectedVessel.information.name;
            $scope.vesselConfigTab = 'form'
            $scope.num_tanks = $scope.selectedVessel.tanks?.length || 0;
            $scope.formPathFilter = $scope.selectedVessel?.form?.fields;
            $scope.carryOverFormPathFilter = $scope.selectedVessel?.carry_over_form?.fields;

            $scope.isDieselElectricPropulsion = false;
            if ($scope.selectedVessel?.information?.prime_mover_type == 'diesel_electric_propulsion') $scope.isDieselElectricPropulsion = true;
            $scope.veslinkFuelGrades = [];
            for (var i=0; i<$scope.selectedVessel?.veslink?.fuel_types?.length;i++) {
                $scope.veslinkFuelGrades.push($scope.selectedVessel.veslink.fuel_types[i].veslink_type)
            }
            $scope.vesselForm.warningMessages = {};

            if ($scope.selectedVessel.main_flow_meter_installation && $scope.selectedVessel.boiler_flow_meter_installation) {
                // if vessel doesn't have a flow meter configuration set, set it to the first template
                $scope.selectedMainFlowMeterInstallation = $scope.selectedVessel.main_flow_meter_installation;
                $scope.selectedBoilerFlowMeterInstallation = $scope.selectedVessel.boiler_flow_meter_installation;
                let selectedMainFMClass = $scope.selectedMainFlowMeterInstallation._cls;
                let selectedBoilerFMClass = $scope.selectedBoilerFlowMeterInstallation._cls;
    
                let mainFlowMeterInstallationTemplate = $scope.mainFlowMeterTemplatesMap[selectedMainFMClass];
                $scope.selectedMainFlowMeterInstallation.description = mainFlowMeterInstallationTemplate.description;
                $scope.selectedMainFlowMeterInstallation.type = mainFlowMeterInstallationTemplate.type;
                let boilerFlowMeterInstallationTemplate = $scope.boilerFlowMeterTemplatesMap[selectedBoilerFMClass];
                $scope.selectedBoilerFlowMeterInstallation.description = boilerFlowMeterInstallationTemplate.description;
                $scope.selectedBoilerFlowMeterInstallation.type = boilerFlowMeterInstallationTemplate.type;
            } else {
                // initialize the flow meter configuration to the first template we find
                $scope.selectedMainFlowMeterInstallation = $scope.mainFlowMeterTemplates[0];
                $scope.selectedBoilerFlowMeterInstallation = $scope.boilerFlowMeterTemplates[0];
            }
            $scope.selectedMainFlowMeterInstallationClass = $scope.selectedMainFlowMeterInstallation._cls;
            $scope.selectedBoilerFlowMeterInstallationClass = $scope.selectedBoilerFlowMeterInstallation._cls;

            if ($scope.selectedVessel.dnv?.cutoff_date) $scope.selectedVessel.dnv.cutoff_date = formatMoment(parseFieldDate($scope.selectedVessel.dnv.cutoff_date));
        });
    }

    $scope.deselectVessel = () => {
        $scope.selectedVessel = null;
        $scope.selectedVesselId = null;
        $scope.editing = false;
        $scope.creating = false;
        $scope.vesselForm.$setPristine();
        $scope.pageState.pageTitle = 'View Vessels';
    }

    $scope.$watch('fieldPathSearch',function(newValue,oldValue,scope) {
        var result = {}
        angular.forEach($scope.selectedVessel?.form?.fields, function(field) {
            if (field?.path?.toLowerCase().includes(newValue)) {
                result[field.path] = $scope.selectedVessel.form.fields[field]
            }
        })
        $scope.formPathFilter = result
    })

    $scope.$watch('carryOverFieldPathSearch',function(newValue,oldValue,scope) {
        var result = {}
        angular.forEach($scope.selectedVessel?.carry_over_form?.fields, function(field) {
            if (field?.path?.toLowerCase().includes(newValue)) {
                result[field.path] = $scope.selectedVessel.carry_over_form.fields[field]
            }
        })
        $scope.carryOverFormPathFilter = result
    })
    

    $scope.formatLabel = function(key) {
        // Replace underscores with spaces and capitalize each word
        return key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
    };
    
    $scope.setEditing = () => {
        $scope.editing = true;
        $scope.selectedVessel = new Vessel($scope.selectedVessel, false);
    }

    $scope.changes_made = false;
    $scope.$watch('selectedVessel', function(newValue,oldValue,scope) {
        if ($scope.editing && newValue != oldValue){
            $scope.changes_made = true;
        }
    })

    $scope.stopEditing = function() {
        $scope.editing = false;
    }

    $scope.newVessel = () => {
        $scope.selectedVessel = new Vessel({}, true);
        $scope.veslinkFuelGrades = [];
        $scope.creating = true;
        $scope.pageState.pageTitle = 'Create New Vessel';
        $scope.vesselConfigTab = 'form'
        $scope.vesselForm.warningMessages = {};
        $scope.selectedMainFlowMeterInstallationClass = undefined;
        $scope.selectedBoilerFlowMeterInstallationClass = undefined;
        $scope.num_tanks = undefined;
    }

    $scope.retrieveNavtorID = (vesselId: string): void => {
        ManagementVesselsService.retrieveNavtorID(vesselId, $scope.selectedVessel)
        .then((res: any) => {
            notify({ message: 'Request to retrieve navtor vessel ID sent successfully. If the vessel has a navtor ID, it will appear shortly.', duration: 5000, classes: ['ok-notification'] });
            $scope.deselectVessel();
        })
        .then((res: any) => {
            ManagementVesselsService.getVessels().then((res: any) => {
                $scope.vessels = res.data.data;
            });
        })
        .catch((err: any) => {
            notify({ message: `Error: ${err.data.message}`, duration: 5000, classes: ['bad-notification'] })
        });
    }

    $scope.createVessel = () => {
        if ($scope.selectedVessel?.veslink?.enabled) {
            $scope.selectedVessel.veslink.fuel_types = $scope.veslinkFuelGrades.map(fuelGrade => veslinkFuelMapping[fuelGrade])
        }

        if ($scope.selectedVessel?.email_alert?.emails) {
            if (typeof $scope.selectedVessel.email_alert.emails == 'string') {
                var email_addresses = $scope.selectedVessel.email_alert.emails.replaceAll(' ','').split(',')
                $scope.selectedVessel.email_alert.emails = email_addresses;
            }
        }
        let vesselDict = Object.assign({}, $scope.selectedVessel);
        vesselDict = deleteNullValues(vesselDict);

        ManagementVesselsService.createVessel(vesselDict)
        .then((res: any) => {
            $scope.selectedVessel = res.data.data;
            notify({ message: 'Vessel created successfully', duration: 5000, classes: ['ok-notification'] });
            $scope.vesselForm.$setPristine();
            $scope.creating = false;
            $scope.selectedVesselId = $scope.selectedVessel._id;
        })
        .then(() => {
            ManagementVesselsService.getVessels().then((res: any) => {
                $scope.vessels = res.data.data;
            });
        })
        .catch((err: any) => {
            if (err.data.message.charAt(0) === "[") {
                const messages: string[] = JSON.parse(err.data.message.replace(/'/g, '"'));
                messages.forEach(message => {
                    notify({ message: `Error: ${message}`, duration: 5000, classes: ['bad-notification'] })
                });
            } else {
                notify({ message: `Error: ${err.data.message}`, duration: 5000, classes: ['bad-notification'] })
            }
        });
    }

    $scope.saveVessel = () => {
        angular.forEach(['field','section','tab'], function(subset) {
            if ($scope['new_'+subset+'_path'] != '' && $scope['new_'+subset+'_path'] != null) $scope.addNewFormSubset(subset)
        })

        if ($scope.new_carry_over_field_path != '' && $scope.new_carry_over_field_path != null) $scope.selectedVessel.carry_over_form.fields[$scope.new_carry_over_field_path] = $scope.newCarryOverField

        if ($scope.selectedVessel?.email_alert?.emails) {
            if (typeof $scope.selectedVessel.email_alert.emails == 'string') {
                var email_addresses = $scope.selectedVessel.email_alert.emails.replaceAll(' ','').split(',')
                $scope.selectedVessel.email_alert.emails = email_addresses;
            }
        }

        if ($scope.selectedVessel?.veslink?.enabled) {
            $scope.selectedVessel.veslink.fuel_types = $scope.veslinkFuelGrades.map(fuelGrade => veslinkFuelMapping[fuelGrade])
        }
        
        let vesselDict = Object.assign({}, $scope.selectedVessel);
        vesselDict = deleteNullValues(vesselDict);

        ManagementVesselsService.updateVessel($scope.selectedVesselId, vesselDict)
        .then((res: any) => {
            $scope.selectedVessel = res.data.data;
            notify({ message: 'Vessel saved successfully', duration: 5000, classes: ['ok-notification'] });
            $scope.vesselForm.$setPristine();
            $scope.editing = false;
        })
        .then(() => {
            ManagementVesselsService.getVessels().then((res: any) => {
                $scope.vessels = res.data.data;
            });
        })
        .catch((err: any) => {
            if (err.data.message.charAt(0) === "[") {
                const messages: string[] = JSON.parse(err.data.message.replace(/'/g, '"'));
                messages.forEach(message => {
                    notify({ message: `Error: ${message}`, duration: 5000, classes: ['bad-notification'] })
                });
            } else {
                notify({ message: `Error: ${err.data.message}`, duration: 5000, classes: ['bad-notification'] })
            }
        });
    }

    $scope.bulkUploadVessels = function() {
        $("#bulkUploadVesselPopup").modal('show');
    }

    $scope.confirmVesselDeactivation = function(vessel) {
        $scope.vesselToDeactivate = vessel;
        $("#deactivateVesselWarning").modal('show');
    }

    $scope.uploadEngineModelModal = function() {
        $scope.manually_enter_engine_model = false;
        $scope.upload_engine_model = false;
        // $scope.engineModelTable = angular.element($('[name="engineModelTable"]')).scope().engineModelTable
        $("#uploadEngineModelPopup").modal('show');
    }

    $scope.uploadEngineModelDisabled = true;
    var engine_model_file = document.getElementById('engineModelFile') as HTMLInputElement
    // var engine_model_form = document.getElementById('engineModelClass') as HTMLInputElement
    
    if (engine_model_file != null) {
        engine_model_file.onchange = function(this: HTMLInputElement) {
            let input = this.files[0]
            if (input) $scope.uploadEngineModelDisabled = false;
        }
    }

    $scope.enterEngineModel = function() {
        $scope.manually_enter_engine_model = true;
        $scope.upload_engine_model = false;
    }
    $scope.uploadEngineModel = function() {
        $scope.upload_engine_model = true;
        $scope.manually_enter_engine_model = false;
    }

    $scope.submitEngineModel = function(vesselId) {
        var engine_model_files = [];
        $('#uploadEngineModels').children('input').each(function(this: HTMLInputElement) {
            if (this.files[0]) {
                engine_model_files.push(this.files[0]);
            }
        });
        var engine_model_class = document.getElementById('engineModelClass') as HTMLInputElement
        $scope.engine_model_class = engine_model_class?.value

        if (engine_model_files[0] != undefined) {
            var data_type = 'file';
            ManagementVesselsService.updateEngineModel(vesselId, engine_model_files[0], data_type).then((res: any) => {
                $scope.selectedVessel.engine_model = res.data.data['models'][0]
            });
        }
        else {
            var data_type = 'dict';
            $scope.engine_model_dict = {
                'engine_model_class': $scope.engine_model_class,
                'parameters':$scope.engine_model_parameters
            }
            ManagementVesselsService.updateEngineModel(vesselId, $scope.engine_model_dict, data_type).then((res: any) => {
                $scope.selectedVessel.engine_model = res.data.data['models'][0]
            });
        }
        
    }

    $scope.disableVessel = (vesselId: string): void => {
        ManagementVesselsService.disableVessel(vesselId, $scope.user)
        .then((res: any) => {
            notify({ message: 'Vessel disabled successfully', duration: 5000, classes: ['ok-notification'] });
            $scope.deselectVessel();
        })
        .then((res: any) => {
            ManagementVesselsService.getVessels().then((res: any) => {
                $scope.vessels = res.data.data;
            });
        })
        .catch((err: any) => {
            notify({ message: `Error: ${err.data.message}`, duration: 5000, classes: ['bad-notification'] })
        });
    }

    const deleteNullValues = (obj: any) => {
        delete obj["sanitiseClient"]

        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                for (let subKey in obj[key]) {
                    if (isNull(obj[key][subKey]) || obj[key][subKey] === "") {
                        delete obj[key][subKey];
                    }
                }
            }
            else {
                if (isNull(obj[key]) || obj[key] === "") {
                    delete obj[key];
                }
            }
        }

        return obj
    }

    $scope.new_field_report_types = [];
    $scope.new_section_report_types = [];

    $scope.updateFormReportTypes = function(event, formSubset, path, reportType) {
        if (event.target.checked){
            if (path.includes('new')) {
                if ($scope['new_'+formSubset+'_report_types'].indexOf(reportType)==-1) {
                    $scope['new_'+formSubset+'_report_types'].push(reportType)
                }
            }
            else if ($scope.selectedVessel.form[formSubset][path].for_report_types.indexOf(reportType) == -1) {
                $scope.selectedVessel.form[formSubset][path].for_report_types.push(reportType)
            }
        }
        else {
            if (path.includes('new')) {
                if ($scope['new_'+formSubset+'_report_types'].indexOf(reportType) != -1) {
                    $scope['new_'+formSubset+'_report_types'].splice($scope['new_'+formSubset+'_report_types'].indexOf(reportType),1)
                }
            }
            else if ($scope.selectedVessel.form[formSubset][path].for_report_types.indexOf(reportType) != -1) {
                $scope.selectedVessel.form[formSubset][path].for_report_types.splice($scope.selectedVessel.form[formSubset][path].for_report_types.indexOf(reportType),1)
            }
        }
    }

    $scope.$watch('selectedVessel.information.name', function(newValue, oldValue, scope) {
        var valid = true;
        var name_warning_msg = null;
        if ($scope.creating) {
            if ($scope.vessel_names.indexOf(newValue) > -1) {
                valid = false;
                name_warning_msg = 'A vessel with this name already exists. Please confirm this is a new vessel before proceeding.'
            }
            $scope.vesselForm.name.$setValidity('name',valid)
            $scope.vesselForm.warningMessages['selectedVessel.information.name'] = name_warning_msg
        }
    })

    $scope.$watch('selectedVessel.information.imo', function(newValue, oldValue, scope) {
        var valid = true;
        var imo_warning_msg = null;
        if ($scope.creating) {
            if ($scope.vessel_imos.indexOf(newValue) > -1) {
                valid = false;
                imo_warning_msg = 'A vessel with this IMO already exists. Please confirm this is a new vessel before proceeding.'
            }
            $scope.vesselForm.imo.$setValidity('imo',valid)
            $scope.vesselForm.warningMessages['selectedVessel.information.imo'] = imo_warning_msg
        }
    })

    $scope.$watch('selectedVessel.client_name', function(newValue, oldValue, scope) {
        var valid = true;
        var client_name_warning_msg = null;
        if ($scope.creating) {
            if ($scope.client_names.indexOf(newValue) == -1) {
                valid = false;
                client_name_warning_msg = 'The client name you provided does not exist in our database. Please confirm the correct client before proceeding.'
            }
            $scope.vesselForm.client.$setValidity('client',valid)
            $scope.vesselForm.warningMessages['selectedVessel.client_name'] = client_name_warning_msg
        }
    })

    $scope.$watch('selectedVessel.information.prime_mover_type', function(newValue,oldValue,scope){
        if (newValue == 'diesel_electric_propulsion') $scope.isDieselElectricPropulsion = true;
        else $scope.isDieselElectricPropulsion = false;
    })

    $scope.numberOfMainEngineOptions = numberOfMainEngineOptions;
    $scope.numberOfAuxEngineOptions = numberOfAuxEngineOptions;
    $scope.numberOfDieselGenOptions = numberOfDieselGenOptions;
    $scope.numberOfAuxBoilerOptions = numberOfAuxBoilerOptions;
    $scope.propellerTypes = propellerTypes;
    $scope.nCylinders = nCylinders;
    $scope.nTurbochargers = nTurbochargers;

    $scope.nTanks = [
        { value: 0, label: "0 Tanks"},
        { value: 1, label: "1 Tank"},
        { value: 2, label: "2 Tanks"},
        { value: 3, label: "3 Tanks"},
        { value: 4, label: "4 Tanks"},
        { value: 5, label: "5 Tanks"},
        { value: 6, label: "6 Tanks"},
        { value: 7, label: "7 Tanks"},
        { value: 8, label: "8 Tanks"},
        { value: 9, label: "9 Tanks"},
        { value: 10, label: "10 Tanks"},
        { value: 11, label: "11 Tanks"},
        { value: 12, label: "12 Tanks"},
        { value: 13, label: "13 Tanks"},
        { value: 14, label: "14 Tanks"},
        { value: 15, label: "15 Tanks"},
        { value: 16, label: "16 Tanks"},
        { value: 17, label: "17 Tanks"},
        { value: 18, label: "18 Tanks"},
        { value: 19, label: "19 Tanks"},
        { value: 20, label: "20 Tanks"},
    ];

    /**
     * Add / Remove Tanks
     */
    $scope.$watch('num_tanks', function(newValue,oldValue,scope) {
        if ($scope.creating || $scope.editing) {
            if ($scope.selectedVessel?.tanks == undefined) {
                $scope.selectedVessel.tanks = [];
            }

            if (oldValue == undefined) {
                oldValue = 0;
            }
            for (var i=oldValue;i<newValue;i++) {
                $scope.selectedVessel.tanks.push({});
            }
            if (oldValue > newValue) {
                for (var i=newValue;i<oldValue;i++) {
                    $scope.selectedVessel.tanks.pop();
                }
            }
            if ($scope.selectedVessel.tanks[newValue-1].volume_ratings == undefined) {
                $scope.selectedVessel.tanks[newValue-1].volume_ratings = [{}];
            }
        }

    })

    VesselSpecificationService.getFlowMeterTemplates().then(function(response) {
        let data = response.data.data;
        $scope.mainFlowMeterTemplates = data.main_fm_templates;
        $scope.boilerFlowMeterTemplates = data.boiler_fm_templates;
        $scope.mainFlowMeterTemplatesMap = {};
        $scope.mainFlowMeterTemplates.forEach(element => {
            $scope.mainFlowMeterTemplatesMap[element._cls] = element;
        });
        $scope.boilerFlowMeterTemplatesMap = {};
        $scope.boilerFlowMeterTemplates.forEach(element => {
            $scope.boilerFlowMeterTemplatesMap[element._cls] = element;
        });
    });

    /**
     * Updates selected main flow meter object from template based on modified flow meter class
     * @param selectedClass: class of the flow meter
     * @returns null
     */
    $scope.updateSelectedMainFlowMeterInstallation = function(selectedClass) {
        if (!selectedClass) {
            return;
        }
        let mainFlowMeterInstallationTemplate = $scope.mainFlowMeterTemplatesMap[selectedClass];
        $scope.selectedMainFlowMeterInstallation = mainFlowMeterInstallationTemplate;
    }
    $scope.updateSelectedBoilerFlowMeterInstallation = function(selectedClass) {
        if (!selectedClass) {
            return;
        }
        let boilerFlowMeterInstallationTemplate = $scope.boilerFlowMeterTemplatesMap[selectedClass];
        $scope.selectedBoilerFlowMeterInstallation = boilerFlowMeterInstallationTemplate;
    }

    $scope.addNewFormSubset = function(subset) {
        $scope.selectedVessel.form[subset+'s'][$scope['new_'+subset+'_path']] = {
            'path': $scope['new_'+subset+'_path'],
            'visible':$scope['new_'+subset+'_visible'],
        }
        var form_dict = $scope.selectedVessel.form[subset+'s'][$scope['new_'+subset+'_path']]
        if (subset != 'tab') {
            form_dict['for_report_types'] = $scope['new_'+subset+'_report_types']
            if (subset == 'field') {
                form_dict['required'] = $scope['new_'+subset+'_required']
            }
        }
    }

    $scope.openAddFormSubset = function(subset) {
        $scope['adding_new_form_' +subset] = true;
    }

    $scope.closeAddFormSubset = function(subset) {
        $scope['adding_new_form_' +subset] = false;
        $scope['new_'+subset+'_path'] = null;
        $scope.vesselForm.vesselConfiguration.form[subset+'s']['new'+subset.charAt(0).toUpperCase() + subset.slice(1)+'PathName'].$setValidity('new'+subset.charAt(0).toUpperCase() + subset.slice(1)+'PathValidity',true);
    }

    $scope.$watch('new_field_path', function(newValue,oldValue,scope) {
        if (newValue?.length > 1) {
            if (Object.keys($scope.selectedVessel?.form?.fields).includes(newValue)) {
                $scope.vesselForm.vesselConfiguration.form.fields.newFieldPathName.$setValidity('newFieldPathValidity',false);
                $scope.fields.newFieldPathName.warningMessages = 'A field with this name already exists. Please edit the already existing field or change the name of the new field.'
            }
        }
    })

    $scope.$watch('new_section_path', function(newValue,oldValue,scope) {
        if (newValue?.length > 1) {
            if (Object.keys($scope.selectedVessel?.form?.sections).includes(newValue)) {
                $scope.vesselForm.vesselConfiguration.form.sections.newSectionPathName.$setValidity('newSectionPathValidity',false);
                $scope.sections.newSectionPathName.warningMessages = 'A section with this name already exists. Please edit the already existing section or change the name of the new section.'
            }
        }
    })

    $scope.$watch('new_tab_path', function(newValue,oldValue,scope) {
        if (newValue?.length > 1) {
            if (Object.keys($scope.selectedVessel?.form?.tabs).includes(newValue)) {
                $scope.vesselForm.vesselConfiguration.form.tabs.newTabPathName.$setValidity('newTabPathValidity',false);
                $scope.tabs.newTabPathName.warningMessages = 'A tab with this name already exists. Please edit the already existing tab or change the name of the new tab.'
            }
        }
    })

    $scope.$watch('selectedMainFlowMeterInstallationClass', function(newValue, oldValue, scope) {
        $scope.mainFlowMeterHasFuelAndGas = mainFlowMeterHasFuelAndGas(newValue);
        $scope.autoPopulateMainFlowMeterTags = autoPopulateMainFlowMeterTags(newValue);
    });

    $scope.$watch('selectedBoilerFlowMeterInstallationClass', function(newValue, oldValue, scope) {
        $scope.boilerFlowMeterHasFuelAndGas = boilerFlowMeterHasFuelAndGas(newValue);
        $scope.autoPopulateBoilerFlowMeterTags = autoPopulateBoilerFlowMeterTags(newValue);
    });

    $scope.engine_model_parameters = {
        // 'RPM':{'function':{}},
        // 'Power':{'function':{}},
        'Scav Press':{'scavenging_air_pressure':{}},
        'TC Speed':{'tcrpm':{}},
        'TC Speed 2': {'tcrpm_2':{}},
        'Air Filter Pr Drop':{'air_filter_pressure_drop':{}},
        'Air Filter Pr Drop 2': {'air_filter_pressure_drop_2':{}},
        'Exhaust Before T/C':{'turbine_inlet_temperature':{}},
        'Exhaust After T/C':{'turbine_outlet_temperature':{}},
        'Exhaust After T/C 2':{'turbine_outlet_temperature_2':{}},
        'Cyl Exhaust Temp':{'exhaust_gas_temperature_cyl_out':{}},
        // 'Exh Back Press':{'function':{}},
        'Pmax':{'pmax':{}},
        'Pcomp':{'pcom':{}},
        'Fuel Index':{'fuel_pump_index':{}},
        'Pr Drop Air Cooler':{'air_cooler_pressure_drop':{}},
        'Pr Drop Air Cooler 2': {'air_cooler_pressure_drop_2':{}},
        'Exh Receiver Press':{'turbine_inlet_pressure':{}},
        'Blower Inlet Temp':{'blower_inlet_temperature':{}},
        'Blower Inlet Temp 2': {'blower_inlet_temperature_2':{}},
        'Scav Temp':{'scavenge_air_temperature':{}},
        'Press After T/C':{'turbine_back_pressure':{}},
        'Press After T/C 2': {'turbine_back_pressure_2':{}},
        'SFOC':{'sfoc':{}},
        'TC Power':{'tc_power':{}},
        // 'Fuel Index RPM':{'function':{}},
        'RPM Power':{'me_rpm_power':{}}
    }

    $scope.engineModelTable = angular.element($('[name="engineModelTable"]')).scope().engineModelTable
    $scope.uploadModel = angular.element($('[name="uploadModel"]')).scope().uploadModel

    $scope.addVolumeRating = function(index) {
        if ($scope.selectedVessel.tanks[index].volume_ratings == undefined) {
            $scope.selectedVessel.tanks[index].volume_ratings = [{}];
        }
        else $scope.selectedVessel.tanks[index].volume_ratings.push({});
    }

    $scope.removeVolumeRating = function(index) {
        $scope.selectedVessel.tanks[index].volume_ratings.splice(-1);
    }
    $scope.availableVeslinkFuelGrades = Object.keys(veslinkFuelMapping);

    $scope.updateVeslinkFuelTypes = function(event, veslinkFuelType) {
        if (event.target.checked) {
            if ($scope.veslinkFuelGrades.indexOf(veslinkFuelType) == -1) {
                $scope.veslinkFuelGrades.push(veslinkFuelType)
            }
            
        }
        else {
            if ($scope.veslinkFuelGrades.indexOf(veslinkFuelType) != -1) {
                $scope.veslinkFuelGrades.splice($scope.veslinkFuelGrades.indexOf(veslinkFuelType),1)
            }
        }
    }

    $scope.openAddCarryOverFormField = function() {
        $scope.adding_new_carry_over_form_field = true;
        $scope.newCarryOverField = {
            'path':null,
            'rules':[],
            'enabled':false
        }
    }

    $scope.closeAddCarryOverFormField = function() {
        $scope.adding_new_carry_over_form_field = false;
        $scope.new_carry_over_field_path = null;
        $scope.vesselForm.vesselConfiguration.carry_over_form.carry_over_fields.newCarryOverFieldPathName.$setValidity('newCarryOverFieldPathValidity',true);
    }

    $scope.$watchGroup(['new_carry_over_field_path','new_carry_over_field_enabled'], function(newValues, oldValues, scope) {
        if ($scope.newCarryOverField) {
            $scope.newCarryOverField['path'] = newValues[0]
            $scope.newCarryOverField['enabled'] = newValues[1]
        }
    })

    $scope.addRule = function(field,index,new_field) {
        var prev_report_dict = {
            'carry_over_report':true,
            'path':'_cls',
            'path_value':null
        }
        var current_report_dict = {
            'carry_over_report':false,
            'path':'_cls',
            'path_value':null
        }
        if (new_field) {
            $scope.newCarryOverField.rules.splice(index,0,[])
            $scope.newCarryOverField.rules[index].push(prev_report_dict,current_report_dict)
        }
        else {
            $scope.selectedVessel.carry_over_form.fields[field].rules.splice(index,0,[])
            $scope.selectedVessel.carry_over_form.fields[field].rules[index].push(prev_report_dict,current_report_dict)
        }
    }

    $scope.$watch('new_carry_over_field_path', function(newValue,oldValue,scope) {
        if (newValue?.length > 1) {
            if (Object.keys($scope.selectedVessel?.carry_over_form?.fields).includes(newValue)) {
                $scope.vesselForm.vesselConfiguration.carry_over_form.carry_over_fields.newCarryOverFieldPathName.$setValidity('newCarryOverFieldPathValidity',false);
                $scope.carry_over_fields.newCarryOverFieldPathName.warningMessages = 'A field with this name already exists. Please edit the already existing field or change the name of the new field.'
            }
            else {
                $scope.vesselForm.vesselConfiguration.carry_over_form.carry_over_fields.newCarryOverFieldPathName.$setValidity('newCarryOverFieldPathValidity',true);
                $scope.carry_over_fields.newCarryOverFieldPathName.warningMessages = undefined
            }
        }
    })

    $scope.$watch('selectedVessel.dnv.enabled', function(newValue,oldValue,scope) {
        if (newValue == true) {
            if (!$scope.selectedVessel.dnv.navtor_company_id && ($scope.editing || $scope.creating)){
                $scope.selectedVessel.dnv.navtor_company_id = $scope.selectedVessel.client.navtor_company_id
            }
        }
    })

    $scope.$watch('selectedVessel.form_configuration', function(newValue, oldValue, scope) {
        var valid = true;
        var name_warning_msg = null;
        if ($scope.creating) {
            if ($scope.availableFormConfigs.indexOf(newValue) == -1 && $scope.vessel_names.indexOf(newValue) == -1) {
                valid = false;
                name_warning_msg = 'Please enter a valid vessel name or form configuration.'
            }
            $scope.vesselForm.vesselConfiguration.form.vesselFormConfiguration.$setValidity('vesselFormConfiguration',valid)
            $scope.vesselForm.warningMessages['selectedVessel.form_configuration'] = name_warning_msg
        }
    })

    $scope.$watchGroup(['selectedMainFlowMeterInstallation','selectedBoilerFlowMeterInstallation'], function(newValues,oldValues,scope) {
        $scope.selectedVessel.main_flow_meter_installation = newValues[0];
        $scope.selectedVessel.boiler_flow_meter_installation = newValues[1];
    })
}]);