export const exportCsv = (rows, reportName: string) => {
    const csvContent = `${rows.map(a => a.join(',')).join('\n')}`
    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'})
    const link = document.createElement('a')

    link.setAttribute('href', URL.createObjectURL(blob))
    link.setAttribute('download', `${reportName}.csv`)

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}