import { routerApp, baseUrl, enableHybrid, enableOnPremise } from '../app.module';
import moment from 'moment';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import { httpRequestGenerator } from './utilities';
import _ from 'lodash';
import { OffshoreReportStatus } from '../models/offshore-report.model';

routerApp.factory('ReportsOffshoreService', ['$rootScope', 'ReportsOffshoreApiService', function($rootScope, ReportsOffshoreApiService) {

    // the format to serialize all the dates to, this should be used when
    // dealing with datepickers
    const DATE_FORMAT = 'DD/MM/YYYY HH:mm';
    let reports = [];

    const clearService = function() {
        reports = []
    }

    const refreshReports = function() {
        ReportsOffshoreApiService.setLoadingReports(true);
        ReportsOffshoreApiService.getReports($rootScope.selectedVessel.id).then(function(response) {
            const data = response.data.data;
            data.forEach(report => {
                applyDatesOnReport(report);
                report.id = report["_id"]["$oid"];
            })
            reports = data.reverse()
            ReportsOffshoreApiService.setLoadingReports(false);
            $rootScope.$broadcast('vessel-offshore-reports-list');
        }, function() {
            ReportsOffshoreApiService.setLoadingReports(false);
        });
    }

    const removeReportFromList = function(reportId) {
        const removed = reports.filter((report) => report._id !== reportId)
        reports = removed
    }

    const addReportToList = function(report) {
        if (reports.map(item => item._id).includes(report._id)) return
        reports.push(report)
    }

    const getPreviousReport = function(reportNumber) {
        if (!reportNumber || reportNumber - 1 <= 0) return

        const sorted = [...reports].sort(({report_number: a}, {report_number: b}) => b - a)
        const index = sorted.indexOf(sorted.find((report) => report.report_number === reportNumber))
        if (index - 1 < 0) return null

        return sorted[index - 1]
    }

    const isReportInProgress = function() {
        return reports?.length && reports[reports.length - 1].status === OffshoreReportStatus.Started
    }

    const getInprogressReport = function() {
        if (isReportInProgress()) {
            return reports[reports.length - 1]
        }
    }

    const parseFieldDate = function(field, returnDefaultField=false) {
        if (field != undefined && field._isAMomentObject) {
            return field;
        }
        if (field != undefined && field["$date"] != undefined) {
            // we must parse this and change it to UTC so that we can extract
            // the hours and date into a string in the right format, anything
            // is fine here as long as it gets displayed the same machine.
            // since we're showing both timezones and time on the page, we would
            // like to see the timezone of the vessel and not our timezone
            // since we don't care about our own timezone in the vessel's reports
            return moment(field["$date"]).utc();
        } else if ((enableHybrid || enableOnPremise || returnDefaultField)) {
            // report saved offline sometimes have date field as string or is undefined. 
            return field;
        } else if (field) {
            return moment(field).utc()
        }
        return null
    }

    const formatMoment = function(date) {
        if (date != undefined && date._isAMomentObject) {
            return date.format(DATE_FORMAT);
        }
        return date;
    }

    // when we get a report back from the database, we run this function on the
    // report to make sure that all the dates are parsed into the right format
    // the dates we get from mongoengine engine are objects that have a $date
    // object, so we just parse that and use the datepicker date format
    const applyDatesOnReport = function(report) {
        if (report == undefined) report = {};
        if (report.report_from) report.report_from = formatMoment(parseFieldDate(report.report_from));
        if (report.report_to) report.report_to = formatMoment(parseFieldDate(report.report_to));

        return report;
    }

    return {
        clearService,
        refreshReports,
        removeReportFromList,
        addReportToList,
        applyDatesOnReport,
        getReports: function() {
            return reports;
        },
        isReportInProgress,
        getInprogressReport,
        formatMoment,
        parseFieldDate,
        getPreviousReport,
    }
}]);

routerApp.factory('ReportsOffshoreApiService', ['$http', function($http) {
    const requestFunc = httpRequestGenerator(enableOnPremise, $http);

    let newReport: {};
    let isLoadingReports = false;

    return {
        setNewReport: function(report) {
            newReport = report;
        },
        getNewReport: function() {
            return newReport;
        },
        getReportView: function() {
            return 'site.offshoreReport'
        },
        getReports: function(vesselId) {
            const url = `${baseUrl}${vesselId}/offshoreReports?callback=JSON_CALLBACK`;
            return requestFunc({
                url: url,
                method: "GET",
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });
        },
        deleteReport: function(vesselId, reportId) {
            const url = `${baseUrl}${vesselId}/offshoreReports/${reportId}`;
            return requestFunc({
                url: url,
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });
        },
        setLoadingReports: function(newValue) {
            isLoadingReports = newValue;
        },
        isLoadingReports: function() {
            return isLoadingReports;
        },
    }
}]);
