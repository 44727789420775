export enum OffshoreReportEventType {
    Transit = 'transit',
    Arrival = 'arrival',
    Departure = 'departure',
    SeaPassageStart = 'start of sea passage',
    SeaPassageEnd = 'end of sea passage',
    NoonPort = 'noon port',
    NoonSea = 'noon sea',
}

export enum OffshoreReportStatus {
    Started = 'started',
    Completed = 'completed',
    Deleted = 'deleted',
}

export interface OffshoreReport {
    report_id?: string
    report_number: number,
    report_from: string,
    report_to: string,
    status: OffshoreReportStatus,
    event_type: OffshoreReportEventType,
    lat_hours: number,
    lat_minutes: number,
    lat_seconds: number,
    lat_direction: string,
    lon_hours: number,
    lon_minutes: number,
    lon_seconds: number,
    lon_direction: string,
    hours_spent_sailing: number,
    hours_spent_at_anchor: number,
    hours_spent_loading_unloading: number,
    distance_sailed_NM: number,
    cargo_weight_Mt: number,
    passengers: number,
    crew: number,
    bdn_1: string,
    consumption_1_Mt: number,
    bdn_2?: string,
    consumption_2_Mt?: number,
    bdn_3?: string,
    consumption_3_Mt?: number,
    bdn_4?: string,
    consumption_4_Mt?: number
}